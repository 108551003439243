import React, { useState } from "react";
import { CSVLink } from "react-csv";
import moment from "moment";
import { Button } from "antd";
import fetcher from "../../shared/lib/fetcher/fetcher";
import sniffProject from "../../shared/lib/sniffProject";
import { message } from "antd";

export function CheckInReport() {
  const [clientData, setClientData] = useState<any>(null);

  const handleReportButtonPressed = async () => {
    const hide = message.loading("Preparing your report..", 0);
    setClientData([]);
    const projectId = sniffProject();

    const data = await fetcher(
      `https://us-central1-${projectId}.cloudfunctions.net/firestoreFileUploaderServer/clients/report`
    );

    if (!data) {
      setTimeout(hide, 1000);
      return;
    }

    setClientData(data);
    setTimeout(hide, 1000);
  };

  return (
    <>
      <Button type="primary" onClick={handleReportButtonPressed}>
        Download Check In Report
      </Button>
      {clientData && clientData.length > 0 && (
        <>
          Your report is ready, download it{" "}
          <CSVLink
            data={clientData}
            filename={`Client Check in Report - ${moment().format(
              "MM/DD/YYYY"
            )}.csv`}
            target="_blank"
          >
            here
          </CSVLink>
        </>
      )}
    </>
  );
}
