/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type MutationResult = "failure" | "skipped" | "success" | "%future added value";
export type participantNotesAddSimpleMutationVariables = {
    readonly participant_id: string;
    readonly case_manager_id: string;
    readonly label: string;
};
export type participantNotesAddSimpleMutationResponse = {
    readonly addParticipantNote: ({
        readonly participant: ({
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
            readonly notes: ReadonlyArray<({
                readonly id: string;
                readonly label: string;
                readonly case_manager: ({
                    readonly id: string;
                    readonly name: {
                        readonly first: string;
                        readonly last: string | null;
                    };
                }) | null;
                readonly created_at: string;
                readonly updated_at: string | null;
            }) | null> | null;
        }) | null;
        readonly result: MutationResult;
        readonly description: string | null;
        readonly errors: ReadonlyArray<string | null> | null;
    }) | null;
};
export type participantNotesAddSimpleMutation = {
    readonly response: participantNotesAddSimpleMutationResponse;
    readonly variables: participantNotesAddSimpleMutationVariables;
};



/*
mutation participantNotesAddSimpleMutation(
  $participant_id: String!
  $case_manager_id: String!
  $label: String!
) {
  addParticipantNote(participant_id: $participant_id, case_manager_id: $case_manager_id, label: $label) {
    participant {
      id
      name {
        first
        last
      }
      notes {
        id
        label
        case_manager {
          id
          name {
            first
            last
          }
        }
        created_at
        updated_at
      }
    }
    result
    description
    errors
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "participant_id",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "case_manager_id",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "label",
            "type": "String!",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "name",
        "storageKey": null,
        "args": null,
        "concreteType": "PersonName",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "first",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "last",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v3 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "addParticipantNote",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "case_manager_id",
                    "variableName": "case_manager_id"
                },
                {
                    "kind": "Variable",
                    "name": "label",
                    "variableName": "label"
                },
                {
                    "kind": "Variable",
                    "name": "participant_id",
                    "variableName": "participant_id"
                }
            ],
            "concreteType": "NewParticipant",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participant",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Participant",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "notes",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ParticipantNote",
                            "plural": true,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "label",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "case_manager",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "CaseManager",
                                    "plural": false,
                                    "selections": [
                                        (v1 /*: any*/),
                                        (v2 /*: any*/)
                                    ]
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "created_at",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "updated_at",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "result",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "errors",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "participantNotesAddSimpleMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v3 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "participantNotesAddSimpleMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v3 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "participantNotesAddSimpleMutation",
            "id": null,
            "text": "mutation participantNotesAddSimpleMutation(\n  $participant_id: String!\n  $case_manager_id: String!\n  $label: String!\n) {\n  addParticipantNote(participant_id: $participant_id, case_manager_id: $case_manager_id, label: $label) {\n    participant {\n      id\n      name {\n        first\n        last\n      }\n      notes {\n        id\n        label\n        case_manager {\n          id\n          name {\n            first\n            last\n          }\n        }\n        created_at\n        updated_at\n      }\n    }\n    result\n    description\n    errors\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '8d72a230096332e8e711253d0f32ef96';
export default node;
