import React, { useState } from "react";
import { CourtReportHeader } from "./CourtReportHeader";
import moment from "moment";
import {
  Booking,
  Maybe,
  CourtMemoInput,
  RecommendationFormInput,
} from "../../graphql-types";
import "./RecommendationForm.scss";
import { CourtReportPreamble } from "./CourtReportPreamble";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { Moment } from "moment";
import { Checkbox, Input, DatePicker, Button } from "antd";
import { dateFormatList, booleanToYesNo, yesNoToBoolean } from "./shared";
import { SignatureLine } from "./SignatureLine";
import { submitBookingCourtMemo } from "../../actions/bookings";
import { ROOT_PATH } from "../../AppInfo";
import { InternalNotesSection } from "./RecommendationForm";
import { ActionSection } from "./RecommendationForm";
import { ConditionsOfRelease } from "./ConditionsOfRelease";

const { TextArea } = Input;

interface CourtMemoFormData {
  bookingDate: Maybe<Moment>;
  arrestAgency: string;
  arrestReason: string;
  was_under_the_influence_of_alcohol_or_drugs: boolean;
  was_combative: boolean;
  was_in_medical_isolation: boolean;
  mental_state_prevented_interview: boolean;
  has_refused_interview: boolean;
  is_no_longer_in_custody: boolean;
  has_declined_interview_or_release: boolean;
  has_plan_to_bail_out: boolean;
  is_incomplete_due_to_insufficient_notice: boolean;
  report_available_by_date: Maybe<Moment>;
  has_existing_or_report_re_referred: boolean;
  existing_or_report_date: Maybe<Moment>;
  is_incomplete_due_to_referral_volume: boolean;
  is_denied_or: boolean;
  ordered_tad_date: Maybe<Moment>;
  has_existing_or_report_previously_interviewed: boolean;
  last_interviewed_date: Maybe<Moment>;
  hold_out_state: string;
  other_reason_comments: string;
  signature_probation_supervisor: string;
  signature_team_member: string;
}

interface CourtMemoFormProps {
  booking: Booking;
  handleCheckboxChanged: (e: CheckboxChangeEvent) => void;
  handleInputChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  handleDatePickerChange: (date: Maybe<Moment>, name: string) => void;
  formData: CourtMemoFormData;
  isPrintView: boolean;
  isCurrentUserAdmin?: boolean;
}

export function CourtMemoForm(props: CourtMemoFormProps) {
  const {
    booking,
    handleCheckboxChanged,
    handleInputChange,
    handleDatePickerChange,
    formData,
    isPrintView,
    isCurrentUserAdmin,
  } = props;

  const getCheckboxes = (): JSX.Element => {
    return (
      <div className="avoid-page-break">
        {!isPrintView ||
        formData.was_under_the_influence_of_alcohol_or_drugs ? (
          <>
            <Checkbox
              name="was_under_the_influence_of_alcohol_or_drugs"
              onChange={handleCheckboxChanged}
              checked={formData.was_under_the_influence_of_alcohol_or_drugs}
            >
              The defendant was under the influence of drugs and/or alcohol.
            </Checkbox>
            <br />
          </>
        ) : null}
        {!isPrintView || formData.was_combative ? (
          <>
            <Checkbox
              name="was_combative"
              onChange={handleCheckboxChanged}
              checked={formData.was_combative}
            >
              The defendant was combative.
            </Checkbox>
            <br />
          </>
        ) : null}
        {!isPrintView || formData.was_in_medical_isolation ? (
          <>
            <Checkbox
              name="was_in_medical_isolation"
              onChange={handleCheckboxChanged}
              checked={formData.was_in_medical_isolation}
            >
              The defendant was in medical isolation.
            </Checkbox>
            <br />
          </>
        ) : null}
        {!isPrintView || formData.mental_state_prevented_interview ? (
          <>
            <Checkbox
              name="mental_state_prevented_interview"
              onChange={handleCheckboxChanged}
              checked={formData.mental_state_prevented_interview}
            >
              The defendant's mental state prevented an interview.
            </Checkbox>
            <br />
          </>
        ) : null}
        {!isPrintView || formData.has_refused_interview ? (
          <>
            <Checkbox
              name="has_refused_interview"
              onChange={handleCheckboxChanged}
              checked={formData.has_refused_interview}
            >
              The defendant refused to be interviewed.
            </Checkbox>
            <br />
          </>
        ) : null}
        {!isPrintView || formData.is_no_longer_in_custody ? (
          <>
            <Checkbox
              name="is_no_longer_in_custody"
              onChange={handleCheckboxChanged}
              checked={formData.is_no_longer_in_custody}
            >
              The defendant is no longer in custody.
            </Checkbox>
            <br />
          </>
        ) : null}
        {!isPrintView || formData.has_declined_interview_or_release ? (
          <>
            <Checkbox
              name="has_declined_interview_or_release"
              onChange={handleCheckboxChanged}
              checked={formData.has_declined_interview_or_release}
            >
              The defendant has indicated he / she does not wish to be
              interviewed / released.
            </Checkbox>
            <br />
          </>
        ) : null}

        {!isPrintView || formData.has_plan_to_bail_out ? (
          <>
            <Checkbox
              name="has_plan_to_bail_out"
              onChange={handleCheckboxChanged}
              checked={formData.has_plan_to_bail_out}
            >
              The defendant has or plans on bailing out.
            </Checkbox>
            <br />
          </>
        ) : null}

        {!isPrintView || formData.is_incomplete_due_to_insufficient_notice ? (
          <>
            <Checkbox
              name="is_incomplete_due_to_insufficient_notice"
              onChange={handleCheckboxChanged}
              checked={formData.is_incomplete_due_to_insufficient_notice}
            >
              The above referral was not received within the required minimum 48
              business hours’ notice required to allow sufficient time to
              interview the defendant and prepare the report. However, the
              report will be available by{" "}
              <DatePicker
                format={dateFormatList}
                value={formData.report_available_by_date || undefined}
                onChange={(e) =>
                  handleDatePickerChange(e, "report_available_by_date")
                }
              />
              .
            </Checkbox>
            <br />
          </>
        ) : null}

        {!isPrintView || formData.has_existing_or_report_re_referred ? (
          <>
            <Checkbox
              name="has_existing_or_report_re_referred"
              onChange={handleCheckboxChanged}
              checked={formData.has_existing_or_report_re_referred}
            >
              The above-mentioned case was re-referred to the Probation
              Department for an OR report with no request for additional
              information. Attached is the original OR report dated{" "}
              <DatePicker
                format={dateFormatList}
                value={formData.existing_or_report_date || undefined}
                onChange={(e) =>
                  handleDatePickerChange(e, "existing_or_report_date")
                }
              />
              . Our recommendation remains the same. If additional information
              is required, please re-refer the case, specifying the information
              that is needed.
            </Checkbox>
            <br />
          </>
        ) : null}

        {!isPrintView || formData.is_incomplete_due_to_referral_volume ? (
          <>
            <Checkbox
              name="is_incomplete_due_to_referral_volume"
              onChange={handleCheckboxChanged}
              checked={formData.is_incomplete_due_to_referral_volume}
            >
              As a result of the large volume of referrals received for
              defendants in custody and limited staffing, we are unable to
              complete all of today’s Own Recognizance reports.
            </Checkbox>
            <br />
          </>
        ) : null}

        {!isPrintView || formData.is_denied_or ? (
          <>
            <Checkbox
              name="is_denied_or"
              onChange={handleCheckboxChanged}
              checked={formData.is_denied_or}
            >
              The above-mentioned defendant is denied OR. On{" "}
              <DatePicker
                onChange={(e) => handleDatePickerChange(e, "ordered_tad_date")}
                format={dateFormatList}
                value={formData.ordered_tad_date || undefined}
              />
              , the Court ordered the defendant be on the TAD unit. The
              defendant is no longer suitable for the Pretrial Program
              currently. It should also be noted the defendant needs to return
              all outstanding TAD unit equipment to the Probation Department or
              will be charged for it.
            </Checkbox>
            <br />
          </>
        ) : null}

        {!isPrintView ||
        formData.has_existing_or_report_previously_interviewed ? (
          <>
            <Checkbox
              name="has_existing_or_report_previously_interviewed"
              onChange={handleCheckboxChanged}
              checked={formData.has_existing_or_report_previously_interviewed}
            >
              The above-mentioned defendant was last interviewed by the
              Probation Department for an OR report on{" "}
              <DatePicker
                onChange={(e) =>
                  handleDatePickerChange(e, "last_interviewed_date")
                }
                format={dateFormatList}
                value={formData.last_interviewed_date || undefined}
              />
              . Attached is the original OR report. Our recommendation remains
              the same. If additional information is required, please re-refer
              the case, specifying the information that is needed.
            </Checkbox>
            <br />
          </>
        ) : null}

        {!isPrintView || formData.hold_out_state ? (
          <>
            <Checkbox checked={formData.hold_out_state ? true : false}>
              The defendant has a (1551 PC) hold out of the State of{" "}
              <Input
                style={{ width: "200px" }}
                name="hold_out_state"
                value={formData.hold_out_state}
                onChange={handleInputChange}
              />
              .
            </Checkbox>
            <br />
          </>
        ) : null}

        {!isPrintView || formData.other_reason_comments ? (
          <>
            <Checkbox
              name="other_reason_comments"
              checked={formData.other_reason_comments ? true : false}
            >
              Other Comments:
            </Checkbox>
            {isPrintView ? (
              <p className="paragraph-text">{formData.other_reason_comments}</p>
            ) : (
              <TextArea
                onChange={handleInputChange}
                name="other_reason_comments"
                value={formData.other_reason_comments}
              ></TextArea>
            )}
          </>
        ) : null}
      </div>
    );
  };

  return (
    <div className={"recommendation-form"}>
      <CourtReportHeader
        booking={booking}
        reportType={"PRETRIAL NON-INTERVIEW REPORT"}
      />
      <CourtReportPreamble
        bookingDate={
          booking.booking_info.booking_date
            ? moment(booking.booking_info.booking_date)
            : null
        }
        arrestAgency={booking.booking_info.arrest_agency || ""}
        arrestedFor={booking.booking_info.arrested_for || ""}
      />
      <p className="section-title">RECOMMENDATION</p>
      <p className="paragraph-text">
        The Pretrial staff was unable to interview and assess the defendant due
        to the following:
      </p>
      {getCheckboxes()}
      <SignatureLine
        onChange={handleInputChange}
        formData={formData}
        isCurrentUserAdmin={isCurrentUserAdmin}
        isPrintView={!!isPrintView}
      />
      {isPrintView && <ConditionsOfRelease />}
    </div>
  );
}

const convertFormDataToCourtMemoInput = (
  formData: CourtMemoFormData
): CourtMemoInput => {
  return {
    was_under_the_influence_of_alcohol_or_drugs: booleanToYesNo(
      formData.was_under_the_influence_of_alcohol_or_drugs
    ),
    was_combative: booleanToYesNo(formData.was_combative),
    was_in_medical_isolation: booleanToYesNo(formData.was_in_medical_isolation),
    mental_state_prevented_interview: booleanToYesNo(
      formData.mental_state_prevented_interview
    ),
    has_refused_interview: booleanToYesNo(formData.has_refused_interview),
    is_no_longer_in_custody: booleanToYesNo(formData.is_no_longer_in_custody),
    has_declined_interview_or_release: booleanToYesNo(
      formData.has_declined_interview_or_release
    ),
    has_plan_to_bail_out: booleanToYesNo(formData.has_plan_to_bail_out),
    is_incomplete_due_to_insufficient_notice: booleanToYesNo(
      formData.is_incomplete_due_to_insufficient_notice
    ),
    report_available_by_date: formData.report_available_by_date
      ? formData.report_available_by_date.toISOString()
      : null,
    has_existing_or_report_re_referred: booleanToYesNo(
      formData.has_existing_or_report_re_referred
    ),
    existing_or_report_date: formData.existing_or_report_date
      ? formData.existing_or_report_date.toISOString()
      : null,
    is_incomplete_due_to_referral_volume: booleanToYesNo(
      formData.is_incomplete_due_to_referral_volume
    ),
    is_denied_or: booleanToYesNo(formData.is_denied_or),
    ordered_tad_date: formData.ordered_tad_date
      ? formData.ordered_tad_date.toISOString()
      : null,
    has_existing_or_report_previously_interviewed: booleanToYesNo(
      formData.has_existing_or_report_previously_interviewed
    ),
    last_interviewed_date: formData.last_interviewed_date
      ? formData.last_interviewed_date.toISOString()
      : null,
    hold_out_state: formData.hold_out_state || null,
    other_reason_comments: formData.other_reason_comments || null,
    signature_probation_supervisor:
      formData.signature_probation_supervisor || null,
    signature_team_member: formData.signature_team_member || null,
  };
};

const convertBookingToCourtMemoFormData = (booking: Booking) => {
  if (!booking.court_memo) {
    return {};
  }

  const { court_memo } = booking;
  return {
    was_under_the_influence_of_alcohol_or_drugs: yesNoToBoolean(
      court_memo.was_under_the_influence_of_alcohol_or_drugs
    ),
    was_combative: yesNoToBoolean(court_memo.was_combative),
    was_in_medical_isolation: yesNoToBoolean(
      court_memo.was_in_medical_isolation
    ),
    mental_state_prevented_interview: yesNoToBoolean(
      court_memo.mental_state_prevented_interview
    ),
    has_refused_interview: yesNoToBoolean(court_memo.has_refused_interview),
    is_no_longer_in_custody: yesNoToBoolean(court_memo.is_no_longer_in_custody),
    has_declined_interview_or_release: yesNoToBoolean(
      court_memo.has_declined_interview_or_release
    ),
    has_plan_to_bail_out: yesNoToBoolean(court_memo.has_plan_to_bail_out),
    is_incomplete_due_to_insufficient_notice: yesNoToBoolean(
      court_memo.is_incomplete_due_to_insufficient_notice
    ),
    report_available_by_date: court_memo.report_available_by_date
      ? moment(court_memo.report_available_by_date)
      : null,
    has_existing_or_report_re_referred: yesNoToBoolean(
      court_memo.has_existing_or_report_re_referred
    ),
    existing_or_report_date: court_memo.existing_or_report_date
      ? moment(court_memo.existing_or_report_date)
      : null,
    is_incomplete_due_to_referral_volume: yesNoToBoolean(
      court_memo.is_incomplete_due_to_referral_volume
    ),
    is_denied_or: yesNoToBoolean(court_memo.is_denied_or),
    ordered_tad_date: court_memo.ordered_tad_date
      ? moment(court_memo.ordered_tad_date)
      : null,
    has_existing_or_report_previously_interviewed: yesNoToBoolean(
      court_memo.has_existing_or_report_previously_interviewed
    ),
    last_interviewed_date: court_memo.last_interviewed_date
      ? moment(court_memo.last_interviewed_date)
      : null,
    hold_out_state: court_memo.hold_out_state || null,
    other_reason_comments: court_memo.other_reason_comments || null,
    signature_probation_supervisor:
      court_memo.signature_probation_supervisor || null,
    signature_team_member: court_memo.signature_team_member || null,
  };
};

export function CourtMemoPage(props: { booking: Booking }) {
  const { booking } = props;
  const intitialData = convertBookingToCourtMemoFormData(booking);
  const [isCurrentUserAdmin, setIsCurrentUserAdmin] = useState(false);

  const [formData, setFormData] = useState(intitialData as CourtMemoFormData);

  const handleCheckboxChanged = (e: CheckboxChangeEvent) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name as string]: checked,
    });
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDatePickerChange = (date: Maybe<Moment>, name: string) => {
    setFormData({ ...formData, [name]: date });
  };

  const handleSaveCourtMemoClicked = async () => {
    const courtMemoInputData = convertFormDataToCourtMemoInput(formData);

    await submitBookingCourtMemo({
      booking_id: booking.id,
      court_memo: courtMemoInputData,
    });
  };

  const getBlankRecommendationForm = (): RecommendationFormInput => {
    return {
      has_verified_employment_information: null,
      employment_comments: null,
      pending_disposition: null,
      pending_disposition_comments: null,
      vprai_override_public_safety_comments: null,
      incident_summary_notes: null,
      mandatory_detention: null,
      additional_information: null,
      release_recommendation: null,
      conditions_of_release: {
        supervision_and_custody: null,
        not_leave_state_or_residence: null,
        notify_change_employment_or_residence: null,
        report_regularly_pretrial: null,
        report_to_probation: null,
        tcemp: null,
        tcv3a: null,
        curfew: null,
        location_tracking: null,
        alcohol_detection: null,
        alcohol_detection_with_curfew: null,
        follow_court_protective_orders: null,
        tcaap: null,
        tcbaf: null,
        tcnax: null,
        tcnae: null,
        tcgnx: null,
        tcgwr: null,
        follow_court_probation_conditions: null,
        pretrial_program: null,
      },
      reasons_against_release: {
        high_risk_to_fail_pretrial_release: null,
        vprai_override_public_safety: null,
        vprai_override_victim_residence: null,
      },
      criminal_history: {
        two_or_more_violent_convictions: null,
        one_or_more_misdemeanor_or_felony_convictions: null,
        no_prior_criminal_history: null,
      },
      afs_info: {
        afs_check_date: null,
        number_of_weapons_registered: null,
      },
      substance_abuse_comments: null,
      other_recommendation_notes: null,
      victim_comments: null,
      signature_probation_supervisor: null,
      signature_team_member: null,
    };
  };

  return (
    <div style={{ padding: "20px" }}>
      <CourtMemoForm
        booking={booking}
        handleDatePickerChange={handleDatePickerChange}
        handleInputChange={handleInputChange}
        handleCheckboxChanged={handleCheckboxChanged}
        formData={formData}
        isPrintView={false}
        isCurrentUserAdmin={isCurrentUserAdmin}
      />
      <div className="form-container recommendation-actions">
        <InternalNotesSection booking={booking} />
        <ActionSection
          booking={booking}
          setIsCurrentUserAdmin={setIsCurrentUserAdmin}
          getRecommendationFormInput={() => getBlankRecommendationForm()}
          renderPrintButton={() => {
            return (
              <Button>
                <a
                  href={`/${ROOT_PATH}/print_court_memo/${booking.id}`}
                  target="_blank"
                >
                  Print
                </a>
              </Button>
            );
          }}
          additionalSave={handleSaveCourtMemoClicked}
        />
      </div>
    </div>
  );
}

export function CourtMemoPrintContainer(props: { booking: Booking }) {
  const { booking } = props;
  const formData = convertBookingToCourtMemoFormData(
    booking
  ) as CourtMemoFormData;

  const handleInputChange = (
    _e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    return null;
  };

  const handleDatePickerChange = (_date: Maybe<Moment>, _name: string) => {
    return null;
  };

  const handleCheckboxChanged = (_e: CheckboxChangeEvent) => {
    return null;
  };

  return (
    <CourtMemoForm
      booking={booking}
      handleDatePickerChange={handleDatePickerChange}
      handleInputChange={handleInputChange}
      handleCheckboxChanged={handleCheckboxChanged}
      formData={formData}
      isPrintView={true}
    />
  );
}
