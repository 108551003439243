import {
  MutationAddParticipantAssessmentResultArgs,
  MutationScoreParticipantAssessmentResultArgs,
} from "../graphql-types";
import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "../shared/lib/graphql/commitMutation";
import { RecordSourceSelectorProxy } from "relay-runtime";
import { checkMutationReturn } from "./util";

export const addParticipantAssessmentResult = async (
  variables: MutationAddParticipantAssessmentResultArgs
) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation participantAssessmentResultAddParticipantAssessmentResultMutation(
          $participant_id: String!
          $scored_by: String!
          $case_number: String!
        ) {
          addParticipantAssessmentResult(
            participant_id: $participant_id
            scored_by: $scored_by
            case_number: $case_number
          ) {
            participant_assessment_result {
              id
              status
              case_number
              scored_by
              interview_and_assessment {
                research_factors {
                  has_prior_misdemeanor_conviction
                  has_prior_felony_conviction
                  prior_violent_convictions_count
                  prior_FTAs_past_two_years_count
                  has_prior_FTAs_older_than_two_years
                  has_prior_sentence_to_incarceration
                }
                risk_factors {
                  has_active_community_supervision
                  is_charge_felony_drug_theft_or_fraud
                  has_pending_charges
                  has_criminal_history
                  has_two_or_more_FTAs
                  has_two_or_more_violent_convictions
                  has_history_of_drug_abuse
                  employed_at_time_of_arrest
                }
                instrument_completed_at
                last_updated_at
              }
              risk_assessment_result {
                score
                level
                failure_rate
              }
            }
            result
            description
            errors
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreAddAssessmentResult(
          store,
          "addParticipantAssessmentResult",
          variables.participant_id
        );
      },
    },
    "Error while adding assessment."
  );
};

export const scoreParticipantAssessmentResult = async (
  variables: MutationScoreParticipantAssessmentResultArgs
) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation participantAssessmentResultScoreParticipantAssessmentResultMutation(
          $participant_assessment_result_id: String!
          $research_factors: ResearchFactorInput!
          $risk_factors: RiskFactorInput!
        ) {
          scoreParticipantAssessmentResult(
            participant_assessment_result_id: $participant_assessment_result_id
            research_factors: $research_factors
            risk_factors: $risk_factors
          ) {
            participant_assessment_result {
              id
              status
              case_number
              scored_by
              interview_and_assessment {
                research_factors {
                  has_prior_misdemeanor_conviction
                  has_prior_felony_conviction
                  prior_violent_convictions_count
                  prior_FTAs_past_two_years_count
                  has_prior_FTAs_older_than_two_years
                  has_prior_sentence_to_incarceration
                }
                risk_factors {
                  has_active_community_supervision
                  is_charge_felony_drug_theft_or_fraud
                  has_pending_charges
                  has_criminal_history
                  has_two_or_more_FTAs
                  has_two_or_more_violent_convictions
                  has_history_of_drug_abuse
                  employed_at_time_of_arrest
                }
                instrument_completed_at
                last_updated_at
              }
              risk_assessment_result {
                score
                level
                failure_rate
              }
            }
            result
            description
            errors
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreScoreParticipantAssessmentResult(
          "scoreParticipantAssessmentResult",
          store,
          variables.participant_assessment_result_id
        );
      },
    },
    "Error while adding assessment."
  );
};

const updateRelayStoreAddAssessmentResult = (
  store: RecordSourceSelectorProxy,
  rootFieldName: string,
  participant_id: string
) => {
  const payload = checkMutationReturn(store, rootFieldName);

  if (!payload) return;

  const newAssessmentResult = payload.getLinkedRecord(
    "participant_assessment_result"
  );

  const participant = store.get(participant_id);

  if (!participant) {
    console.error(
      `Error while updating the store after ${rootFieldName}.\n` +
        " Can't find the participant in the store."
    );
    return;
  }

  const assessmentResultsList = participant.getLinkedRecords(
    "assessment_results"
  );

  //add new obligation to front of obligationsList
  const combinedAssessmentResults = [newAssessmentResult].concat(
    assessmentResultsList
  );

  //set the new Obligation
  participant.setLinkedRecords(combinedAssessmentResults, "assessment_results");
};

export const updateRelayStoreScoreParticipantAssessmentResult = (
  rootFieldName: string,
  store: RecordSourceSelectorProxy,
  id: string
) => {
  const payload = checkMutationReturn(store, rootFieldName);

  if (!payload) return;

  const existing = store.get(id);

  existing!.copyFieldsFrom(payload);
};
