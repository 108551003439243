import React from "react";
import { Card, Row, Col, Typography } from "antd";
import { Referral } from "../../graphql-types";
import moment from "moment-timezone";

const { Text } = Typography;

interface ReferralCardProps {
  step: number;
  total: number;
  referral: Referral;
}

export default function ReferralCard(props: ReferralCardProps) {
  const { referral, step, total } = props;
  return (
    <Card
      title={`Referral (${step + 1} of ${total})`}
      bordered={false}
      style={{ width: 300 }}
    >
      <Row>
        <Col span={12}>
          <Text strong>Name:</Text>
        </Col>
        <Col span={12}>{`${referral.first_name} ${
          referral.middle_name ? `${referral.middle_name} ` : ""
        }${referral.last_name}`}</Col>
      </Row>
      <Row>
        <Col span={12}>
          <Text strong>CJIS Referral ID:</Text>
        </Col>
        <Col span={12}>{referral.cjis_referral_sid}</Col>
      </Row>
      <Row>
        <Col span={12}>
          <Text strong>Court Case No.:</Text>
        </Col>
        <Col span={12}>{referral.court_case_no}</Col>
      </Row>
      <Row>
        <Col span={12}>
          <Text strong>Date of Birth:</Text>
        </Col>
        <Col span={12}>{referral.date_of_birth}</Col>
      </Row>
      <Row>
        <Col span={12}>
          <Text strong>Event Code:</Text>
        </Col>
        <Col span={12}>{referral.event_code}</Col>
      </Row>
      <Row>
        <Col span={12}>
          <Text strong>Event Date/Time:</Text>
        </Col>
        <Col span={12}>
          {moment(referral.event_datetime).format("MM/DD/YYYY h:mm a")}
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Text strong>Referral Date:</Text>
        </Col>
        <Col span={12}>
          {moment(referral.referral_date).format("MM/DD/YYYY h:mm a")}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Row>
            <Text strong>Notes: </Text>
          </Row>
          <Text>{referral.event_text}</Text>
        </Col>
      </Row>
    </Card>
  );
}
