import * as React from "react";

export type MatcherRequest = {
  operation: string | { name?: string };
};

export type MatcherUrlMock = { url: string };
export type MatcherGraphqlMock = { relayRequestName?: string };

export type MatcherParams = {
  request: MatcherRequest;
  options: any;
  method?: string;
};

export interface FeatureFlagsDefs {
  /**
   * Controls variable portions of broadcast messages & chat
   */
  messages: {
    /**
     * Header explanation of how broadcast messages behav
     */
    broadcastMessagesHeaderString: string;
    /**
     * Whether or not to display chat and notes
     */
    display?: boolean;
  };
  /**
   * **false** hides "Care Plan" tab in Client Detail
   * **false** hides "Add Client" button in Client List
   */
  supportsCarePlanEditing: boolean;
  /**
   * **false** makes the Status column in the Client List show "Not Set" for each client
   *   we made a conscious decision not to turn off the feature completely so
   *   clients could see how that it exists, even tho they don't support it
   * **true** make them show as the true client status
   */
  supportsProgramCompliance: boolean;
  /**
   * **false** hides both assessment fields (1. instrument and 2. score) in Add Client modal
   */
  supportsAssessment: boolean;
  /**
   * **false** hides the probation number in the add / update participant modal
   */
  supportsProbationNumber?: boolean;
  /**
   * Changes the name of the client grouping used throughout the app
   */
  whatIsRiskLevelCalled: string;
  /**
   * Goals & Tasks items
   * n.b.: there is one flag "showTasksInClientTable" outside this group
   */
  goalsTasks: {
    /**
     * Display Goals/Tasks in app
     *
     * see also "showTasksInClientTable"
     */
    display?: boolean;
    whatIsGoalCalledSingular?: string;
    whatIsGoalCalledPlural?: string;
    whatIsTaskCalledSingular?: string;
    whatIsTaskCalledPlural?: string;
    goalsAndTasksTabLabel?: string;
  };
  /**
   * Show the client profile tab
   */
  supportsClientProfile: boolean;
  /**
   * Show the client victim tab (default to false)
   */
  supportsVictims?: boolean;
  /**
   * Show the client's accounting tab
   */

  supportsClientAccounting?: boolean;
  /**
   * Show the client's document tab
   */
  supportsClientDocuments?: boolean;
  /**
   * Show the participant assessment tab tab
   */
  supportsParticipantRiskAssessments?: boolean;
  /**
   * shows the cases tab (default is to not show the tab if left undefined)
   */
  supportsCases?: boolean;
  /**
   * Top level Help URL
   */
  helpUrl: string;
  /**
   * **true** changes the Client List table columns to:
   * - name
   * - sent message unread count
   * - tasks
   * - next event
   * - status
   *
   * **false** changes the Client List table columns to:
   * - name
   * - sent message unread count
   * - events
   * - next event
   * - last event
   * - status
   */
  showTasksInClientTable: boolean;
  /**
   * true (default) includes events in the client list table
   * false removes events (performance optimization)
   */
  showEventsInClientTable: boolean;
  /**
   * true (non-default) includes program title in the client list table
   */
  showProgramTitleInClientTable: boolean;
  /**
   * true (non-default) includes risk level in the client list table
   */
  showRiskLevelInClientTable: boolean;
  /**
   * true (default) includes sms status in the client list table
   */
  showSmsStatusInClientTable: boolean;
  /**
   * true (non-default) includes client start date in the client list table (displays as "Time in Program")
   */
  showStartDateInClientTable: boolean;
  /**
   * Optional, defaults to false, shows the client's end date in the client table
   */
  showEndDateInClientTable?: boolean;
  /**
   * true (non-default) includes client address in the client list table
   */
  showAddressInClientTable: boolean;
  /**
   * true (non-default) includes client address in the client list table
   */
  showHasAppInClientTable: boolean;
  /**
   * optionally includes client address in the client list table
   */
  showCaseNumberInClientTable?: boolean;
  /**
   * false (default) includes information on whether a client needs data input (e.g employment confirmation, address confirmation)
   * updated
   */
  showDataUpdateNeededInClientTable: boolean;
  /**
   * Optional, false (default) includes probation officer's name in the client list table
   */
  showProbationOfficerInClientTable?: boolean;
  /**
   * Sets the title on the Client List page
   */
  clientListPageName: string;
  /**
   * Sets the default tab
   * based on participantDetailsTab exported from
   */
  defaultTabInClientDetails?:
    | "carePlan"
    | "messagesNotes"
    | "timeline"
    | "profile"
    | "goalsTasks"
    | "accounting"
    | "documents"
    | "assessment"
    | "cases";
  /**
   * Defines the display name for "Participant" / "Client"
   */
  clientDisplayTerm: string;
  /**
   * Defines the display name for "Case Manager"
   */
  caseManagerDisplayTerm: string;
  /**
   * Defines the display term for "Compliance"
   */
  whatIsComplianceCalled: string;
  /**
   * Defines the display term for "Disposition"
   */
  whatIsDispositionCalled: string;
  /**
   * Which columns to display for ParticipantAccountInfo (top level participant details)
   */
  clientDetailsColumnsToDisplay: ClientDetailsColumnsType;
  /**
   * default (false) answers "Should we sort the client list by next event?"
   */
  sortClientListByNextEvent: boolean;
  /**
   * Which additional rows to display for ParticipantProfileContainer
   */
  clientProfileAdditionalRows: ClientProfileAdditionalRowsType;
  /**
   * Determines if the next event and next event date columns
   * Will include verifiation / confirmation events (default is falsey)
   */
  includeCheckInEventsInNextEventDisplay?: boolean;
  /**
   * All things timeline
   */
  timeline: {
    /**
     * Display the timeline tab, like at all?
     */
    display?: boolean;
    /**
     * Hide notifications in timeline?
     */
    hideNotifications?: boolean;
  };
  /**
   * supports Broadcast Messaging. display in the nav
   */
  supportsBroadcastMessaging: boolean;
  /**
   * Determines if the case analytics tab is available (default is false)
   */
  supportsCaseAnalytics?: boolean;
  /**
   * Determines if the calendar tab is available (default is false)
   */
  supportsCalendar?: boolean;
  /**
   * Determines if the classes tab is available (default is no)
   */
  supportsClasses?: boolean;
  /**
   * Determines if the bookings tab is available (default is no)
   */
  supportsBookings?: boolean;
  /**
   * Determines if pre-trial prospects workflow is supported (Marin Pretrial only), default is false
   */
  supportsProspects?: boolean;
  /**
   * Determines if care plan setup is available, default is false
   */

  supportsCarePlanSetup?: boolean;
  /**
   * determines which types of broadcast messaging buckets are available
   * for a given client. Needed as some clients lack needed data for activity
   * broadcast messages
   */
  broadcastMessagingBuckets: string[];
  /**
   * If broadcastMessagingBuckets includes program, this setting will determine
   * the names of the program buckets and the corresponding regex filter that
   * will be applied against program_title.
   *
   * This probably isn't the right long-term configuration for this feature flag,
   * but it's a start. If the feature proves useful, this should probably be
   * replaced by a field name and a regex to be applied against that field.
   * See functions/src/firestore/messages/decomposeBroadcastMessages.ts
   * for all of the backend implementation.
   */
  broadcastMessagingBucketsProgramRegexes?: { name: string; regex: string }[];
  /**
   * All things workflow
   */
  workflow: {
    /**
     * Display the workflow, at all?
     */
    display: boolean;
    /**
     * Use the legacy, clickable, review item checkboxes on workflow?
     */
    reviewIsLegacy?: boolean;
    /**
     * Default is false, shows a button allowing download of check in data
     */
    showCheckInDownload?: boolean;
  };
  /**
   * supports chat. enables/disables the chat input only—chat is still visible
   */
  supportsChat: boolean;

  /**
   * Supports the "notes" (on the chat tab of participant details)
   */
  supportsNotes?: boolean;

  /**
   * Allows or disallows adding obligations to a care plan in MCAW
   */
  supportsAddingObligationsToCarePlan: boolean;
  /**
   * Shows or hides obligation start / end times in MCAW
   */
  supportsDateRangesOnObligations: boolean;
  /**
   * Shows or hides button to download chat message log
   */
  supprtsChatLogDownload: boolean;
  /**
   * Data URI of logo image
   */
  logoDataURI: string | null;
  /**
   * Deactivation flow things
   */
  deactivation: {
    /**
     * Shows or hides button to deactive a participant
     */
    allowDeactivation?: boolean;
    /**
     * Populate supervision_end_date on deactivation
     */
    populateEndDateOnDeactivation?: boolean;
  };
  /**
   * Shows or hides button to deactive a participant
   */
  showRecurrence: boolean;
  /**
   * Misc formatting
   */
  formatting: {
    /**
     * Start Date shows days always at or under this number
     */
    startDateHumanizeCutoff?: number;
  };
  /**
   * Adds a link in header to the analytics-report page
   */
  showAnalyticsReportLink: boolean;
  /**
   * Adds a link in header to the analytics-report page
   * Optional, defaults to false
   */
  showHealthLink?: boolean;
  /**
   * Displays the client's last name first in the participant table
   */
  lastNameFirst: boolean;
  /**
   * Displays the client's last name first in the participant table
   */
  eventAttendanceTitles: { [key: string]: string };
}

export interface CattFeatureFlagDefs {
  broadcast_messaging_buckets: Array<string>;
  case_manager_display_term: string;
  chat_enabled: boolean;
  "client_details_columns_to_display.access_code": boolean;
  "client_details_columns_to_display.case_manager": boolean;
  "client_details_columns_to_display.compliance": boolean;
  "client_details_columns_to_display.joined": boolean;
  "client_details_columns_to_display.language": boolean;
  "client_details_columns_to_display.name": boolean;
  "client_details_columns_to_display.phone": boolean;
  "client_details_columns_to_display.risk_level": boolean;
  "client_details_columns_to_display.sms_consent": boolean;
  "client_details_columns_to_display.sms_enabled": boolean;
  "client_details_columns_to_display.supervision_begin_date": boolean;
  "client_details_columns_to_display.supervision_end_date": boolean;
  client_display_term: string;
  client_list_page_name: string;
  "deactivation.allow_deactivation": boolean;
  default_tab_in_client_detail_is_tasks: boolean;
  documents_enabled: boolean;
  group_chat_enabled: boolean;
  help_url: string;
  logo_data_uri: string;
  "messages.broadcast_messages_header_string": string;
  "office_hours.label.en": string;
  "office_hours.label.es": string;
  show_events_in_client_table: boolean;
  show_program_title_in_client_table: boolean;
  show_risk_level_in_client_table: boolean;
  show_sms_status_in_client_table: boolean;
  show_tasks_in_client_table: boolean;
  supports_adding_obligations_to_care_plan: boolean;
  supports_assessment: boolean;
  supports_broadcast_messaging: boolean;
  supports_care_plan_editing: boolean;
  supports_date_ranges_on_obligations: boolean;
  supports_program_compliance: boolean;
  supprts_chat_log_download: boolean;
  tasks_goals_enabled: boolean;
  what_is_compliance_called: string;
  what_is_disposition_called: string;
  what_is_risk_level_called: string;
  "workflow.display": boolean;
}

export type MatcherResult<T> = T | boolean;

export type Matcher = <T>(params: MatcherParams, mock: T) => MatcherResult<T>;

export type WrappedComponentType = React.ComponentType<any> & {
  displayName: string;
  Name: string;
  WrappedComponent: React.ComponentType<any>;
};

export type wrapComponentFunc = <T>(
  newComponent: T,
  wrappedComponent: React.ComponentType<any>,
  prefix?: string
) => T;

export const wrapComponent: wrapComponentFunc = function <T>(
  newComponent: T,
  wrappedComponent: React.ComponentType<any>,
  prefix?: string
) {
  const result = newComponent as any;
  result.displayName = `${prefix || ""}(${
    (wrappedComponent as any).displayName || (wrappedComponent as any).Name
  })`;
  result.WrappedComponent = wrappedComponent;

  return result as T;
};

export interface ClientDetailsColumnsType {
  address: boolean;
  phone: boolean;
  name: boolean;
  case_manager: boolean;
  cases: boolean;
  language: boolean;
  access_code: boolean;
  joined: boolean;
  supervision_begin_date: boolean;
  supervision_end_date: boolean;
  sms_consent: boolean;
  sms_enabled: boolean;
  risk_level: boolean;
  compliance: boolean;
  currentDevice: boolean;
  probationNumber?: boolean;
}

export interface ClientProfileAdditionalRowsType {
  is_supervised_probation?: boolean;
  probation_officer_name?: boolean;
}

export interface OfficeHoursType {
  label: string;
}
