import React from "react";
import { ProspectStatus, ProspectStatusCounts } from "../../graphql-types";
import { LinkedTabs } from "../../shared/components/elements/LinkedTabs";
import { Link, RouteComponentProps } from "@reach/router";
import { PageHeader } from "../../shared/components/elements/PageHeader";
import { getBaseUri } from "../../components/screens/ParticipantListContainer";
import { ProspectTableQueryContainer } from "./ProspectTableQueryContainer";

export interface TabWithLinkTo {
  value: string;
  key: string;
  linkTo: string;
  selected: boolean;
  count?: number;
}

const tabTitlesAndKeys: { value: string; key: string }[] = [
  { value: "New", key: ProspectStatus.New },
  { value: "Interview and Assessment", key: ProspectStatus.PendingInterview },
  { value: "Recommendation", key: ProspectStatus.PendingRecommendation },
  { value: "Reviewed Reports", key: ProspectStatus.PendingSubmission },
  { value: "Court Decision", key: ProspectStatus.PendingCourtDecision },
  { value: "Enroll/Archive", key: ProspectStatus.EnrollOrArchive },
  { value: "Search Prospects", key: "search" },
];

export function Prospects(
  props: RouteComponentProps<{
    prospect_status: string;
    bookingProspectStatusCounts: ProspectStatusCounts;
  }>
) {
  const {
    path,
    prospect_status: prospectStatus,
    bookingProspectStatusCounts,
  } = props;

  const prospectTabsList: TabWithLinkTo[] = tabTitlesAndKeys.map((tab) => {
    const { key } = tab;
    return {
      ...tab,
      linkTo: `${getBaseUri(path)}${key}`,
      selected: prospectStatus === key,
      count: bookingProspectStatusCounts
        ? bookingProspectStatusCounts[key as ProspectStatus]
        : undefined,
    };
  });

  return (
    <>
      <PageHeader
        pageName="Prospects"
        pageBlurb={`Conduct interview/risk assessments, prepare court recommendations, record court decisions, and enroll clients.`}
      ></PageHeader>
      <div className="constrained-section">
        <LinkedTabs
          tabs={prospectTabsList}
          LinkComponent={Link}
          tabProps={{ type: "boxed", className: "risk-tabs" }}
        />
      </div>
      <ProspectTableQueryContainer prospectStatus={prospectStatus} />
    </>
  );
}
