import graphql from "babel-plugin-relay/macro";

export const participantAssessmentsQuery = graphql`
  query ParticipantAssessmentGraphqlQuery($id: String!) {
    me {
      case_manager {
        id
        name {
          first
          last
        }
      }
    }
    participants(id: $id) {
      participant {
        id
        name {
          first
          last
        }
        assessment_results {
          id
          status
          case_number
          scored_by
          interview_and_assessment {
            research_factors {
              has_prior_misdemeanor_conviction
              has_prior_felony_conviction
              prior_violent_convictions_count
              prior_FTAs_past_two_years_count
              has_prior_FTAs_older_than_two_years
              has_prior_sentence_to_incarceration
            }
            risk_factors {
              has_active_community_supervision
              is_charge_felony_drug_theft_or_fraud
              has_pending_charges
              has_criminal_history
              has_two_or_more_FTAs
              has_two_or_more_violent_convictions
              has_history_of_drug_abuse
              employed_at_time_of_arrest
            }
            instrument_completed_at
            last_updated_at
          }
          risk_assessment_result {
            score
            level
            failure_rate
          }
        }
      }
    }
  }
`;
