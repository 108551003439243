/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type ProspectsGraphqlStatusCountGraphqlQueryVariables = {};
export type ProspectsGraphqlStatusCountGraphqlQueryResponse = {
    readonly booking_prospect_status_counts: {
        readonly new: number;
        readonly pending_interview: number;
        readonly pending_recommendation: number;
        readonly pending_submission: number;
        readonly pending_court_decision: number;
        readonly enroll_or_archive: number;
    };
};
export type ProspectsGraphqlStatusCountGraphqlQuery = {
    readonly response: ProspectsGraphqlStatusCountGraphqlQueryResponse;
    readonly variables: ProspectsGraphqlStatusCountGraphqlQueryVariables;
};



/*
query ProspectsGraphqlStatusCountGraphqlQuery {
  booking_prospect_status_counts {
    new
    pending_interview
    pending_recommendation
    pending_submission
    pending_court_decision
    enroll_or_archive
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "booking_prospect_status_counts",
            "storageKey": null,
            "args": null,
            "concreteType": "ProspectStatusCounts",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "new",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "pending_interview",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "pending_recommendation",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "pending_submission",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "pending_court_decision",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "enroll_or_archive",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "ProspectsGraphqlStatusCountGraphqlQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": (v0 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "ProspectsGraphqlStatusCountGraphqlQuery",
            "argumentDefinitions": [],
            "selections": (v0 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "ProspectsGraphqlStatusCountGraphqlQuery",
            "id": null,
            "text": "query ProspectsGraphqlStatusCountGraphqlQuery {\n  booking_prospect_status_counts {\n    new\n    pending_interview\n    pending_recommendation\n    pending_submission\n    pending_court_decision\n    enroll_or_archive\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '95043bea71c2975e845777b3faefbd07';
export default node;
