/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type Employment = "employed" | "full_time_student" | "none" | "primary_caregiver" | "retired" | "%future added value";
export type ParticipantAssessmentResultStatus = "draft" | "finalized" | "new" | "%future added value";
export type YesNo = "no" | "yes" | "%future added value";
export type ZeroToThreeOrMore = "more" | "one" | "three" | "two" | "zero" | "%future added value";
export type ParticipantAssessmentGraphqlQueryVariables = {
    readonly id: string;
};
export type ParticipantAssessmentGraphqlQueryResponse = {
    readonly me: ({
        readonly case_manager: ({
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
        }) | null;
    }) | null;
    readonly participants: ({
        readonly participant: ReadonlyArray<({
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
            readonly assessment_results: ReadonlyArray<({
                readonly id: string;
                readonly status: ParticipantAssessmentResultStatus;
                readonly case_number: string;
                readonly scored_by: string | null;
                readonly interview_and_assessment: {
                    readonly research_factors: ({
                        readonly has_prior_misdemeanor_conviction: YesNo | null;
                        readonly has_prior_felony_conviction: YesNo | null;
                        readonly prior_violent_convictions_count: ZeroToThreeOrMore | null;
                        readonly prior_FTAs_past_two_years_count: ZeroToThreeOrMore | null;
                        readonly has_prior_FTAs_older_than_two_years: YesNo | null;
                        readonly has_prior_sentence_to_incarceration: YesNo | null;
                    }) | null;
                    readonly risk_factors: ({
                        readonly has_active_community_supervision: YesNo | null;
                        readonly is_charge_felony_drug_theft_or_fraud: YesNo | null;
                        readonly has_pending_charges: YesNo | null;
                        readonly has_criminal_history: YesNo | null;
                        readonly has_two_or_more_FTAs: YesNo | null;
                        readonly has_two_or_more_violent_convictions: YesNo | null;
                        readonly has_history_of_drug_abuse: YesNo | null;
                        readonly employed_at_time_of_arrest: Employment | null;
                    }) | null;
                    readonly instrument_completed_at: string | null;
                    readonly last_updated_at: string | null;
                };
                readonly risk_assessment_result: ({
                    readonly score: number | null;
                    readonly level: string | null;
                    readonly failure_rate: string | null;
                }) | null;
            }) | null> | null;
        }) | null> | null;
    }) | null;
};
export type ParticipantAssessmentGraphqlQuery = {
    readonly response: ParticipantAssessmentGraphqlQueryResponse;
    readonly variables: ParticipantAssessmentGraphqlQueryVariables;
};



/*
query ParticipantAssessmentGraphqlQuery(
  $id: String!
) {
  me {
    case_manager {
      id
      name {
        first
        last
      }
    }
  }
  participants(id: $id) {
    participant {
      id
      name {
        first
        last
      }
      assessment_results {
        id
        status
        case_number
        scored_by
        interview_and_assessment {
          research_factors {
            has_prior_misdemeanor_conviction
            has_prior_felony_conviction
            prior_violent_convictions_count
            prior_FTAs_past_two_years_count
            has_prior_FTAs_older_than_two_years
            has_prior_sentence_to_incarceration
          }
          risk_factors {
            has_active_community_supervision
            is_charge_felony_drug_theft_or_fraud
            has_pending_charges
            has_criminal_history
            has_two_or_more_FTAs
            has_two_or_more_violent_convictions
            has_history_of_drug_abuse
            employed_at_time_of_arrest
          }
          instrument_completed_at
          last_updated_at
        }
        risk_assessment_result {
          score
          level
          failure_rate
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "String!",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "name",
        "storageKey": null,
        "args": null,
        "concreteType": "PersonName",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "first",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "last",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v3 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "me",
            "storageKey": null,
            "args": null,
            "concreteType": "Me",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "case_manager",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CaseManager",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/)
                    ]
                }
            ]
        } as any),
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "participants",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                }
            ],
            "concreteType": "Pagination",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participant",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Participant",
                    "plural": true,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "assessment_results",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ParticipantAssessmentResult",
                            "plural": true,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "status",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "case_number",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "scored_by",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "interview_and_assessment",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "InterviewAndAssessment",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "research_factors",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "ResearchFactor",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "has_prior_misdemeanor_conviction",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "has_prior_felony_conviction",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "prior_violent_convictions_count",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "prior_FTAs_past_two_years_count",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "has_prior_FTAs_older_than_two_years",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "has_prior_sentence_to_incarceration",
                                                    "args": null,
                                                    "storageKey": null
                                                }
                                            ]
                                        },
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "risk_factors",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "RiskFactor",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "has_active_community_supervision",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "is_charge_felony_drug_theft_or_fraud",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "has_pending_charges",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "has_criminal_history",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "has_two_or_more_FTAs",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "has_two_or_more_violent_convictions",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "has_history_of_drug_abuse",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "employed_at_time_of_arrest",
                                                    "args": null,
                                                    "storageKey": null
                                                }
                                            ]
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "instrument_completed_at",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "last_updated_at",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "risk_assessment_result",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "RiskAssessmentResult",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "score",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "level",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "failure_rate",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "ParticipantAssessmentGraphqlQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v3 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "ParticipantAssessmentGraphqlQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v3 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "ParticipantAssessmentGraphqlQuery",
            "id": null,
            "text": "query ParticipantAssessmentGraphqlQuery(\n  $id: String!\n) {\n  me {\n    case_manager {\n      id\n      name {\n        first\n        last\n      }\n    }\n  }\n  participants(id: $id) {\n    participant {\n      id\n      name {\n        first\n        last\n      }\n      assessment_results {\n        id\n        status\n        case_number\n        scored_by\n        interview_and_assessment {\n          research_factors {\n            has_prior_misdemeanor_conviction\n            has_prior_felony_conviction\n            prior_violent_convictions_count\n            prior_FTAs_past_two_years_count\n            has_prior_FTAs_older_than_two_years\n            has_prior_sentence_to_incarceration\n          }\n          risk_factors {\n            has_active_community_supervision\n            is_charge_felony_drug_theft_or_fraud\n            has_pending_charges\n            has_criminal_history\n            has_two_or_more_FTAs\n            has_two_or_more_violent_convictions\n            has_history_of_drug_abuse\n            employed_at_time_of_arrest\n          }\n          instrument_completed_at\n          last_updated_at\n        }\n        risk_assessment_result {\n          score\n          level\n          failure_rate\n        }\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '9f749443bdf4ba669f195babd45ee193';
export default node;
