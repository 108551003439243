import { MutationUpdateReferralArgs } from "../graphql-types";
import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "../shared/lib/graphql/commitMutation";

export const updateReferral = async (variables: MutationUpdateReferralArgs) => {
  return commitMutation(
    {
      mutation: graphql`
        mutation referralsUpdateReferralMutation($referral_id: String!) {
          updateReferral(referral_id: $referral_id) {
            referral {
              id
              cjis_referral_sid
              court_case_no
              last_name
              first_name
              middle_name
              date_of_birth
              event_code
              event_datetime
              event_text
              referral_date
              processed_at
              processed_by_id
              booking_id
              created_at
            }
            result
          }
        }
      `,
      variables,
    },
    "Error while updating referral."
  );
};
