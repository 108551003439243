/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type MutationResult = "failure" | "skipped" | "success" | "%future added value";
export type SmsMessageActor = "promise" | "twilio" | "%future added value";
export type participantsUpdateParticipantCaseNumbersMutationVariables = {
    readonly id: string;
    readonly case_numbers?: ReadonlyArray<string | null> | null;
};
export type participantsUpdateParticipantCaseNumbersMutationResponse = {
    readonly updateParticipant: ({
        readonly participant: ({
            readonly id: string;
            readonly name: {
                readonly first: string;
                readonly last: string | null;
            };
            readonly case_manager: ({
                readonly id: string;
                readonly name: {
                    readonly first: string;
                    readonly last: string | null;
                };
            }) | null;
            readonly risk_level: string | null;
            readonly assessment: ({
                readonly score: string | null;
                readonly tool: string | null;
            }) | null;
            readonly language: string;
            readonly phone: ({
                readonly mobile: string | null;
            }) | null;
            readonly status: string | null;
            readonly compliance: string | null;
            readonly events: ReadonlyArray<({
                readonly id: string | null;
                readonly activity: {
                    readonly id: string;
                    readonly title: {
                        readonly en: string | null;
                    };
                };
                readonly date: string | null;
            }) | null> | null;
            readonly preferred_name: string | null;
            readonly sms_enabled: boolean | null;
            readonly sms_disabled_by: SmsMessageActor | null;
            readonly sms_consent: boolean | null;
            readonly sms_can_enable: boolean | null;
            readonly is_active: boolean | null;
            readonly supervision_begin_date: string | null;
            readonly supervision_end_date: string | null;
            readonly auth_token: string | null;
            readonly created_at: string;
            readonly case_manager_id: string | null;
            readonly home_phone_human_readable: string | null;
            readonly cell_phone_human_readable: string | null;
            readonly equipment_returned: ReadonlyArray<string | null> | null;
            readonly protective_order_notes: string | null;
            readonly probation_number: string | null;
            readonly case_numbers: ReadonlyArray<string | null> | null;
        }) | null;
        readonly result: MutationResult;
        readonly description: string | null;
        readonly errors: ReadonlyArray<string | null> | null;
    }) | null;
};
export type participantsUpdateParticipantCaseNumbersMutation = {
    readonly response: participantsUpdateParticipantCaseNumbersMutationResponse;
    readonly variables: participantsUpdateParticipantCaseNumbersMutationVariables;
};



/*
mutation participantsUpdateParticipantCaseNumbersMutation(
  $id: String!
  $case_numbers: [String]
) {
  updateParticipant(id: $id, case_numbers: $case_numbers) {
    participant {
      id
      name {
        first
        last
      }
      case_manager {
        id
        name {
          first
          last
        }
      }
      risk_level
      assessment {
        score
        tool
      }
      language
      phone {
        mobile
      }
      status
      compliance
      events {
        id
        activity {
          id
          title {
            en
          }
        }
        date
      }
      preferred_name
      sms_enabled
      sms_disabled_by
      sms_consent
      sms_can_enable
      is_active
      supervision_begin_date
      supervision_end_date
      auth_token
      created_at
      case_manager_id
      home_phone_human_readable
      cell_phone_human_readable
      equipment_returned
      protective_order_notes
      probation_number
      case_numbers
    }
    result
    description
    errors
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "case_numbers",
            "type": "[String]",
            "defaultValue": null
        } as any)
    ], v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v2 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "name",
        "storageKey": null,
        "args": null,
        "concreteType": "PersonName",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "first",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "last",
                "args": null,
                "storageKey": null
            }
        ]
    } as any), v3 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "updateParticipant",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "case_numbers",
                    "variableName": "case_numbers"
                },
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                }
            ],
            "concreteType": "NewParticipant",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participant",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Participant",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "case_manager",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CaseManager",
                            "plural": false,
                            "selections": [
                                (v1 /*: any*/),
                                (v2 /*: any*/)
                            ]
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "risk_level",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "assessment",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ParticipantAssessment",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "score",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "tool",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "language",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "phone",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PersonPhone",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "mobile",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "status",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "compliance",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "events",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Event",
                            "plural": true,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "activity",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "Activity",
                                    "plural": false,
                                    "selections": [
                                        (v1 /*: any*/),
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "title",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "i18nText",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "en",
                                                    "args": null,
                                                    "storageKey": null
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "date",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "preferred_name",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "sms_enabled",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "sms_disabled_by",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "sms_consent",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "sms_can_enable",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "is_active",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "supervision_begin_date",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "supervision_end_date",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "auth_token",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "created_at",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "case_manager_id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "home_phone_human_readable",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "cell_phone_human_readable",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "equipment_returned",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "protective_order_notes",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "probation_number",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "case_numbers",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "result",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "errors",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "participantsUpdateParticipantCaseNumbersMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v3 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "participantsUpdateParticipantCaseNumbersMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v3 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "participantsUpdateParticipantCaseNumbersMutation",
            "id": null,
            "text": "mutation participantsUpdateParticipantCaseNumbersMutation(\n  $id: String!\n  $case_numbers: [String]\n) {\n  updateParticipant(id: $id, case_numbers: $case_numbers) {\n    participant {\n      id\n      name {\n        first\n        last\n      }\n      case_manager {\n        id\n        name {\n          first\n          last\n        }\n      }\n      risk_level\n      assessment {\n        score\n        tool\n      }\n      language\n      phone {\n        mobile\n      }\n      status\n      compliance\n      events {\n        id\n        activity {\n          id\n          title {\n            en\n          }\n        }\n        date\n      }\n      preferred_name\n      sms_enabled\n      sms_disabled_by\n      sms_consent\n      sms_can_enable\n      is_active\n      supervision_begin_date\n      supervision_end_date\n      auth_token\n      created_at\n      case_manager_id\n      home_phone_human_readable\n      cell_phone_human_readable\n      equipment_returned\n      protective_order_notes\n      probation_number\n      case_numbers\n    }\n    result\n    description\n    errors\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'b92b5327a6478ba596731215cfada09b';
export default node;
