import React from "react";
import { KeyedString } from "../../../shared/src/lib/graphql/flowTypes";
import {
  CarePlanParticipantType,
  CarePlanServiceProvider,
  CarePlanObligationType,
} from "../components/care-plan/CarePlanQuery";
import { ActivityMeta } from "../shared/lib/graphql/index";
import { UpdateObligationGqlArguments } from "../actions/obligations";
import { BroadcastMessageRecipientDisplay } from "../components/broadcast/BroadcastMessagesGql";

export interface CarePlanContextType {
  activityInformation: Array<ActivityMeta>;
  compliance: KeyedString[];
  obligation_status: KeyedString[];
  participant: CarePlanParticipantType;
  serviceProviders: CarePlanServiceProvider[];
  dispositionOptions: KeyedString[];
  updateObligationMutation: (
    variables: UpdateObligationGqlArguments,
    obligation: CarePlanObligationType
  ) => void;
}

export const CarePlanContext = React.createContext<CarePlanContextType>(
  (undefined as any) as CarePlanContextType
);

export interface ParticipantContextType {
  caseManagersSerialized: KeyedString[];
  riskLevelsSerialized: KeyedString[];
  assessmentToolsSerialized: KeyedString[];
  languagesSerialized: KeyedString[];
  assessmentToolDictionary: { [key: string]: any };
  complianceOptionsSerialized: KeyedString[];
}

export interface ChangeParticipantIsActiveContextType {
  deactivateReasonsSerialized: KeyedString[];
  reactivateReasonsSerialized: KeyedString[];
  isActive: boolean;
  populateEndDateOnDeactivation?: boolean;
}

export const CreateParticipantContext = React.createContext<
  ParticipantContextType
>((undefined as any) as ParticipantContextType);

export interface BroadcastMessageContextType {
  recipients: Array<BroadcastMessageRecipientDisplay>;
}

export const BroadcastMessageContext = React.createContext<
  BroadcastMessageContextType
>((undefined as any) as BroadcastMessageContextType);

export const ChangeParticipantIsActiveContext = React.createContext<
  ChangeParticipantIsActiveContextType
>((undefined as any) as ChangeParticipantIsActiveContextType);
