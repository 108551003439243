/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type MutationResult = "failure" | "skipped" | "success" | "%future added value";
export type referralsUpdateReferralMutationVariables = {
    readonly referral_id: string;
};
export type referralsUpdateReferralMutationResponse = {
    readonly updateReferral: ({
        readonly referral: ({
            readonly id: string;
            readonly cjis_referral_sid: string;
            readonly court_case_no: string;
            readonly last_name: string;
            readonly first_name: string;
            readonly middle_name: string | null;
            readonly date_of_birth: string;
            readonly event_code: string;
            readonly event_datetime: string;
            readonly event_text: string;
            readonly referral_date: string;
            readonly processed_at: string | null;
            readonly processed_by_id: string | null;
            readonly booking_id: string | null;
            readonly created_at: string;
        }) | null;
        readonly result: MutationResult;
    }) | null;
};
export type referralsUpdateReferralMutation = {
    readonly response: referralsUpdateReferralMutationResponse;
    readonly variables: referralsUpdateReferralMutationVariables;
};



/*
mutation referralsUpdateReferralMutation(
  $referral_id: String!
) {
  updateReferral(referral_id: $referral_id) {
    referral {
      id
      cjis_referral_sid
      court_case_no
      last_name
      first_name
      middle_name
      date_of_birth
      event_code
      event_datetime
      event_text
      referral_date
      processed_at
      processed_by_id
      booking_id
      created_at
    }
    result
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "referral_id",
            "type": "String!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "updateReferral",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "referral_id",
                    "variableName": "referral_id"
                }
            ],
            "concreteType": "NewReferralResult",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "referral",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Referral",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "cjis_referral_sid",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "court_case_no",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "last_name",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "first_name",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "middle_name",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "date_of_birth",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "event_code",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "event_datetime",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "event_text",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "referral_date",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "processed_at",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "processed_by_id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "booking_id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "created_at",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "result",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "referralsUpdateReferralMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "referralsUpdateReferralMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "referralsUpdateReferralMutation",
            "id": null,
            "text": "mutation referralsUpdateReferralMutation(\n  $referral_id: String!\n) {\n  updateReferral(referral_id: $referral_id) {\n    referral {\n      id\n      cjis_referral_sid\n      court_case_no\n      last_name\n      first_name\n      middle_name\n      date_of_birth\n      event_code\n      event_datetime\n      event_text\n      referral_date\n      processed_at\n      processed_by_id\n      booking_id\n      created_at\n    }\n    result\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '196a96c6e3e5e90d31d94894189b4b07';
export default node;
