import React, { useState } from "react";
import { Button } from "antd";
import { NotesModal } from "./NotesModal";
import { UserConsumer } from "../../shared/lib/contexts/UserContext";

export interface NotesModalProps {
  participantId: string;
}

export function NotesModalContainer({ participantId }: NotesModalProps) {
  const [showModal, setShowModal] = useState<boolean>(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <UserConsumer>
      {(userContext) => (
        <>
          <NotesModal
            toggleModal={toggleModal}
            showModal={showModal}
            participantId={participantId}
            caseManagerId={userContext.user ? userContext.user.uid : ""}
          />
          <Button type="primary" onClick={toggleModal}>
            Add
          </Button>
        </>
      )}
    </UserConsumer>
  );
}
