import React, { useState } from "react";
import { Modal } from "react-bulma-components";
import { Button, Input } from "antd";
import { addParticipantNoteSimple } from "../../actions/participant-notes";

const { TextArea } = Input;

export function NotesModal(props: {
  toggleModal: any;
  showModal: boolean;
  participantId: string;
  caseManagerId: string;
}) {
  const { showModal, toggleModal, participantId, caseManagerId } = props;
  const [caseNote, setCaseNote] = useState<string>("");

  const handleAddParticipantNoteClicked = () => {
    addParticipantNoteSimple({
      case_manager_id: caseManagerId,
      participant_id: participantId,
      label: caseNote,
    });

    toggleModal();
    setCaseNote("");
  };

  const cancelModal = () => {
    toggleModal();
    setCaseNote("");
  };

  return (
    <>
      <Modal show={showModal} showClose={false} onClose={cancelModal}>
        <Modal.Card>
          <Modal.Card.Head showClose={true} onClose={cancelModal}>
            <Modal.Card.Title>Add Case Note</Modal.Card.Title>
          </Modal.Card.Head>
          <Modal.Card.Body>
            <TextArea
              value={caseNote}
              onChange={({ target }) => {
                setCaseNote(target.value);
              }}
            />
          </Modal.Card.Body>
          <Modal.Card.Foot>
            <div className="modal-footer">
              <Button
                type="primary"
                disabled={!caseNote}
                onClick={handleAddParticipantNoteClicked}
              >
                Add Note
              </Button>
              <Button onClick={cancelModal} style={{ marginLeft: "20px" }}>
                Cancel
              </Button>
            </div>
          </Modal.Card.Foot>
        </Modal.Card>
      </Modal>
    </>
  );
}
