import React from "react";
import idx from "idx.macro";

import { updateParticipantActiveStatus } from "../../../actions/participants";
import { CarePlanParticipantType } from "../../care-plan/CarePlanQuery";
import ChangeParticipantActiveStatusForm from "./ChangeParticipantActiveStatusForm";
import { ModalPropsPassThrough } from "../ModalWrapper";
import { ChangeParticipantActiveStatusGqlArguments } from "../../../actions/participants";
import { ParticipantDetailsType } from "../../screens/ParticipantDetailsQueryTypes";
import { ChangeParticipantIsActiveContextType } from "../../../utils/context";
import ChangeParticipantActiveStatusModalWrapper, {
  ChangeParticipantActiveStatusFormData,
} from "./ChangeParticipantActiveStatusModalWrapper";

export interface ChangeParticipantActivePropsExternal {
  callback?: (participant: CarePlanParticipantType) => void;
  participant?: ParticipantDetailsType;
  metaDataObject?: ChangeParticipantIsActiveContextType;
  buttonComponent?: React.ReactElement<any>;
  populateEndDateOnDeactivation?: boolean;
}

export type ChangeParticipantActiveStatusModalFormProps = ModalPropsPassThrough<
  ChangeParticipantActiveStatusFormData
> &
  ChangeParticipantActivePropsExternal;

type ChangeParticipantIsActiveStatus = {
  changeParticipantIsActiveStatus: {
    participant: CarePlanParticipantType;
  };
};

const addParticipantArgumentDictionary: { [key: string]: string } = {
  reason: "reason",
  note: "note",
  startDate: "supervision_begin_date",
  endDate: "supervision_end_date",
  recidivismReason: "recidivism_reason",
  recidivismDate: "recidivism_date",
};

export function getGraphQLVariablesFromFormData(
  formData: ChangeParticipantActiveStatusFormData
) {
  return Object.keys(formData).reduce((acc, key) => {
    const newItemValue =
      formData[key].value === "Incarcerated"
        ? formData[key].value.toLowerCase()
        : formData[key].value;
    const newItem = {
      [addParticipantArgumentDictionary[key]]: newItemValue,
    };
    acc = {
      ...acc,
      ...newItem,
    };

    return acc;
  }, {});
}

class AddParticipantModal extends React.Component<
  ChangeParticipantActiveStatusModalFormProps
> {
  private changeParticipantStatusCallback = async (
    formData: ChangeParticipantActiveStatusFormData
  ) => {
    const { participant, populateEndDateOnDeactivation = false } = this.props;

    if (!participant) {
      return;
    }

    const { id, is_active } = participant as ParticipantDetailsType;

    const data = getGraphQLVariablesFromFormData(formData);

    //sorry TypeScript, simpler to just force cast it!
    const variables = {
      ...data,
      id,
      is_active: !is_active,
      populate_end_date: !!populateEndDateOnDeactivation,
    } as ChangeParticipantActiveStatusGqlArguments;

    //TODO: Implement more robust system for handling error/loading states within mutations called from React components
    const updatedParticipants = (await updateParticipantActiveStatus(
      variables
    )) as ChangeParticipantIsActiveStatus;
    const updatedParticipant = idx(
      updatedParticipants,
      (_) => _.changeParticipantIsActiveStatus.participant
    );
    const updatedParticipantId = (updatedParticipant || {}).id;

    if (!updatedParticipant || !updatedParticipantId) {
      console.error(
        "Callback after updating a participant, but no updatedParticipantId found. \n Sent:",
        { ...variables, mobile: "redacted" }
      );
    }
    const { callback } = this.props;
    if (callback) {
      callback(updatedParticipant);
    }
  };

  render() {
    const {
      operation,
      type,
      participant,
      metaDataObject,
      buttonComponent,
    } = this.props;
    return (
      <ChangeParticipantActiveStatusModalWrapper
        callBackForAPI={this.changeParticipantStatusCallback}
        type={type}
        operation={operation}
        updateObject={participant}
        metaDataObject={metaDataObject}
        buttonComponent={buttonComponent}
      >
        {(data: any) => <ChangeParticipantActiveStatusForm {...data} />}
      </ChangeParticipantActiveStatusModalWrapper>
    );
  }
}

export default AddParticipantModal;
