import React from "react";
import { Form, Button, Typography, Row, Col, Input } from "antd";
import {
  Employment,
  RiskFactor,
  ResearchFactor,
  InterviewAndAssessment,
  RiskAssessmentResult,
  Maybe,
} from "../../graphql-types";
import { FormComponentProps } from "antd/es/form";
import {
  SelectFormItem,
  formItemLayout,
  zeroToThreeOrMoreOptions,
  FIELD_IS_REQUIRED_MESSAGE,
  yesNoOptions,
} from "../prospects/shared";
import {
  SelectOption,
  ResearchFactorFormData,
  RiskFactorFormData,
} from "../prospects/types";
import { scoreParticipantAssessmentResult } from "../../actions/participantAssessmentResult";

const { Text } = Typography;

interface AssessmentFormProps extends FormComponentProps {
  interviewAndAssessment: InterviewAndAssessment;
  participantAssessmentResultId: string;
  risk_assessment_result: Maybe<RiskAssessmentResult>;
}

const employmentOptions: SelectOption[] = [
  { id: Employment.Employed, title: "Employed" },
  { id: Employment.FullTimeStudent, title: "Full-time Student" },
  { id: Employment.PrimaryCaregiver, title: "Primary Caregiver" },
  { id: Employment.Retired, title: "Retired" },
  { id: Employment.None, title: "None" },
];

const researchFactors: ResearchFactorFormData[] = [
  {
    id: "has_prior_misdemeanor_conviction",
    label: "Prior Misdemeanor Conviction",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    optionsArray: yesNoOptions,
    required: true,
  },
  {
    id: "has_prior_felony_conviction",
    label: "Prior Felony Conviction",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    optionsArray: yesNoOptions,
    required: true,
  },
  {
    id: "prior_violent_convictions_count",
    label: "Prior Violent Conviction",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    optionsArray: zeroToThreeOrMoreOptions,
    required: true,
  },
  {
    id: "prior_FTAs_past_two_years_count",
    label: "Prior FTAs in past 2 years",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    optionsArray: zeroToThreeOrMoreOptions,
    required: true,
  },
  {
    id: "has_prior_FTAs_older_than_two_years",
    label: "Prior FTAs Older Than 2 years",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    optionsArray: yesNoOptions,
    required: true,
  },
  {
    id: "has_prior_sentence_to_incarceration",
    label: "Prior Sentence to Incarceration",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    optionsArray: yesNoOptions,
    required: true,
  },
];

const riskFactors: RiskFactorFormData[] = [
  {
    id: "has_active_community_supervision",
    label: "Active Community Supervision",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    optionsArray: yesNoOptions,
    required: true,
  },
  {
    id: "is_charge_felony_drug_theft_or_fraud",
    label: "Charge is Felony Drug, Theft or Fraud",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    optionsArray: yesNoOptions,
    required: true,
  },
  {
    id: "has_pending_charges",
    label: "Pending Charges",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    optionsArray: yesNoOptions,
    required: true,
  },
  {
    id: "has_criminal_history",
    label: "Criminal History",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    optionsArray: yesNoOptions,
    required: true,
  },
  {
    id: "has_two_or_more_FTAs",
    label: "Two or More FTAs",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    optionsArray: yesNoOptions,
    required: true,
  },
  {
    id: "has_two_or_more_violent_convictions",
    label: "Two or More Violent Convictions",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    optionsArray: yesNoOptions,
    required: true,
  },
  {
    id: "has_history_of_drug_abuse",
    label: "History of Drug Abuse",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    optionsArray: yesNoOptions,
    required: true,
  },
  {
    id: "employed_at_time_of_arrest",
    label: "Employed at Time of Arrest",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    optionsArray: employmentOptions,
    required: true,
  },
];

function serializeFormValuesForMutation(values: { [key: string]: string }) {
  return {
    researchFactors: {
      has_prior_misdemeanor_conviction: values.has_prior_misdemeanor_conviction,
      has_prior_felony_conviction: values.has_prior_felony_conviction,
      prior_violent_convictions_count: values.prior_violent_convictions_count,
      prior_FTAs_past_two_years_count: values.prior_FTAs_past_two_years_count,
      has_prior_FTAs_older_than_two_years:
        values.has_prior_FTAs_older_than_two_years,
      has_prior_sentence_to_incarceration:
        values.has_prior_sentence_to_incarceration,
    },
    riskFactors: {
      has_active_community_supervision: values.has_active_community_supervision,
      is_charge_felony_drug_theft_or_fraud:
        values.is_charge_felony_drug_theft_or_fraud,
      has_pending_charges: values.has_pending_charges,
      has_criminal_history: values.has_criminal_history,
      has_two_or_more_FTAs: values.has_two_or_more_FTAs,
      has_two_or_more_violent_convictions:
        values.has_two_or_more_violent_convictions,
      has_history_of_drug_abuse: values.has_history_of_drug_abuse,
      employed_at_time_of_arrest: values.employed_at_time_of_arrest,
    },
  };
}

class AssessmentForm extends React.Component<AssessmentFormProps> {
  handleSubmit = (e: any) => {
    e.preventDefault();
    const { participantAssessmentResultId } = this.props;
    this.props.form.validateFieldsAndScroll(async (err: any, values: any) => {
      if (!err) {
        const { riskFactors, researchFactors } = serializeFormValuesForMutation(
          values
        );
        await scoreParticipantAssessmentResult({
          participant_assessment_result_id: participantAssessmentResultId,
          risk_factors: riskFactors as RiskFactor,
          research_factors: researchFactors as ResearchFactor,
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const {
      interviewAndAssessment,
      risk_assessment_result: riskAssessmentResult,
    } = this.props;

    const riskAssessmentScore =
      riskAssessmentResult && riskAssessmentResult.score
        ? riskAssessmentResult.score
        : 0;

    const riskAssessmentLevel =
      riskAssessmentResult && riskAssessmentResult.level
        ? riskAssessmentResult.level
        : "N/A";

    // would be nice to abstract the map function here,
    // but a little tricky to do with TypeScript
    const riskFactorsWithInitialValue = riskFactors.map(
      (riskFactorFormData) => {
        const { id } = riskFactorFormData;
        return {
          ...riskFactorFormData,
          initialValue:
            interviewAndAssessment && interviewAndAssessment.risk_factors
              ? interviewAndAssessment.risk_factors[id]
              : null,
        };
      }
    );

    const researchFactorWithInitialValue = researchFactors.map(
      (researchFactorFormData) => {
        const { id } = researchFactorFormData;

        return {
          ...researchFactorFormData,
          initialValue:
            interviewAndAssessment && interviewAndAssessment.research_factors
              ? interviewAndAssessment.research_factors[id]
              : null,
        };
      }
    );

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={8}>
          <Col span={12}>
            <Text>Research Factors</Text>
            {researchFactorWithInitialValue.map(
              (researchFactorFormData, index) => (
                <SelectFormItem
                  key={`research_factor_${index}`}
                  {...researchFactorFormData}
                  getFieldDecorator={getFieldDecorator}
                />
              )
            )}
          </Col>
          <Col span={12}>
            <Text>Risk Factors</Text>
            {riskFactorsWithInitialValue.map((riskFactorFormData, index) => (
              <SelectFormItem
                key={`risk_factor_${index}`}
                {...riskFactorFormData}
                getFieldDecorator={getFieldDecorator}
              />
            ))}
            <Form.Item {...formItemLayout} label={"Risk Score"}>
              <Input value={riskAssessmentScore} disabled={true} />
            </Form.Item>
            <Form.Item {...formItemLayout} label={"Risk Level"}>
              <Input value={riskAssessmentLevel} disabled={true} />
            </Form.Item>
          </Col>
        </Row>

        <Row type="flex" justify="center" gutter={24}>
          <Form.Item>
            <Button type="primary" size={"large"} htmlType="submit">
              Save Assessment
            </Button>
          </Form.Item>
        </Row>
      </Form>
    );
  }
}

export const WrappedAssessmentForm = Form.create<AssessmentFormProps>({
  name: "Assessment Form",
})(AssessmentForm);
