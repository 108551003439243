import { InputFormDataWithInputType, InputType } from "./types";
import {
  FIELD_IS_REQUIRED_MESSAGE,
  DATE_PICKER_PLACEHOLDER,
  yesNoOptions,
  VALID_PHONE_NUMBER_MESSAGE,
  NUMBER_REQUIRED_MESSAGE,
  pretrialDecisionOptions,
  pretrialSentenceOptions,
} from "./shared";
import {
  ResidenceInfo,
  EmploymentAndEducationInfo,
  MilitaryServiceInfo,
  SubstanceAbuseInfo,
  PriorRecordInfo,
  VictimInfo,
  FirearmInfo,
} from "../../graphql-types";

interface BookingInformationFormFieldsWithInputType
  extends InputFormDataWithInputType {
  id:
    | "booking_date"
    | "arrest_agency"
    | "arrested_for"
    | "court_date"
    | "court_time"
    | "court_room";
}

interface BoookingCourtDeciionFormFieldsWithInputType
  extends InputFormDataWithInputType {
  id: "decision";
}

interface ReleasedWithConditionFormFieldsWithInputType
  extends InputFormDataWithInputType {
  id: "has_gps_monitoring" | "has_alcohol_monitoring" | "has_basic_monitoring";
}

interface SentencedFormFieldsWithInputType extends InputFormDataWithInputType {
  id: "sentencing_decision";
}

interface PetitionREsolvedFormFieldsWithInputType
  extends InputFormDataWithInputType {
  id: "jail_sanction";
}

interface ResidenceInputFormDataWithInputType
  extends InputFormDataWithInputType {
  id: keyof ResidenceInfo;
}

interface EmploymentInputFormDataWithInputType
  extends InputFormDataWithInputType {
  id: keyof EmploymentAndEducationInfo;
}

interface MilitaryServiceFieldsWithInputType
  extends InputFormDataWithInputType {
  id: keyof MilitaryServiceInfo;
}

interface SubstanceAbuseFieldsWithInputType extends InputFormDataWithInputType {
  id: keyof SubstanceAbuseInfo;
}

interface PriorRecordFieldsWithInputType extends InputFormDataWithInputType {
  id: keyof PriorRecordInfo;
}

interface VictimFieldsWithInputType extends InputFormDataWithInputType {
  id: keyof VictimInfo;
}

interface FirearmFieldsWithInputType extends InputFormDataWithInputType {
  id: keyof FirearmInfo;
}

export const courtDecisionFormFields: BoookingCourtDeciionFormFieldsWithInputType[] = [
  {
    id: "decision",
    label: "Court Decision",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: "",
    inputType: InputType.Select,
    optionsArray: pretrialDecisionOptions,
    required: true,
  },
];

export const sentencedFormFields: SentencedFormFieldsWithInputType[] = [
  {
    id: "sentencing_decision",
    label: "Sentencing Decision",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: "",
    inputType: InputType.Select,
    optionsArray: pretrialSentenceOptions,
    required: true,
  },
];

export const petitionResolvedFormFields: PetitionREsolvedFormFieldsWithInputType[] = [
  {
    id: "jail_sanction",
    label: "Jail Sanction",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: "",
    inputType: InputType.Select,
    optionsArray: yesNoOptions,
    required: true,
  },
];

export const releasedWithConditionFormFields: ReleasedWithConditionFormFieldsWithInputType[] = [
  {
    id: "has_gps_monitoring",
    label: "GPS Monitoring",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: "",
    inputType: InputType.Select,
    optionsArray: yesNoOptions,
    required: true,
  },
  {
    id: "has_alcohol_monitoring",
    label: "Alcohol Monitoring",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: "",
    inputType: InputType.Select,
    optionsArray: yesNoOptions,
    required: true,
  },
  {
    id: "has_basic_monitoring",
    label: "Basic Monitoring",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: "",
    inputType: InputType.Select,
    optionsArray: yesNoOptions,
    required: true,
  },
];

export const bookingInformationFormFields: BookingInformationFormFieldsWithInputType[] = [
  {
    id: "booking_date",
    label: "Booking date",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: "",
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Date,
    required: true,
  },
  {
    id: "arrest_agency",
    label: "Arrest agency",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Input,
    required: true,
  },
  {
    id: "arrested_for",
    label: "Arrested for",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Input,
    required: true,
  },
  {
    id: "court_date",
    label: "Court Date",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Date,
    required: true,
  },
  {
    id: "court_time",
    label: "Court Time",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: "",
    inputType: InputType.Time,
    required: true,
  },
  {
    id: "court_room",
    label: "Court Room",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: "e.g. 77",
    inputType: InputType.Input,
    required: true,
  },
];

export const residenceFormFields: ResidenceInputFormDataWithInputType[] = [
  {
    id: "address",
    label: "Current Address (Street, City, State, Zip)",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: "",
    inputType: InputType.Input,
    required: false,
  },
  {
    id: "moved_into_current_address_date",
    label: "Estimated date moved into current address",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Date,
    required: false,
  },
  {
    id: "phone_number",
    label: "Telephone Number",
    errorMessage: VALID_PHONE_NUMBER_MESSAGE,
    initialValue: "",
    inputType: InputType.Input,
    required: false,
    validationPattern: /^((\+?\d{1,2}[\s\-]?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}|\d{10})$/,
  },
  {
    id: "moved_to_bay_area_date",
    label: "Estimated date moved into Bay Area",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Date,
    required: false,
  },
];

export const employmentFormFields: EmploymentInputFormDataWithInputType[] = [
  {
    id: "is_employed",
    label: "Is currently employed",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: "",
    inputType: InputType.Select,
    required: false,
    optionsArray: yesNoOptions,
  },
  {
    id: "current_employer_name",
    label: "Current Employer Name",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: "",
    inputType: InputType.Input,
    required: false,
  },
  {
    id: "is_student_retired_disabled",
    label: "Student/Retired/Disabled",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: "",
    inputType: InputType.Select,
    optionsArray: yesNoOptions,
    required: false,
  },
  {
    id: "is_primary_caretaker",
    label: "Primary Caretaker",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: "",
    inputType: InputType.Select,
    optionsArray: yesNoOptions,
    required: false,
  },
  {
    id: "employment_start_date",
    label: "Date started position",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Date,
    required: false,
  },
  {
    id: "employment_type",
    label: "Employment Type",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: "",
    inputType: InputType.Input,
    required: false,
  },
  {
    id: "hours_worked_per_week",
    label: "Hours worked per week",
    errorMessage: NUMBER_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Input,
    validationPattern: /^\d*$/,
    required: false,
  },
  {
    id: "type_of_work",
    label: "Type of work",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Input,
    required: false,
  },
  {
    id: "previous_employer_name",
    label: "Previous Employer Name",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Input,
    required: false,
  },
  {
    id: "previous_employer_start_date",
    label: "Previous employer start date",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Date,
    required: false,
  },
  {
    id: "previous_employer_end_date",
    label: "Previous employer end date",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Date,
    required: false,
  },
  {
    id: "unemployed_start_date",
    label: "Unemployed start date",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Date,
    required: false,
  },
  {
    id: "unemployed_how_supported",
    label: "Unemployed how supported",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Input,
    required: false,
  },
];

export const militaryServiceFields: MilitaryServiceFieldsWithInputType[] = [
  {
    id: "has_served_in_military",
    label: "Have you ever had prior service in the U.S Armed Forces",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    inputType: InputType.Select,
    required: false,
    optionsArray: yesNoOptions,
  },
  {
    id: "military_service_comments",
    label: "Comments on military service",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    inputType: InputType.TextArea,
    required: false,
  },
];

export const substanceAbuseHistoryFields: SubstanceAbuseFieldsWithInputType[] = [
  {
    id: "has_experimented_with_illegal_substances",
    label: "Has experimented with illegal substances",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: "",
    inputType: InputType.Select,
    optionsArray: yesNoOptions,
    required: false,
  },
  {
    id: "type_of_illegal_substance_experimented",
    label: "Type of illegal subtance experimented",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Input,
    required: false,
  },
  {
    id: "last_use_of_illegal_substance_date",
    label: "Last use illegal substance date",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: "",
    inputType: InputType.Date,
    required: false,
  },
  {
    id: "has_abused_alcohol_or_marijuana",
    label: "Has abused alcohol or marijuana",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Select,
    optionsArray: yesNoOptions,
    required: false,
  },
  {
    id: "last_alcohol_or_marijuna_abuse_date",
    label: "Last alcohol / marijuana use date",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Date,
    required: false,
  },
  {
    id: "alcohol_or_marijuana_abuse_how_often",
    label: "Alcohol or marijuana how often",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Input,
    required: false,
  },
  {
    id: "has_used_illegal_drugs_last_six_months",
    label: "Has used illegal drugs last six months",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Select,
    optionsArray: yesNoOptions,
    required: false,
  },
  {
    id: "type_of_illegal_drug_used_last_six_months",
    label: "Illegal drug use type",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Input,
    required: false,
  },
  {
    id: "how_often_illegal_drug_use_last_six_months",
    label: "Illegal drug use how often",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Input,
    required: false,
  },
  {
    id: "has_received_treatment_alcohol_or_marijuana",
    label: "Has received alcohol or marijuana treatment",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Select,
    optionsArray: yesNoOptions,
    required: false,
  },
  {
    id: "alcohol_or_marijuana_program_name",
    label: "Alcohol or marijuana program name",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Input,
    required: false,
  },
  {
    id: "alcohol_or_marijuana_treatment_date",
    label: "Alcohol or marijuana treatment date",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Date,
    required: false,
  },
];

export const priorRecordFields: PriorRecordFieldsWithInputType[] = [
  {
    id: "bench_warrants_count",
    label: "Number of bench warrants",
    errorMessage: NUMBER_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: "E.g. 1",
    inputType: InputType.Input,
    validationPattern: /^\d*$/,
    required: false,
  },
  {
    id: "currently_on_probation",
    label: "Client is currently on probation",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    inputType: InputType.Select,
    optionsArray: yesNoOptions,
    required: false,
  },
  {
    id: "has_pending_case",
    label: "Client has pending case",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    inputType: InputType.Select,
    optionsArray: yesNoOptions,
    required: false,
  },
  {
    id: "prior_record_comments",
    label: "Comments on prior record",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    inputType: InputType.TextArea,
    optionsArray: yesNoOptions,
    required: false,
  },
];

export const victimInfoFields: VictimFieldsWithInputType[] = [
  {
    id: "was_victim_in_case",
    label: "Was there a victim in this case?",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Select,
    optionsArray: yesNoOptions,
    required: false,
  },
  {
    id: "has_protective_order",
    label: "Is there  a protective order issued?",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Select,
    optionsArray: yesNoOptions,
    required: false,
  },
  {
    id: "has_stay_away_order",
    label: "Has stay away order",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Select,
    optionsArray: yesNoOptions,
    required: false,
  },
  {
    id: "has_peaceful_contact_order",
    label: "Has peacful contact order",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Select,
    optionsArray: yesNoOptions,
    required: false,
  },
];

export const firearmFormFields: FirearmFieldsWithInputType[] = [
  {
    id: "has_firearm_registered",
    label: "Do you have any firearms registered to you?",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Select,
    optionsArray: yesNoOptions,
    required: false,
  },
  {
    id: "registered_firearm_model",
    label: "Model of firearm?",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Input,
    required: false,
  },
  {
    id: "registered_firearm_location",
    label: "Location",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Input,
    required: false,
  },
  {
    id: "has_firearm_in_home",
    label: "Are there any firearms in your home?",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Select,
    optionsArray: yesNoOptions,
    required: false,
  },
  {
    id: "home_firearm_model",
    label: "Model of Firearm",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Input,
    required: false,
  },
  {
    id: "home_firearm_location",
    label: "Location",
    errorMessage: FIELD_IS_REQUIRED_MESSAGE,
    initialValue: null,
    placeholder: DATE_PICKER_PLACEHOLDER,
    inputType: InputType.Input,
    required: false,
  },
];
