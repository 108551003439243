import React from "react";
import { Form, Row, Button } from "antd";
import { FormComponentProps } from "antd/es/form";
import { InterviewFormItem, InterviewFormSection } from "./shared";
import "./prospects.scss";
import {
  ParticipantContactFormEntry,
  InputFormDataWithInputType,
} from "./types";
import { ParticipantContactsForm } from "./ParticipantContactsForm";
import {
  InterviewDataInput,
  ParticipantContactInput,
  ParticipantContact,
  Maybe,
} from "../../graphql-types";
import { updateBookingInterviewData } from "../../actions/bookings";
import moment from "moment";
import { throttle } from "lodash";

interface AssessmentFormProps extends FormComponentProps {
  residenceFormFields: InputFormDataWithInputType[];
  employmentFormFields: InputFormDataWithInputType[];
  militaryServiceFields: InputFormDataWithInputType[];
  substanceAbuseHistoryFields: InputFormDataWithInputType[];
  priorRecordFields: InputFormDataWithInputType[];
  victimInfoFields: InputFormDataWithInputType[];
  firearmFormFields: InputFormDataWithInputType[];
  bookingId: string;
  contacts: Maybe<ParticipantContact[]>;
  victims: Maybe<ParticipantContact[]>;
}

interface State {
  contacts: ParticipantContactFormEntry[];
  victims: ParticipantContactFormEntry[];
}

type VictimsOrContacts = "victims" | "contacts";

function convertFormFieldDataToInterviewFormDataInput(
  formData: { [key: string]: any },
  contacts: ParticipantContactFormEntry[],
  victims: ParticipantContactFormEntry[]
): InterviewDataInput {
  const participantContactMapFunction = (
    contact: ParticipantContactFormEntry
  ): ParticipantContactInput => {
    return {
      name: {
        first: contact.first_name,
        last: contact.last_name,
      },
      date_of_birth: contact.date_of_birth
        ? contact.date_of_birth.toISOString()
        : null,
      address: contact.address,
      relationship: contact.relationship,
      how_often_seen: contact.how_often_seen,
      phone: contact.phone,
    };
  };
  return {
    residence: {
      address: formData.address,
      moved_into_current_address_date: formData.moved_into_current_address_date,
      phone_number: formData.phone_number,
      moved_to_bay_area_date: formData.moved_to_bay_area_date,
    },
    contacts: contacts.map(participantContactMapFunction),
    employment_and_education: {
      is_employed: formData.is_employed,
      current_employer_name: formData.current_employer_name,
      is_student_retired_disabled: formData.is_student_retired_disabled,
      is_primary_caretaker: formData.is_primary_caretaker,
      employment_start_date: formData.employment_start_date,
      employment_type: formData.employment_type,
      hours_worked_per_week: formData.hours_worked_per_week
        ? parseInt(formData.hours_worked_per_week, 10)
        : null,
      type_of_work: formData.type_of_work,
      previous_employer_name: formData.previous_employer_name,
      previous_employer_start_date: formData.previous_employer_start_date,
      previous_employer_end_date: formData.previous_employer_end_date,
      unemployed_start_date: formData.unemployed_start_date,
      unemployed_how_supported: formData.unemployed_how_supported,
    },
    military_service: {
      has_served_in_military: formData.has_served_in_military,
      military_service_comments: formData.military_service_comments,
    },
    prior_record: {
      bench_warrants_count: formData.bench_warrants_count
        ? parseInt(formData.bench_warrants_count, 10)
        : null,
      currently_on_probation: formData.currently_on_probation,
      has_pending_case: formData.has_pending_case,
      prior_record_comments: formData.prior_record_comments,
    },
    substance_abuse: {
      has_experimented_with_illegal_substances:
        formData.has_experimented_with_illegal_substances,
      type_of_illegal_substance_experimented:
        formData.type_of_illegal_substance_experimented,
      last_use_of_illegal_substance_date:
        formData.last_use_of_illegal_substance_date,
      has_abused_alcohol_or_marijuana: formData.has_abused_alcohol_or_marijuana,
      last_alcohol_or_marijuna_abuse_date:
        formData.last_alcohol_or_marijuna_abuse_date,
      alcohol_or_marijuana_abuse_how_often:
        formData.alcohol_or_marijuana_abuse_how_often,
      has_used_illegal_drugs_last_six_months:
        formData.has_used_illegal_drugs_last_six_months,
      type_of_illegal_drug_used_last_six_months:
        formData.type_of_illegal_drug_used_last_six_months,
      how_often_illegal_drug_use_last_six_months:
        formData.how_often_illegal_drug_use_last_six_months,
      has_received_treatment_alcohol_or_marijuana:
        formData.has_received_treatment_alcohol_or_marijuana,
      alcohol_or_marijuana_program_name:
        formData.alcohol_or_marijuana_program_name,
      alcohol_or_marijuana_treatment_date:
        formData.alcohol_or_marijuana_treatment_date,
    },
    victim_info: {
      was_victim_in_case: formData.was_victim_in_case,
      has_protective_order: formData.has_protective_order,
      has_stay_away_order: formData.has_stay_away_order,
      has_peaceful_contact_order: formData.has_peaceful_contact_order,
      protective_order_notes: formData.proptective_order_notes,
      victims: victims.map(participantContactMapFunction),
    },
    firearm_info: {
      has_firearm_registered: formData.has_firearm_registered,
      registered_firearm_model: formData.registered_firearm_model,
      registered_firearm_location: formData.registered_firearm_location,
      has_firearm_in_home: formData.has_firearm_in_home,
      home_firearm_model: formData.home_firearm_model,
      home_firearm_location: formData.home_firearm_location,
    },
  };
}

const participantContactToFormMapFunction = (contact: ParticipantContact) => {
  return {
    first_name: contact.name && contact.name.first ? contact.name.first : "",
    last_name: contact.name && contact.name.last ? contact.name.last : "",
    address: contact.address || "",
    relationship: contact.relationship || "",
    phone: contact.phone || "",
    date_of_birth: contact.date_of_birth
      ? moment(contact.date_of_birth)
      : undefined,
    how_often_seen: contact.how_often_seen || "",
  };
};

class InterviewForm extends React.Component<AssessmentFormProps, State> {
  constructor(props: AssessmentFormProps) {
    super(props);

    this.state = {
      contacts: this.props.contacts
        ? this.props.contacts.map(participantContactToFormMapFunction)
        : [],
      victims: this.props.victims
        ? this.props.victims.map(participantContactToFormMapFunction)
        : [],
    };

    // we save the form on blur,
    // but we should throttle to prevent API from getting hammered
    this.handleBlur = throttle(this.handleBlur, 5000);
  }

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      async (err: any, values: { [key: string]: any }) => {
        if (!err) {
          await this.saveFormData(values);
        }
      }
    );
  };

  handleBlur = async () => {
    this.props.form.validateFields(
      async (err: any, values: { [key: string]: any }) => {
        if (!err) {
          await this.saveFormData(values);
        }
      }
    );
  };

  saveFormData = async (values: { [key: string]: any }) => {
    const { contacts, victims } = this.state;
    const { bookingId } = this.props;

    const interviewData = convertFormFieldDataToInterviewFormDataInput(
      values,
      contacts,
      victims
    );

    await updateBookingInterviewData({
      booking_id: bookingId,
      interview_data: interviewData,
    });
  };

  setContacts = (
    newContacts: ParticipantContactFormEntry[],
    fieldName: VictimsOrContacts
  ) => {
    const newState = {
      [fieldName]: newContacts,
    } as Pick<State, "contacts" | "victims">;

    this.setState(newState);
  };

  addContact = (
    contact: ParticipantContactFormEntry,
    fieldName: VictimsOrContacts
  ) => {
    const contacts = this.state[fieldName];
    const newContacts = [...contacts, contact];
    this.setContacts(newContacts, fieldName);
  };

  removeContact = async (
    indexToRemove: number,
    fieldName: VictimsOrContacts
  ) => {
    const contacts = this.state[fieldName];
    const newContacts = contacts.filter(
      (_contact, index) => index !== indexToRemove
    );

    this.setContacts(newContacts, fieldName);
  };

  updateContact = (
    newContact: ParticipantContactFormEntry,
    indexToUpdate: number,
    fieldName: VictimsOrContacts
  ) => {
    const contacts = this.state[fieldName];
    const newContacts = contacts.map((contact, index) => {
      if (index === indexToUpdate) {
        return newContact;
      }
      return contact;
    });

    this.setContacts(newContacts, fieldName);
  };

  render() {
    const {
      residenceFormFields,
      employmentFormFields,
      militaryServiceFields,
      substanceAbuseHistoryFields,
      priorRecordFields,
      victimInfoFields,
      firearmFormFields,
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit}>
        <InterviewFormSection
          sectionTitle={"Residence"}
          renderContents={() => (
            <>
              {residenceFormFields.map((residenceFormField) => {
                return (
                  <InterviewFormItem
                    key={residenceFormField.id}
                    formData={residenceFormField}
                    getFieldDecorator={getFieldDecorator}
                    handleBlur={this.handleBlur}
                  />
                );
              })}
            </>
          )}
        />
        <InterviewFormSection
          sectionTitle={"Family Ties"}
          renderContents={() => (
            <>
              <ParticipantContactsForm
                title={
                  "Relatives/references the defendant lives with or keeps in close contact with"
                }
                contacts={this.state.contacts}
                updateContact={(
                  newContact: ParticipantContactFormEntry,
                  indexToUpdate: number
                ) => this.updateContact(newContact, indexToUpdate, "contacts")}
                addContact={(contact: ParticipantContactFormEntry) =>
                  this.addContact(contact, "contacts")
                }
                removeContact={(index: number) =>
                  this.removeContact(index, "contacts")
                }
              />
            </>
          )}
        />
        <InterviewFormSection
          sectionTitle={"Employment"}
          renderContents={() => (
            <>
              {employmentFormFields.map((employmentFormFields) => {
                return (
                  <InterviewFormItem
                    key={employmentFormFields.id}
                    formData={employmentFormFields}
                    getFieldDecorator={getFieldDecorator}
                    handleBlur={this.handleBlur}
                  />
                );
              })}
            </>
          )}
        />
        <InterviewFormSection
          sectionTitle={"Military Service"}
          renderContents={() => (
            <>
              {militaryServiceFields.map((militaryServiceField) => {
                return (
                  <InterviewFormItem
                    key={militaryServiceField.id}
                    formData={militaryServiceField}
                    getFieldDecorator={getFieldDecorator}
                    handleBlur={this.handleBlur}
                  />
                );
              })}
            </>
          )}
        />
        <InterviewFormSection
          sectionTitle={"Prior Record"}
          renderContents={() => (
            <>
              {priorRecordFields.map((priorRecordField) => {
                return (
                  <InterviewFormItem
                    key={priorRecordField.id}
                    formData={priorRecordField}
                    getFieldDecorator={getFieldDecorator}
                    handleBlur={this.handleBlur}
                  />
                );
              })}
            </>
          )}
        />
        <InterviewFormSection
          sectionTitle={"History of drug abuse"}
          renderContents={() => (
            <>
              {substanceAbuseHistoryFields.map((substanceAbuseHistoryField) => {
                return (
                  <InterviewFormItem
                    key={substanceAbuseHistoryField.id}
                    formData={substanceAbuseHistoryField}
                    getFieldDecorator={getFieldDecorator}
                    handleBlur={this.handleBlur}
                  />
                );
              })}
            </>
          )}
        />
        <InterviewFormSection
          sectionTitle={"Victim info"}
          renderContents={() => (
            <>
              {victimInfoFields.map((victimInfoField) => {
                return (
                  <InterviewFormItem
                    key={victimInfoField.id}
                    formData={victimInfoField}
                    getFieldDecorator={getFieldDecorator}
                    handleBlur={this.handleBlur}
                  />
                );
              })}

              <ParticipantContactsForm
                title={"Victims"}
                contacts={this.state.victims}
                updateContact={(
                  newContact: ParticipantContactFormEntry,
                  indexToUpdate: number
                ) => this.updateContact(newContact, indexToUpdate, "victims")}
                addContact={(contact: ParticipantContactFormEntry) =>
                  this.addContact(contact, "victims")
                }
                removeContact={(index: number) =>
                  this.removeContact(index, "victims")
                }
              />
            </>
          )}
        />
        <InterviewFormSection
          sectionTitle={"AFS"}
          renderContents={() => (
            <>
              {firearmFormFields.map((firearmFormField) => {
                return (
                  <InterviewFormItem
                    key={firearmFormField.id}
                    formData={firearmFormField}
                    getFieldDecorator={getFieldDecorator}
                    handleBlur={this.handleBlur}
                  />
                );
              })}
            </>
          )}
        />
        <Row type="flex" justify="center" gutter={24}>
          <Form.Item>
            <Button type="primary" size={"large"} htmlType="submit">
              Save Interview
            </Button>
          </Form.Item>
        </Row>
      </Form>
    );
  }
}

export const WrappedInterviewForm = Form.create<AssessmentFormProps>({
  name: "Interview Form",
})(InterviewForm);
