import React, { useState } from "react";
import { Button, Input, Icon } from "antd";
import { ParticipantDetailsType } from "./screens/ParticipantDetailsQueryTypes";
import { updateParticipantCaseNumbers } from "../actions/participants";
import { Create } from "@material-ui/icons";
import "./CaseNumbers.scss";

export function CasesComponent(props: {
  caseNumbers: string[];
  participant: ParticipantDetailsType;
}) {
  const { caseNumbers, participant } = props;
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [caseNumberToAdd, setCaseNumberToAdd] = useState<string>("");

  const handleAddCaseNumberClicked = async () => {
    if (!caseNumberToAdd) {
      return;
    }

    const newCaseNumbers = caseNumbers
      ? [...caseNumbers, caseNumberToAdd]
      : [caseNumberToAdd];

    await updateParticipantCaseNumbers({
      id: participant.id,
      case_numbers: newCaseNumbers,
    });

    setIsEditMode(false);
    setCaseNumberToAdd("");
  };

  const handleRemoveCaseNumberClicked = async (caseNumberToRemove: string) => {
    if (!caseNumberToRemove) {
      return;
    }

    const newCaseNumbers = caseNumbers.filter(
      (caseNumber) => caseNumber !== caseNumberToRemove
    );

    await updateParticipantCaseNumbers({
      id: participant.id,
      case_numbers: newCaseNumbers,
    });
  };

  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "row" }}
        className="case-numbers-container"
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {caseNumbers.map((caseNumber) => (
            <div
              style={{ display: "flex", alignItems: "center" }}
              key={caseNumber}
            >
              <p style={{ margin: "0 0 0 5px" }}>{caseNumber}</p>
              {isEditMode && (
                <Icon
                  onClick={() => handleRemoveCaseNumberClicked(caseNumber)}
                  type="minus-circle-o"
                  style={{ marginLeft: "5px" }}
                />
              )}
            </div>
          ))}
        </div>
        {!isEditMode && (
          <div style={{ minHeight: "21px" }}>
            <Create
              onClick={() => setIsEditMode(!isEditMode)}
              className="show-edit"
              nativeColor="#3b7abe"
              style={{
                fontSize: 16,
                marginRight: 5,
                marginLeft: 10,
              }}
            />
          </div>
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {isEditMode && (
          <div style={{ margin: "10px 0px" }}>
            <Input
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCaseNumberToAdd(e.target.value)
              }
            />
            <div style={{ margin: "5px 0px" }}>
              <Button
                disabled={!caseNumberToAdd}
                size="small"
                type="primary"
                onClick={handleAddCaseNumberClicked}
              >
                Add
              </Button>
              <Button
                size="small"
                style={{ marginLeft: "5px" }}
                onClick={() => setIsEditMode(false)}
              >
                Done
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
