import React, { useLayoutEffect, useRef, useState } from "react";
import moment from "moment";
import { Input } from "antd";
import "./RecommendationForm";

interface SignatureDisplayProps {
  text: string;
}

// a signature that shrinks/grows the font according the number of characters
const SignatureDisplay = (props: SignatureDisplayProps) => {
  const { text } = props;
  const [loading, setLoading] = useState(true);
  const [fontSize, setFontSize] = useState(6);

  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    (async () => {
      const el = ref ? ref.current : null;
      if (el && el.clientWidth < 170 && fontSize < 40) {
        setFontSize(fontSize + 1);
        setLoading(true);
      } else {
        if (el) {
          setLoading(false);
        }
      }
    })();
  }, [text, fontSize]);

  return (
    <div
      ref={ref}
      className="signature-display"
      style={{
        fontSize: fontSize,
        top: -fontSize + 12,
        color: loading ? "white" : "black",
      }}
    >
      {text}
    </div>
  );
};

interface SignatureLineProps {
  formData?: any;
  isPrintView?: boolean;
  isCurrentUserAdmin?: boolean;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
}

export function SignatureLine(props: SignatureLineProps) {
  const { formData, isPrintView, isCurrentUserAdmin, onChange } = props;

  return (
    <div className="avoid-page-break">
      <div className="signature-container">
        <div className="mr">
          <p>APPROVED:</p>
        </div>
        <div className={"signature-line-container mr"}>
          {(!isCurrentUserAdmin || (isPrintView && formData)) && (
            <SignatureDisplay text={formData.signature_probation_supervisor} />
          )}
          {!isPrintView && isCurrentUserAdmin && (
            <Input
              name="signature_probation_supervisor"
              onChange={onChange}
              value={formData.signature_probation_supervisor}
              placeholder={"Enter Signature"}
              className="signature-input"
            />
          )}
          <div className="signature-line">&nbsp;</div>
          <p>Probation Supervisor</p>
        </div>
        <div className="mr">
          <p>{`DATE: ${moment().format("MM/DD/YYYY")}`} </p>
        </div>
        <div className={"signature-line-container"}>
          {isPrintView && formData && (
            <SignatureDisplay text={formData.signature_team_member} />
          )}
          {!isPrintView && (
            <Input
              name="signature_team_member"
              onChange={onChange}
              placeholder={"Enter Signature"}
              value={formData.signature_team_member}
              className="signature-input"
            />
          )}
          <div className="signature-line">&nbsp;</div>
          <p>Pretrial Program Team Member</p>
        </div>
      </div>
      <div>
        <div style={{ textAlign: "right" }}>cc: District Attorney</div>
        <div style={{ textAlign: "right" }}>Public Defender</div>
      </div>
    </div>
  );
}
