/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type Employment = "employed" | "full_time_student" | "none" | "primary_caregiver" | "retired" | "%future added value";
export type MutationResult = "failure" | "skipped" | "success" | "%future added value";
export type ParticipantAssessmentResultStatus = "draft" | "finalized" | "new" | "%future added value";
export type YesNo = "no" | "yes" | "%future added value";
export type ZeroToThreeOrMore = "more" | "one" | "three" | "two" | "zero" | "%future added value";
export type ResearchFactorInput = {
    readonly has_prior_misdemeanor_conviction?: YesNo | null;
    readonly has_prior_felony_conviction?: YesNo | null;
    readonly prior_violent_convictions_count?: ZeroToThreeOrMore | null;
    readonly prior_FTAs_past_two_years_count?: ZeroToThreeOrMore | null;
    readonly has_prior_FTAs_older_than_two_years?: YesNo | null;
    readonly has_prior_sentence_to_incarceration?: YesNo | null;
};
export type RiskFactorInput = {
    readonly has_active_community_supervision?: YesNo | null;
    readonly is_charge_felony_drug_theft_or_fraud?: YesNo | null;
    readonly has_pending_charges?: YesNo | null;
    readonly has_criminal_history?: YesNo | null;
    readonly has_two_or_more_FTAs?: YesNo | null;
    readonly has_two_or_more_violent_convictions?: YesNo | null;
    readonly has_history_of_drug_abuse?: YesNo | null;
    readonly employed_at_time_of_arrest?: Employment | null;
};
export type participantAssessmentResultScoreParticipantAssessmentResultMutationVariables = {
    readonly participant_assessment_result_id: string;
    readonly research_factors: ResearchFactorInput;
    readonly risk_factors: RiskFactorInput;
};
export type participantAssessmentResultScoreParticipantAssessmentResultMutationResponse = {
    readonly scoreParticipantAssessmentResult: ({
        readonly participant_assessment_result: ({
            readonly id: string;
            readonly status: ParticipantAssessmentResultStatus;
            readonly case_number: string;
            readonly scored_by: string | null;
            readonly interview_and_assessment: {
                readonly research_factors: ({
                    readonly has_prior_misdemeanor_conviction: YesNo | null;
                    readonly has_prior_felony_conviction: YesNo | null;
                    readonly prior_violent_convictions_count: ZeroToThreeOrMore | null;
                    readonly prior_FTAs_past_two_years_count: ZeroToThreeOrMore | null;
                    readonly has_prior_FTAs_older_than_two_years: YesNo | null;
                    readonly has_prior_sentence_to_incarceration: YesNo | null;
                }) | null;
                readonly risk_factors: ({
                    readonly has_active_community_supervision: YesNo | null;
                    readonly is_charge_felony_drug_theft_or_fraud: YesNo | null;
                    readonly has_pending_charges: YesNo | null;
                    readonly has_criminal_history: YesNo | null;
                    readonly has_two_or_more_FTAs: YesNo | null;
                    readonly has_two_or_more_violent_convictions: YesNo | null;
                    readonly has_history_of_drug_abuse: YesNo | null;
                    readonly employed_at_time_of_arrest: Employment | null;
                }) | null;
                readonly instrument_completed_at: string | null;
                readonly last_updated_at: string | null;
            };
            readonly risk_assessment_result: ({
                readonly score: number | null;
                readonly level: string | null;
                readonly failure_rate: string | null;
            }) | null;
        }) | null;
        readonly result: MutationResult;
        readonly description: string | null;
        readonly errors: ReadonlyArray<string | null> | null;
    }) | null;
};
export type participantAssessmentResultScoreParticipantAssessmentResultMutation = {
    readonly response: participantAssessmentResultScoreParticipantAssessmentResultMutationResponse;
    readonly variables: participantAssessmentResultScoreParticipantAssessmentResultMutationVariables;
};



/*
mutation participantAssessmentResultScoreParticipantAssessmentResultMutation(
  $participant_assessment_result_id: String!
  $research_factors: ResearchFactorInput!
  $risk_factors: RiskFactorInput!
) {
  scoreParticipantAssessmentResult(participant_assessment_result_id: $participant_assessment_result_id, research_factors: $research_factors, risk_factors: $risk_factors) {
    participant_assessment_result {
      id
      status
      case_number
      scored_by
      interview_and_assessment {
        research_factors {
          has_prior_misdemeanor_conviction
          has_prior_felony_conviction
          prior_violent_convictions_count
          prior_FTAs_past_two_years_count
          has_prior_FTAs_older_than_two_years
          has_prior_sentence_to_incarceration
        }
        risk_factors {
          has_active_community_supervision
          is_charge_felony_drug_theft_or_fraud
          has_pending_charges
          has_criminal_history
          has_two_or_more_FTAs
          has_two_or_more_violent_convictions
          has_history_of_drug_abuse
          employed_at_time_of_arrest
        }
        instrument_completed_at
        last_updated_at
      }
      risk_assessment_result {
        score
        level
        failure_rate
      }
    }
    result
    description
    errors
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "participant_assessment_result_id",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "research_factors",
            "type": "ResearchFactorInput!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "risk_factors",
            "type": "RiskFactorInput!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "scoreParticipantAssessmentResult",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "participant_assessment_result_id",
                    "variableName": "participant_assessment_result_id"
                },
                {
                    "kind": "Variable",
                    "name": "research_factors",
                    "variableName": "research_factors"
                },
                {
                    "kind": "Variable",
                    "name": "risk_factors",
                    "variableName": "risk_factors"
                }
            ],
            "concreteType": "NewParticipantAssessmentResult",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "participant_assessment_result",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ParticipantAssessmentResult",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "status",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "case_number",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "scored_by",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "interview_and_assessment",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "InterviewAndAssessment",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "research_factors",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ResearchFactor",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "has_prior_misdemeanor_conviction",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "has_prior_felony_conviction",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "prior_violent_convictions_count",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "prior_FTAs_past_two_years_count",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "has_prior_FTAs_older_than_two_years",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "has_prior_sentence_to_incarceration",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                },
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "risk_factors",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "RiskFactor",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "has_active_community_supervision",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "is_charge_felony_drug_theft_or_fraud",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "has_pending_charges",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "has_criminal_history",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "has_two_or_more_FTAs",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "has_two_or_more_violent_convictions",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "has_history_of_drug_abuse",
                                            "args": null,
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "employed_at_time_of_arrest",
                                            "args": null,
                                            "storageKey": null
                                        }
                                    ]
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "instrument_completed_at",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "last_updated_at",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "risk_assessment_result",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "RiskAssessmentResult",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "score",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "level",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "failure_rate",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "result",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "errors",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "participantAssessmentResultScoreParticipantAssessmentResultMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "participantAssessmentResultScoreParticipantAssessmentResultMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "participantAssessmentResultScoreParticipantAssessmentResultMutation",
            "id": null,
            "text": "mutation participantAssessmentResultScoreParticipantAssessmentResultMutation(\n  $participant_assessment_result_id: String!\n  $research_factors: ResearchFactorInput!\n  $risk_factors: RiskFactorInput!\n) {\n  scoreParticipantAssessmentResult(participant_assessment_result_id: $participant_assessment_result_id, research_factors: $research_factors, risk_factors: $risk_factors) {\n    participant_assessment_result {\n      id\n      status\n      case_number\n      scored_by\n      interview_and_assessment {\n        research_factors {\n          has_prior_misdemeanor_conviction\n          has_prior_felony_conviction\n          prior_violent_convictions_count\n          prior_FTAs_past_two_years_count\n          has_prior_FTAs_older_than_two_years\n          has_prior_sentence_to_incarceration\n        }\n        risk_factors {\n          has_active_community_supervision\n          is_charge_felony_drug_theft_or_fraud\n          has_pending_charges\n          has_criminal_history\n          has_two_or_more_FTAs\n          has_two_or_more_violent_convictions\n          has_history_of_drug_abuse\n          employed_at_time_of_arrest\n        }\n        instrument_completed_at\n        last_updated_at\n      }\n      risk_assessment_result {\n        score\n        level\n        failure_rate\n      }\n    }\n    result\n    description\n    errors\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '93f0eff363c85f7fca79ae042843b9e4';
export default node;
