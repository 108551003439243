import idx from "idx.macro";
import graphql from "babel-plugin-relay/macro";

import { KeyedString, RelayResponse, Participant } from "../../../lib/graphql";
import { TableData } from "../../table/tableTypes";
import { ParticipantItem } from "../ParticipantListTypes";

export type ParticipantListTableGraphqlWithEventsType = {
  application: {
    risk_levels: KeyedString[];
    compliance_options: KeyedString[];
  };
  participants: {
    participant: Participant[];
  };
};

export interface ParticipantListTableGraphqlWithEventsResultType
  extends RelayResponse<ParticipantListTableGraphqlWithEventsType> {}

export const ParticipantListTableGraphqlWithEventsIsValid = (_: any) => true;

export const ParticipantListTableGraphqlWithEventsDataToItems = (
  data: ParticipantListTableGraphqlWithEventsType
): TableData<ParticipantItem> =>
  idx<TableData<ParticipantItem>>(data, (_) => _.participants.participant);

export const ParticipantListTableGraphqlWithEvents = graphql`
  query ParticipantListTableGqlWithEventsQuery(
    $risk: String
    $name_contains: String
    $case_manager_id: String
    $active_clients_only: Boolean
    $secondary_case_manager_id: String
  ) {
    application {
      risk_levels {
        key
        value
      }
      compliance_options {
        key
        value
      }
    }
    participants(
      risk: $risk
      name_contains: $name_contains
      case_manager_id: $case_manager_id
      active_clients_only: $active_clients_only
      secondary_case_manager_id: $secondary_case_manager_id
    ) {
      participant {
        id
        name {
          first
          last
        }
        preferred_name
        risk_level
        program_title
        supervision_begin_date
        supervision_end_date
        status
        compliance
        sms_enabled
        sms_consent
        phone {
          mobile
        }
        case_manager {
          id
          name {
            first
            last
          }
        }
        tasks {
          id
          created_at
          completed_at
          label
          type
        }
        program_title
        sent_message_unread_count(unread_for: "all")
        deactivated_reason
        address {
          value
        }
        current_device {
          platform
        }
        case_number
        case_numbers
        is_active
        probation_officer
      }
    }
  }
`;

export const ParticipantListTableGraphqlWithEventsSynchronous = graphql`
  query ParticipantListTableGqlWithEventsSynchronousQuery(
    $risk: String
    $name_contains: String
    $case_manager_id: String
    $active_clients_only: Boolean
    $secondary_case_manager_id: String
  ) {
    application {
      risk_levels {
        key
        value
      }
      compliance_options {
        key
        value
      }
    }
    participants(
      risk: $risk
      name_contains: $name_contains
      case_manager_id: $case_manager_id
      active_clients_only: $active_clients_only
      secondary_case_manager_id: $secondary_case_manager_id
    ) {
      participant {
        id
        name {
          first
          last
        }
        preferred_name
        risk_level
        program_title
        supervision_begin_date
        supervision_end_date
        status
        compliance
        sms_enabled
        sms_consent
        phone {
          mobile
        }
        case_manager {
          id
          name {
            first
            last
          }
        }
        tasks {
          id
          created_at
          completed_at
          label
          type
        }
        program_title
        sent_message_unread_count(unread_for: "all")
        deactivated_reason
        events {
          id
          date
          activity {
            title {
              en
            }
          }
        }
        address {
          value
        }
        current_device {
          platform
        }
        case_number
        case_numbers
        is_active
      }
    }
  }
`;
