import React, { useState } from "react";
import {
  Booking,
  Maybe,
  ProspectStatus,
  RecommendationReviewStatus,
  YesNo,
} from "../../graphql-types";
import { Table, Tag } from "antd";
import moment from "moment";
import { Link } from "@reach/router";
import { ROOT_PATH } from "../../AppInfo";
import { ProspectDetailSections } from "./ProspectDetail";
const titleCase = require("title-case");

interface ProspectTableDataSource {
  key: string;
  client_name: JSX.Element;
  recommendation_type: string;
  court_date: string;
  prospect_status: Maybe<JSX.Element>;
  recommendation_review_status: JSX.Element;
  next_step: JSX.Element;
}

const steps = [
  { key: ProspectStatus.New, title: "New" },
  { key: ProspectStatus.PendingInterview, title: "Pending Interview" },
  {
    key: ProspectStatus.PendingRecommendation,
    title: "Pending Recommendation",
  },
  { key: ProspectStatus.PendingCourtDecision, title: "Pending Court Decision" },
  {
    key: ProspectStatus.EnrollOrArchive,
    title: "Enroll / Archive Prospective Client",
  },
  {
    key: ProspectStatus.Archived,
    title: "Archive",
  },
];

const recommendationReviewStatus = {
  [RecommendationReviewStatus.NotReadyForReview]: "Not ready",
  [RecommendationReviewStatus.PendingReview]: "Pending review",
  [RecommendationReviewStatus.Approved]: "Approved",
  [RecommendationReviewStatus.Rejected]: "Changes requested",
};

const getTagReviewColor = (
  reviewStatus: RecommendationReviewStatus
): string | undefined => {
  const reviewStatusColorDictionary = {
    [RecommendationReviewStatus.NotReadyForReview]: undefined,
    [RecommendationReviewStatus.PendingReview]: "orange",
    [RecommendationReviewStatus.Approved]: "green",
    [RecommendationReviewStatus.Rejected]: "red",
  };

  return reviewStatusColorDictionary[reviewStatus] || undefined;
};

const getProspectDetaillNavigation = (
  prospectStatus: Maybe<ProspectStatus>
): string => {
  if (!prospectStatus) {
    return ProspectDetailSections.Summary;
  }

  if (prospectStatus === ProspectStatus.PendingSubmission) {
    return ProspectDetailSections.Recommendation;
  }

  const prospectDetailSubPageDictionary: { [key: string]: string } = {
    [ProspectStatus.New]: ProspectDetailSections.Summary,
    [ProspectStatus.PendingInterview]:
      ProspectDetailSections.InterviewAndAssessment,
    [ProspectStatus.PendingRecommendation]:
      ProspectDetailSections.Recommendation,
    [ProspectStatus.PendingCourtDecision]: ProspectDetailSections.CourtDecision,
    [ProspectStatus.EnrollOrArchive]: ProspectDetailSections.EnrollOrArchive,
  };

  return (
    prospectDetailSubPageDictionary[prospectStatus] ||
    ProspectDetailSections.Summary
  );
};

function mapBookingsToProspectTableDataSource(
  bookings: Booking[]
): ProspectTableDataSource[] {
  return bookings.map((booking, index) => {
    const {
      booking_defendent: bookingDefendent,
      id,
      is_interview_declined,
      booking_status: bookingStatus,
    } = booking;

    const prospectDetailNavigation = getProspectDetaillNavigation(
      booking.prospect_status ? booking.prospect_status : null
    );

    return {
      key: `${index}`,
      client_name: (
        <Link to={`/${ROOT_PATH}/prospects/${id}/${prospectDetailNavigation}`}>
          {`${bookingDefendent.name.last}, ${bookingDefendent.name.first}`}
        </Link>
      ),
      recommendation_type:
        is_interview_declined === YesNo.Yes ? "Memo" : "Recommendation",
      court_date:
        bookingStatus && bookingStatus.court_appearance_datetime
          ? moment(bookingStatus.court_appearance_datetime).format(
              "MM/DD/YYYY h:mm a"
            )
          : "N/A",
      prospect_status: <Tag>{titleCase(booking.prospect_status)}</Tag>,
      next_step: (
        <Tag>
          {
            steps[
              steps.findIndex((step) => step.key === booking.prospect_status) +
                1
            ].title
          }
        </Tag>
      ),
      recommendation_review_status: (
        <Tag
          color={getTagReviewColor(
            booking.recommendation_review_status ||
              RecommendationReviewStatus.NotReadyForReview
          )}
          key={
            recommendationReviewStatus[
              booking.recommendation_review_status ||
                RecommendationReviewStatus.NotReadyForReview
            ]
          }
        >
          {
            recommendationReviewStatus[
              booking.recommendation_review_status ||
                RecommendationReviewStatus.NotReadyForReview
            ]
          }
        </Tag>
      ),
    };
  });
}

export function ProspectTable({ bookings }: { bookings: Booking[] }) {
  const prospectTableDataSource = mapBookingsToProspectTableDataSource(
    bookings
  );

  const [sortedInfo, setSortedInfo] = useState({
    order: "ascend",
    columnKey: "court_date",
  } as any);

  const handleChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortedInfo(sorter);
  };

  const prospectTableColumns = [
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
    },
    {
      title: "Recommendation Type",
      dataIndex: "recommendation_type",
      key: "recommendation_type",
    },
    {
      title: "Court date/time",
      dataIndex: "court_date",
      key: "court_date",
      sorter: (a: ProspectTableDataSource, b: ProspectTableDataSource) => {
        const firstMoment =
          a.court_date === "N/A"
            ? moment().subtract(100, "years")
            : moment(a.court_date, "MM/DD/YYYY h:mm a");
        const secondMoment =
          b.court_date === "N/A"
            ? moment().subtract(100, "years")
            : moment(b.court_date, "MM/DD/YYYY h:mm a");

        return firstMoment.isBefore(secondMoment) ? 1 : -1;
      },
      sortOrder: sortedInfo.columnKey === "court_date" && sortedInfo.order,
    },
    {
      title: "Current Step",
      dataIndex: "prospect_status",
      key: "prospect_status",
    },
    {
      title: "Next Step",
      dataIndex: "next_step",
      key: "next_step",
    },
    {
      title: "Review Status",
      dataIndex: "recommendation_review_status",
      key: "recommendation_review_status",
      sorter: (a: ProspectTableDataSource, b: ProspectTableDataSource) => {
        const aKey = a.recommendation_review_status.key as string;
        const bKey = b.recommendation_review_status.key as string;
        if (aKey < bKey) {
          return -1;
        }
        if (aKey > bKey) {
          return 1;
        }
        return 0;
      },
      sortOrder:
        sortedInfo.columnKey === "recommendation_review_status" &&
        sortedInfo.order,
    },
  ];

  return (
    <Table
      onChange={handleChange}
      dataSource={prospectTableDataSource}
      columns={prospectTableColumns}
      pagination={false}
    />
  );
}
