import React from "react";
import { participantAssessmentsQuery } from "./ParticipantAssessmentGraphql";
import { QueryRenderer } from "../../shared/lib/graphql";
import { Me, Participant } from "../../graphql-types";
import { CarePlanContainerProps } from "../care-plan/CarePlanContainer";
import idx from "idx.macro";
import { safelyUnwrapArrayWithPossibleNullOrUndefined } from "../../utils/safeUnwrap";
import AddAssessmentResult from "../modals/assessment-result/AddAssessmentResult";
import { Operation } from "../modals/types";
import { Tooltip, Button } from "antd";
import { addParticipantAssessmentResult } from "../../actions/participantAssessmentResult";
import { AddAssessmentResultFormData } from "../modals/assessment-result/AddAssessmentResultModalWrapper";
import { Collapse } from "antd";
import { customPanelStyle } from "../care-plan/CarePlan";
import { WrappedAssessmentForm } from "./AssessmentForm";
import { AssessmentFormHeader } from "./AssessmentFormHeader";

export interface ParticipantAssessmentQueryResult {
  me: Me;
  participants: {
    participant: Array<Participant>;
  };
}

export function ParticipantAssessmentContainer(props: CarePlanContainerProps) {
  const { id } = props;
  const { Panel } = Collapse;
  return (
    <QueryRenderer
      query={participantAssessmentsQuery}
      variables={{ id }}
      SuccessComponent={(props: ParticipantAssessmentQueryResult) => {
        const participant = idx(props, (_) => _.participants.participant[0]);
        const caseManager = idx(props, (_) => _.me.case_manager);
        const { assessment_results: assessmentResults } = participant;
        const participantFullName = `${participant.name.first} ${participant.name.last}`;

        const unwrappedAssessments = safelyUnwrapArrayWithPossibleNullOrUndefined(
          assessmentResults ? assessmentResults : []
        );

        return (
          <div>
            <div style={{ marginBottom: "1em" }}>
              <AddAssessmentResult
                updateObject={{
                  first: participant.name.first,
                  last: participant.name.last,
                  createdBy: caseManager
                    ? `${caseManager.name.first} ${caseManager.name.last}`
                    : "",
                }}
                callBackForAPI={async (formData: AddAssessmentResultFormData) =>
                  await addParticipantAssessmentResult({
                    participant_id: participant.id,
                    scored_by: formData.createdBy.value,
                    case_number: formData.caseNumber.value,
                  })
                }
                type="Assessment"
                operation={Operation.Add}
                buttonComponent={
                  <Tooltip title={`Add a new participant assessment`}>
                    <Button type={"primary"}>Add Assessment</Button>
                  </Tooltip>
                }
              />
            </div>

            {unwrappedAssessments.map((assessment, index) => (
              <Collapse key={index.toString()}>
                <Panel
                  key={index.toString()}
                  style={{ ...customPanelStyle }}
                  header={
                    <AssessmentFormHeader
                      {...assessment}
                      participantFullName={participantFullName}
                    />
                  }
                >
                  <WrappedAssessmentForm
                    participantAssessmentResultId={assessment.id}
                    interviewAndAssessment={assessment.interview_and_assessment}
                    risk_assessment_result={assessment.risk_assessment_result}
                  />
                </Panel>
              </Collapse>
            ))}
          </div>
        );
      }}
    />
  );
}
