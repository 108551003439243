import graphql from "babel-plugin-relay/macro";

export const bookingsGraphqlQuery = graphql`
  query BookingsGraphqlQuery {
    bookings(is_eligible: unknown) {
      booking {
        id
        booking_defendent {
          cii_number
          local_id
          cdl_id
          name {
            first
            last
          }
          date_of_birth
          sex
          race
        }
        booking_info {
          booking_sid
          local_id
          booking_number
          booking_datetime
          booking_address
          booking_date
          arrest_agency
          arrested_for
        }
        booking_charges {
          id
          booking_charge_sid
          booking_sid
          booking_number
          booking_datetime
          charge_auth_type
          charge_code_section
          charge_type
          charge_datetime
          charge_description
          arrest_agency
          arrest_datetime
          crime_case_number
          court_warrant_number
        }
        booking_releases {
          charge_code_section
          charge_description
          charge_type
          charge_release_datetime
          charge_release_type
          warrant_number
          court_case_number
          jc_control
          created_datetime
        }
        booking_status {
          booking_number
          court_appearance_datetime
          court_reason
          court_room
        }
        classification
        imported_date
        comments
        is_eligible
        spanish_speaker
        has_wait
        prospect_status
        participant_id
        interview_and_assessment {
          research_factors {
            has_prior_misdemeanor_conviction
            has_prior_felony_conviction
            prior_violent_convictions_count
            prior_FTAs_past_two_years_count
            has_prior_FTAs_older_than_two_years
            has_prior_sentence_to_incarceration
          }
          risk_factors {
            has_active_community_supervision
            is_charge_felony_drug_theft_or_fraud
            has_pending_charges
            has_criminal_history
            has_two_or_more_FTAs
            has_two_or_more_violent_convictions
            has_history_of_drug_abuse
            employed_at_time_of_arrest
          }
          completed_by {
            id
            name {
              first
              last
            }
          }
          court_date
          charge_bond_type_amount_court
          instrument_completed_at
          last_updated_at
        }
        is_interview_declined
        recommendation_review_status
      }
    }
    referrals(processed_at: null) {
      referral {
        id
        cjis_referral_sid
        court_case_no
        last_name
        first_name
        middle_name
        date_of_birth
        event_code
        event_datetime
        event_text
        referral_date
        processed_at
        processed_by_id
        booking_id
        created_at
      }
    }
  }
`;

export const bookingsGraphqlByIdQuery = graphql`
  query BookingsGraphqlByIdQuery($id: String!) {
    bookings(id: $id) {
      booking {
        id
        booking_defendent {
          cii_number
          local_id
          cdl_id
          name {
            first
            last
          }
          date_of_birth
          sex
          race
        }
        booking_info {
          booking_sid
          local_id
          booking_number
          booking_datetime
          booking_address
          booking_date
          arrest_agency
          arrested_for
        }
        booking_charges {
          id
          booking_charge_sid
          booking_sid
          booking_number
          booking_datetime
          charge_auth_type
          charge_code_section
          charge_type
          charge_datetime
          charge_description
          arrest_agency
          arrest_datetime
          crime_case_number
          court_warrant_number
        }
        booking_releases {
          charge_code_section
          charge_description
          charge_type
          charge_release_datetime
          charge_release_type
          warrant_number
          court_case_number
          jc_control
          created_datetime
        }
        booking_status {
          booking_number
          court_appearance_datetime
          court_reason
          court_room
        }
        classification
        imported_date
        comments
        is_eligible
        spanish_speaker
        has_wait
        prospect_status
        participant_id
        interview_and_assessment {
          interview_data {
            residence {
              address
              moved_into_current_address_date
              phone_number
              moved_to_bay_area_date
            }
            contacts {
              name {
                first
                last
              }
              address
              phone
              date_of_birth
              sex
              relationship
              how_often_seen
            }
            employment_and_education {
              is_employed
              current_employer_name
              is_student_retired_disabled
              is_primary_caretaker
              employment_start_date
              employment_type
              hours_worked_per_week
              type_of_work
              previous_employer_name
              previous_employer_start_date
              previous_employer_end_date
              unemployed_start_date
              unemployed_how_supported
            }
            military_service {
              has_served_in_military
              military_service_comments
            }
            prior_record {
              bench_warrants_count
              currently_on_probation
              has_pending_case
              prior_record_comments
            }
            substance_abuse {
              has_experimented_with_illegal_substances
              type_of_illegal_substance_experimented
              last_use_of_illegal_substance_date
              has_abused_alcohol_or_marijuana
              last_alcohol_or_marijuna_abuse_date
              alcohol_or_marijuana_abuse_how_often
              has_used_illegal_drugs_last_six_months
              type_of_illegal_drug_used_last_six_months
              how_often_illegal_drug_use_last_six_months
              has_received_treatment_alcohol_or_marijuana
              alcohol_or_marijuana_program_name
              alcohol_or_marijuana_treatment_date
            }
            victim_info {
              was_victim_in_case
              victims {
                name {
                  first
                  last
                }
                address
                phone
                date_of_birth
                sex
                relationship
                how_often_seen
              }
              has_protective_order
              has_stay_away_order
              has_peaceful_contact_order
              protective_order_notes
            }
            firearm_info {
              has_firearm_registered
              registered_firearm_model
              registered_firearm_location
              has_firearm_in_home
              home_firearm_model
              home_firearm_location
            }
          }
          research_factors {
            has_prior_misdemeanor_conviction
            has_prior_felony_conviction
            prior_violent_convictions_count
            prior_FTAs_past_two_years_count
            has_prior_FTAs_older_than_two_years
            has_prior_sentence_to_incarceration
          }
          risk_factors {
            has_active_community_supervision
            is_charge_felony_drug_theft_or_fraud
            has_pending_charges
            has_criminal_history
            has_two_or_more_FTAs
            has_two_or_more_violent_convictions
            has_history_of_drug_abuse
            employed_at_time_of_arrest
          }
          completed_by {
            id
            name {
              first
              last
            }
          }
          court_date
          charge_bond_type_amount_court
          instrument_completed_at
          last_updated_at
        }
        is_interview_declined
        risk_assessment_result {
          score
          level
          failure_rate
        }
        court_memo {
          was_under_the_influence_of_alcohol_or_drugs
          was_combative
          was_in_medical_isolation
          mental_state_prevented_interview
          has_refused_interview
          is_no_longer_in_custody
          has_declined_interview_or_release
          has_plan_to_bail_out
          is_incomplete_due_to_insufficient_notice
          report_available_by_date
          has_existing_or_report_re_referred
          existing_or_report_date
          is_incomplete_due_to_referral_volume
          is_denied_or
          ordered_tad_date
          has_existing_or_report_previously_interviewed
          last_interviewed_date
          hold_out_state
          other_reason_comments
          signature_probation_supervisor
          signature_team_member
        }
        recommendation_form {
          has_verified_employment_information
          employment_comments
          pending_disposition
          pending_disposition_comments
          vprai_override_public_safety_comments
          incident_summary_notes
          mandatory_detention
          additional_information
          release_recommendation
          signature_probation_supervisor
          signature_team_member
          conditions_of_release {
            supervision_and_custody
            not_leave_state_or_residence
            notify_change_employment_or_residence
            report_regularly_pretrial
            report_to_probation
            tcemp
            tcv3a
            curfew
            location_tracking
            alcohol_detection
            alcohol_detection_with_curfew
            follow_court_protective_orders
            tcaap
            tcbaf
            tcnax
            tcnae
            tcgnx
            tcgwr
            follow_court_probation_conditions
            pretrial_program
          }
          reasons_against_release {
            high_risk_to_fail_pretrial_release
            vprai_override_public_safety
            vprai_override_victim_residence
          }
          criminal_history {
            two_or_more_violent_convictions
            one_or_more_misdemeanor_or_felony_convictions
            no_prior_criminal_history
          }
          afs_info {
            afs_check_date
            number_of_weapons_registered
          }
          substance_abuse_comments
          other_recommendation_notes
          victim_comments
          signature_probation_supervisor
          signature_team_member
          internal_notes {
            text
            created_at
            created_by {
              name {
                first
                last
              }
            }
          }
          submitted_for_review_by {
            name {
              first
              last
            }
          }
          submitted_for_review_at
          reviewed_by {
            name {
              first
              last
            }
          }
          reviewed_at
          completed_by {
            name {
              first
              last
            }
          }
          completed_at
        }
        recommendation_review_status
        pretrial_decision_form {
          decision
          has_gps_monitoring
          has_alcohol_monitoring
          has_basic_monitoring
          sentencing_decision
          jail_sanction
        }
      }
    }
  }
`;

export const bookingsGraphqlByDOB = graphql`
  query BookingsGraphqlByDOBQuery($date_of_birth: String!) {
    bookings(date_of_birth: $date_of_birth) {
      booking {
        id
        booking_defendent {
          cii_number
          local_id
          cdl_id
          name {
            first
            last
          }
          date_of_birth
          sex
          race
        }
        booking_info {
          booking_sid
          local_id
          booking_number
          booking_datetime
          booking_address
          booking_date
          arrest_agency
          arrested_for
        }
        booking_charges {
          id
          booking_charge_sid
          booking_sid
          booking_number
          booking_datetime
          charge_auth_type
          charge_code_section
          charge_type
          charge_datetime
          charge_description
          arrest_agency
          arrest_datetime
          crime_case_number
          court_warrant_number
        }
        booking_releases {
          charge_code_section
          charge_description
          charge_type
          charge_release_datetime
          charge_release_type
          warrant_number
          court_case_number
          jc_control
          created_datetime
        }
        booking_status {
          booking_number
          court_appearance_datetime
          court_reason
          court_room
        }
        classification
        imported_date
        comments
        is_eligible
        spanish_speaker
        has_wait
        prospect_status
        participant_id
        interview_and_assessment {
          research_factors {
            has_prior_misdemeanor_conviction
            has_prior_felony_conviction
            prior_violent_convictions_count
            prior_FTAs_past_two_years_count
            has_prior_FTAs_older_than_two_years
            has_prior_sentence_to_incarceration
          }
          risk_factors {
            has_active_community_supervision
            is_charge_felony_drug_theft_or_fraud
            has_pending_charges
            has_criminal_history
            has_two_or_more_FTAs
            has_two_or_more_violent_convictions
            has_history_of_drug_abuse
            employed_at_time_of_arrest
          }
          completed_by {
            id
            name {
              first
              last
            }
          }
          court_date
          charge_bond_type_amount_court
          instrument_completed_at
          last_updated_at
        }
        is_interview_declined
      }
    }
  }
`;

export const bookingsGraphqlByDOBAndName = graphql`
  query BookingsGraphqlByDOBAndNameQuery(
    $date_of_birth: String
    $first_name: String
    $last_name: String
  ) {
    bookings(
      date_of_birth: $date_of_birth
      first_name: $first_name
      last_name: $last_name
    ) {
      booking {
        id
        booking_defendent {
          cii_number
          local_id
          cdl_id
          name {
            first
            last
          }
          date_of_birth
          sex
          race
        }
        booking_info {
          booking_sid
          local_id
          booking_number
          booking_datetime
          booking_address
          booking_date
          arrest_agency
          arrested_for
        }
        booking_charges {
          id
          booking_charge_sid
          booking_sid
          booking_number
          booking_datetime
          charge_auth_type
          charge_code_section
          charge_type
          charge_datetime
          charge_description
          arrest_agency
          arrest_datetime
          crime_case_number
          court_warrant_number
        }
        booking_releases {
          charge_code_section
          charge_description
          charge_type
          charge_release_datetime
          charge_release_type
          warrant_number
          court_case_number
          jc_control
          created_datetime
        }
        booking_status {
          booking_number
          court_appearance_datetime
          court_reason
          court_room
        }
        classification
        imported_date
        comments
        is_eligible
        spanish_speaker
        has_wait
        prospect_status
        participant_id
        interview_and_assessment {
          research_factors {
            has_prior_misdemeanor_conviction
            has_prior_felony_conviction
            prior_violent_convictions_count
            prior_FTAs_past_two_years_count
            has_prior_FTAs_older_than_two_years
            has_prior_sentence_to_incarceration
          }
          risk_factors {
            has_active_community_supervision
            is_charge_felony_drug_theft_or_fraud
            has_pending_charges
            has_criminal_history
            has_two_or_more_FTAs
            has_two_or_more_violent_convictions
            has_history_of_drug_abuse
            employed_at_time_of_arrest
          }
          completed_by {
            id
            name {
              first
              last
            }
          }
          court_date
          charge_bond_type_amount_court
          instrument_completed_at
          last_updated_at
        }
        is_interview_declined
      }
    }
  }
`;
