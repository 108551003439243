import React, { useState } from "react";
import {
  Booking,
  Maybe,
  YesNo,
  RecommendationFormInput,
  ReleaseRecommendation,
  RecommendationReviewStatus,
  ProspectStatus,
  ProspectNote,
  CaseManager,
  Employment
} from "../../graphql-types";
import { CourtReportHeader } from "./CourtReportHeader";
import moment from "moment";
import "./RecommendationForm.scss";
import {
  Button,
  Checkbox,
  Input,
  DatePicker,
  Row,
  Col,
  Radio,
  Typography
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import { ROOT_PATH } from "../../AppInfo";
import { Moment } from "moment-timezone";
import { dateFormatList, booleanToYesNo, yesNoToBoolean } from "./shared";
import { CourtReportPreamble } from "./CourtReportPreamble";
import { SignatureLine } from "./SignatureLine";
import {
  updateBookingRecommendationForm,
  submitBookingRecommendationFormForReview,
  reviewBookingRecommendationForm,
  submitBookingRecommendationForm,
  addNoteToBookingRecommendationForm,
  revokeBookingRecommendationApproval
} from "../../actions/bookings";
import { withUser, WithUserProps } from "../../shared/lib/contexts/UserContext";
import { ConditionsOfRelease } from "./ConditionsOfRelease";

interface RecommendationFormProps {
  booking: Booking;
  handleDatePickerChange: Function;
  handleCheckboxChanged: (e: CheckboxChangeEvent) => void;
  handleInputChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  handleRadioChanged: (e: RadioChangeEvent, field: string) => void;
  recommendationFormData: { [key: string]: any };
  isPrintView: boolean;
  handleSaveCommentClicked: (e: React.MouseEvent) => void;
}

interface RecommendationFormData {
  has_verified_employment_information: boolean;
  employment_comments: string;
  pending_disposition: boolean;
  pending_disposition_comments: string;
  fta_comments: string;
  vprai_override_public_safety_comments: string;
  incident_summary_notes: string;
  mandatory_detention: boolean;
  one_or_more_misdemeanor_or_felony_convictions: boolean;
  two_or_more_violent_convictions: boolean;
  no_prior_criminal_history: boolean;
  arrest_reason: string;
  arrest_agency: string;
  booking_date: Maybe<Moment>;
  afs_check_date: Maybe<Moment>;
  number_of_weapons_registered: string;
  no_victim: boolean;
  victim_no_contact: boolean;
  victim_and_contact: boolean;
  victim_name: "";
  release_recommendation: ReleaseRecommendation;
  supervision_and_custody: boolean;
  not_leave_state_or_residence: boolean;
  notify_change_employment_or_residence: boolean;
  report_regularly_pretrial: boolean;
  report_to_probation: boolean;
  tcemp: boolean;
  tcv3a: boolean;
  curfew: boolean;
  location_tracking: boolean;
  alcohol_detection: boolean;
  alcohol_detection_with_curfew: boolean;
  follow_court_protective_orders: boolean;
  tcaap: boolean;
  tcbaf: boolean;
  tcnax: boolean;
  tcnae: boolean;
  tcgnx: boolean;
  tcgwr: boolean;
  follow_court_probation_conditions: boolean;
  pretrial_program: boolean;
  remain_in_custody: boolean;
  high_risk_to_fail_pretrial_release: boolean;
  vprai_override_public_safety: boolean;
  vprai_override_victim_residence: boolean;
  other_recommendation_notes: string;
  victim_comments: string;
  additional_information: string;
  other_recommendations: boolean;
  substance_abuse_comments: string;
  signature_probation_supervisor: string;
  signature_team_member: string;
}

interface InternalNotesSectionProps {
  booking: Booking;
}

interface InternalNoteProps {
  note: ProspectNote;
}

interface ActionSectionProps {
  booking: Booking;
  getRecommendationFormInput: () => RecommendationFormInput;
  renderPrintButton: () => JSX.Element;
  additionalSave?: () => void;
  setIsCurrentUserAdmin?: (val: boolean) => void;
}

interface ActionHistoryItemProps {
  actionStr: String;
  caseManager: CaseManager;
  dt: Maybe<string>;
}

const { TextArea } = Input;

const { Text, Title } = Typography;

type AllRecommendationFormProps = RecommendationFormProps & WithUserProps;

export const RecommendationForm = withUser<
  RecommendationFormProps,
  AllRecommendationFormProps
>(RecommendationFormComponent);

function RecommendationFormComponent(props: AllRecommendationFormProps) {
  const {
    booking,
    handleDatePickerChange,
    handleInputChange,
    handleCheckboxChanged,
    recommendationFormData,
    handleRadioChanged,
    isPrintView,
    handleSaveCommentClicked,
    userContext: { user }
  } = props;

  const {
    risk_assessment_result: riskAssessmentResult,
    interview_and_assessment
  } = booking;

  const score =
    riskAssessmentResult && riskAssessmentResult.score
      ? riskAssessmentResult.score
      : 0;

  const failureRate =
    riskAssessmentResult && riskAssessmentResult.failure_rate
      ? riskAssessmentResult.failure_rate
      : "N/A";

  const militaryServiceComments =
    interview_and_assessment &&
    interview_and_assessment.interview_data &&
    interview_and_assessment.interview_data.military_service
      ? interview_and_assessment.interview_data.military_service
          .military_service_comments
      : null;

  const getCriminalHistoryBullets = (): JSX.Element => {
    const twoOrMoreViolentConvictions =
      interview_and_assessment &&
      interview_and_assessment.risk_factors &&
      interview_and_assessment.risk_factors.has_two_or_more_violent_convictions
        ? interview_and_assessment.risk_factors
            .has_two_or_more_violent_convictions
        : null;

    const hasCriminalHistory =
      interview_and_assessment &&
      interview_and_assessment.risk_factors &&
      interview_and_assessment.risk_factors.has_criminal_history
        ? interview_and_assessment.risk_factors.has_criminal_history
        : null;

    return (
      <div className="checkbox-container">
        {twoOrMoreViolentConvictions === YesNo.Yes ? (
          <>
            <Checkbox checked={true}>
              The defendant has had 2 or more violent convictions.
            </Checkbox>
            <br />
          </>
        ) : null}

        {hasCriminalHistory === YesNo.Yes ? (
          <>
            <Checkbox checked={true}>
              {
                "The defendant has had 1 or more misdemeanor or felony convictions."
              }
            </Checkbox>
            <br />
          </>
        ) : null}

        {hasCriminalHistory == YesNo.No ? (
          <Checkbox checked={true}>
            The defendant has no prior criminal history.
          </Checkbox>
        ) : null}
      </div>
    );
  };

  const getResidenceBullets = (): JSX.Element => {
    const address =
      interview_and_assessment &&
      interview_and_assessment.interview_data &&
      interview_and_assessment.interview_data.residence
        ? interview_and_assessment.interview_data.residence.address
        : "";

    if (!address) {
      return (
        <div className="checkbox-container">
          <Checkbox checked>Residence not provided.</Checkbox>
        </div>
      );
    }

    const dateMovedIn =
      interview_and_assessment &&
      interview_and_assessment.interview_data &&
      interview_and_assessment.interview_data.residence
        ? interview_and_assessment.interview_data.residence
            .moved_into_current_address_date
        : "";

    const movedInText = dateMovedIn
      ? `The defendant reported living there since ${moment(dateMovedIn).format(
          "MM/DD/YYYY"
        )}.`
      : "";

    return (
      <div className="checkbox-container">
        <Checkbox checked>
          {`The defendant did provide an address of ${address}. ${movedInText}`}
        </Checkbox>
      </div>
    );
  };

  const getSubstanceAbuseBullets = (): JSX.Element => {
    const hasHistoryOfSubstanceAbuse =
      interview_and_assessment &&
      interview_and_assessment.risk_factors &&
      interview_and_assessment.risk_factors.has_history_of_drug_abuse
        ? interview_and_assessment.risk_factors.has_history_of_drug_abuse
        : null;

    return (
      <p>
        It was determined that the defendant{" "}
        <Radio.Group value={hasHistoryOfSubstanceAbuse || YesNo.No}>
          <Radio value={YesNo.Yes}>has</Radio>
          <Radio value={YesNo.No}>does not have</Radio>
        </Radio.Group>
        drug abuse history
      </p>
    );
  };

  const getEmploymentPrimaryCareTakerBullets = () => {
    const employmentStatus =
      interview_and_assessment &&
      interview_and_assessment.risk_factors &&
      interview_and_assessment.risk_factors.employed_at_time_of_arrest
        ? interview_and_assessment.risk_factors.employed_at_time_of_arrest
        : null;

    return (
      <div className="checkbox-container">
        <p>The defendant reported that they are currently</p>
        <Radio.Group value={employmentStatus || Employment.None}>
          <Radio value={Employment.Employed}>Employed</Radio>
          <Radio value={Employment.FullTimeStudent}>Full-time student</Radio>
          <Radio value={Employment.PrimaryCaregiver}>
            Primary caregiver (minor child, elderly person or disabled person)
          </Radio>
          <Radio value={Employment.Retired}>Retired</Radio>
          <Radio value={Employment.None}>Unemployed</Radio>
        </Radio.Group>
        <br />
        <br />
        <Checkbox
          name={"has_verified_employment_information"}
          checked={recommendationFormData.has_verified_employment_information}
          onChange={handleCheckboxChanged}
        >
          Employment information was verified by pretrial staff.
        </Checkbox>
      </div>
    );
  };

  const getPendingDispositionBullets = (): JSX.Element => {
    const pendingDisposition =
      interview_and_assessment &&
      interview_and_assessment.risk_factors &&
      interview_and_assessment.risk_factors.has_pending_charges
        ? interview_and_assessment.risk_factors.has_pending_charges
        : null;

    if (pendingDisposition === YesNo.Yes) {
      return (
        <Checkbox checked={true}>
          The defendant has a case that is pending disposition.
        </Checkbox>
      );
    } else {
      return (
        <Checkbox checked={true}>
          The defendant does NOT have a case that is pending disposition.
        </Checkbox>
      );
    }
  };

  const getMilitaryServiceBullets = (): JSX.Element => {
    const hasServedInMilitary =
      interview_and_assessment &&
      interview_and_assessment.interview_data &&
      interview_and_assessment.interview_data.military_service
        ? interview_and_assessment.interview_data.military_service
            .has_served_in_military
        : null;

    if (hasServedInMilitary === YesNo.Yes) {
      return (
        <Checkbox checked={true}>
          The defendant has served in the U.S. Armed Forces
        </Checkbox>
      );
    } else {
      return (
        <Checkbox checked={true}>
          The defendant has NOT served in the U.S. Armed Forces
        </Checkbox>
      );
    }
  };

  const getFTABullets = (): JSX.Element => {
    const hasTwoOrMOreFTAs =
      interview_and_assessment &&
      interview_and_assessment.risk_factors &&
      interview_and_assessment.risk_factors.has_two_or_more_FTAs
        ? interview_and_assessment.risk_factors.has_two_or_more_FTAs
        : null;

    return (
      <div className="checkbox-container">
        {hasTwoOrMOreFTAs == YesNo.No ? (
          <Checkbox checked={hasTwoOrMOreFTAs === YesNo.No}>
            {`The defendant has had less than 2 bench warrants issued.`}
          </Checkbox>
        ) : (
          <Checkbox checked={hasTwoOrMOreFTAs === YesNo.Yes}>
            {`The defendant has had 2 or more bench warrants issued.`}
          </Checkbox>
          )}

      {hasTwoOrMOreFTAs == YesNo.Yes ? (
        <div>
        {!isPrintView || recommendationFormData.fta_comments ? (
            <p>
              <strong>Comments:</strong>{" "}
              {isPrintView ? (
                <p>{recommendationFormData.fta_comments}</p>
              ) : (
                <>
                  <TextArea
                    name="fta_comments"
                    onChange={handleInputChange}
                    style={{ height: "75px" }}
                    value={recommendationFormData.fta_comments}
                  />
                  <SaveCommentButton
                    handleSaveCommentClicked={handleSaveCommentClicked}
                  />
                </>
              )}
            </p>
          ) : null}
          </div>
      ) : <br/> }

      </div>
    );
  };

  const getVictimInfoBullets = (): Maybe<JSX.Element> => {
    const victims =
      interview_and_assessment &&
      interview_and_assessment.interview_data &&
      interview_and_assessment.interview_data.victim_info
        ? interview_and_assessment.interview_data.victim_info.victims
        : null;

    const wasVictimInCase =
      interview_and_assessment &&
      interview_and_assessment.interview_data &&
      interview_and_assessment.interview_data.victim_info
        ? interview_and_assessment.interview_data.victim_info.was_victim_in_case
        : null;

    return (
      <div className="checkbox-container">
        <p className="paragraph-text">
          There{" "}
          <Radio.Group
            value={
              (victims && victims.length) || wasVictimInCase === YesNo.Yes
                ? YesNo.Yes
                : YesNo.No
            }
          >
            <Radio value={YesNo.Yes}>was</Radio>
            <Radio value={YesNo.No}>was not</Radio>
          </Radio.Group>
          a victim in this matter.
        </p>
        {victims && victims.length ? (
          <p>{`Victim(s): ${victims
            .map(victim => {
              return victim.name
                ? `${victim.name.first} ${victim.name.last}`
                : "";
            })
            .join(", ")}`}</p>
        ) : null}
      </div>
    );
  };

  const isCurrentUserAdmin = user ? ADMIN_EMAILS.includes(user.email) : false;

  return (
    <div className={"recommendation-form"}>
      <CourtReportHeader booking={booking} />
      <CourtReportPreamble
        bookingDate={
          booking.booking_info.booking_date
            ? moment(booking.booking_info.booking_date)
            : null
        }
        arrestAgency={booking.booking_info.arrest_agency || ""}
        arrestedFor={booking.booking_info.arrested_for || ""}
      />

      <div className="avoid-page-break">
        <p className="section-title">INCIDENT SUMMARY</p>
        {isPrintView ? (
          <p className="paragraph-text">
            {recommendationFormData.incident_summary_notes}
          </p>
        ) : (
          <>
            <TextArea
              name="incident_summary_notes"
              value={recommendationFormData.incident_summary_notes}
              onChange={handleInputChange}
              style={{ height: "150px" }}
            />
            <SaveCommentButton
              handleSaveCommentClicked={handleSaveCommentClicked}
            />
          </>
        )}
      </div>

      <div className="avoid-page-break">
        <p className="section-title">RESIDENCY</p>
        {getResidenceBullets()}
      </div>

      <div className="avoid-page-break">
        <p className="section-title">EMPLOYMENT/PRIMARY CARETAKER:</p>
        {getEmploymentPrimaryCareTakerBullets()}
        {!isPrintView || recommendationFormData.employment_comments ? (
          <p>
            <strong>Comments:</strong>{" "}
            {isPrintView ? (
              <p>{recommendationFormData.employment_comments}</p>
            ) : (
              <>
                <TextArea
                  name="employment_comments"
                  onChange={handleInputChange}
                  style={{ height: "75px" }}
                  value={recommendationFormData.employment_comments}
                />
                <SaveCommentButton
                  handleSaveCommentClicked={handleSaveCommentClicked}
                />
              </>
            )}
          </p>
        ) : null}
      </div>

      <div className="avoid-page-break">
        <p className="section-title">MILITARY SERVICE:</p>
        <div className="checkbox-container">{getMilitaryServiceBullets()}</div>
        {militaryServiceComments && (
          <p>
            <strong>Comments:</strong> <p>{militaryServiceComments}</p>
          </p>
        )}
      </div>

      <div className="avoid-page-break">
        <p className="section-title">FAILURES TO APPEAR:</p>
        {getFTABullets()}
      </div>

      <div className="avoid-page-break">
        <p className="section-title">PENDING:</p>
        <div className="checkbox-container">
          {getPendingDispositionBullets()}
        </div>
        {!isPrintView || recommendationFormData.pending_disposition_comments ? (
          <p>
            <strong>Comments:</strong>{" "}
            {isPrintView ? (
              <p>{recommendationFormData.pending_disposition_comments}</p>
            ) : (
              <>
                <TextArea
                  name="pending_disposition_comments"
                  onChange={handleInputChange}
                  style={{ height: "75px" }}
                  value={recommendationFormData.pending_disposition_comments}
                />
                <SaveCommentButton
                  handleSaveCommentClicked={handleSaveCommentClicked}
                />
              </>
            )}
          </p>
        ) : null}
      </div>

      <div className="avoid-page-break">
        <p className="section-title">CRIMINAL HISTORY:</p>
        {getCriminalHistoryBullets()}
        <p className="section-title">
          AFS check ran on:{" "}
          <DatePicker
            style={{ width: "150px" }}
            name="afs_check_date"
            value={recommendationFormData.afs_check_date}
            onChange={(date: Maybe<Moment>) =>
              handleDatePickerChange(date, "afs_check_date")
            }
            format={dateFormatList}
          />{" "}
        </p>
        <p className="info-text">The defendant has: </p>
        <div className="checkbox-container">
          {!isPrintView ||
          !recommendationFormData.number_of_weapons_registered ||
          recommendationFormData.number_of_weapons_registered === "0" ? (
            <>
              <Checkbox
                name="no_weapons_registered"
                onChange={handleCheckboxChanged}
                checked={
                  !recommendationFormData.number_of_weapons_registered ||
                  recommendationFormData.number_of_weapons_registered === "0"
                }
              >
                No weapons registered in their name.
              </Checkbox>
              <br />
            </>
          ) : null}

          {!isPrintView ||
          (recommendationFormData.number_of_weapons_registered &&
            parseInt(recommendationFormData.number_of_weapons_registered, 10) >
              0) ? (
            <>
              <Checkbox
                name="weapons_registered"
                onChange={handleCheckboxChanged}
                checked={
                  recommendationFormData.number_of_weapons_registered &&
                  parseInt(
                    recommendationFormData.number_of_weapons_registered,
                    10
                  ) > 0
                }
              >
                The defendant has
                <Input
                  style={{ width: "50px" }}
                  name="number_of_weapons_registered"
                  value={
                    recommendationFormData.number_of_weapons_registered || ""
                  }
                  onChange={handleInputChange}
                />
                weapons registered to them.
              </Checkbox>
            </>
          ) : null}
        </div>
      </div>

      <div className="avoid-page-break">
        <p className="section-title">SUBSTANCE ABUSE:</p>
        {getSubstanceAbuseBullets()}
        {!isPrintView || recommendationFormData.substance_abuse_comments ? (
          <p>
            <strong>Comments:</strong>
            {isPrintView ? (
              <p>{recommendationFormData.substance_abuse_comments}</p>
            ) : (
              <>
                <TextArea
                  name="substance_abuse_comments"
                  style={{ height: "75px" }}
                  value={recommendationFormData.substance_abuse_comments}
                  onChange={handleInputChange}
                />
                <SaveCommentButton
                  handleSaveCommentClicked={handleSaveCommentClicked}
                />
              </>
            )}
          </p>
        ) : null}
      </div>

      <div className="avoid-page-break section-wrapper">
        <p className="section-title">ALLEDGED VICTIM INFORMATION:</p>
        <div className="checkbox-container">
          {getVictimInfoBullets()}
          {!isPrintView || recommendationFormData.victim_comments ? (
            <p>
              <strong>Comments:</strong>{" "}
              {isPrintView ? (
                <p>{recommendationFormData.victim_comments}</p>
              ) : (
                <>
                  <TextArea
                    name="victim_comments"
                    style={{ height: "75px" }}
                    value={recommendationFormData.victim_comments}
                    onChange={handleInputChange}
                  />
                  <SaveCommentButton
                    handleSaveCommentClicked={handleSaveCommentClicked}
                  />
                </>
              )}
            </p>
          ) : null}
        </div>
      </div>

      {!isPrintView || recommendationFormData.additional_information ? (
        <div className="avoid-page-break">
          <p className="section-title">ADDITIONAL INFORMATION:</p>
          {isPrintView ? (
            <p className="paragraph-text">
              {recommendationFormData.additional_information}
            </p>
          ) : (
            <>
              <TextArea
                name="additional_information"
                style={{ height: "75px" }}
                value={recommendationFormData.additional_information}
                onChange={handleInputChange}
              />
              <SaveCommentButton
                handleSaveCommentClicked={handleSaveCommentClicked}
              />
            </>
          )}
        </div>
      ) : null}

      <p className="section-title">RELEASE ASSESSMENT:</p>
      <p className="paragraph-text">
        The Virginia Pretrial Risk Assessment Instrument (VPRAI) consists of
        eight risk factors: Active Community Criminal Justice Supervision, The
        Charge is Felony Drug/Theft/Fraud, Pending Charge, Criminal History, Two
        or More Failures to Appear, Two or More Violent Convictions, Employed at
        the Time of Arrest, and History of Drug Abuse. The risk levels represent
        the likelihood of Pretrial failure, including failing to appear in court
        and risk to re-offend pending trial.
      </p>
      <div className="small-table-container avoid-page-break">
        <table>
          <tbody>
            <tr className="header-column">
              <th>Risk Level</th>
              <th>Score Range</th>
              <th>Any Failure Rate</th>
            </tr>
            <tr className={"row-grey"}>
              <td>1</td>
              <td>(0-2)</td>
              <td>6.1%</td>
            </tr>
            <tr>
              <td>2</td>
              <td>(3-4)</td>
              <td>9.8%</td>
            </tr>
            <tr className={"row-grey"}>
              <td>3</td>
              <td>(5-6)</td>
              <td>14.9%</td>
            </tr>
            <tr>
              <td>4</td>
              <td>(7-8)</td>
              <td>21.4%</td>
            </tr>
            <tr className={"row-grey"}>
              <td>5</td>
              <td>(9-10)</td>
              <td>29.3%</td>
            </tr>
            <tr>
              <td>6</td>
              <td>(11-14)</td>
              <td>37.1%</td>
            </tr>
          </tbody>
        </table>
        <p className="citation">
          Data Source: Mona J.E. Danner, Ph.D. (Old Dominion University), Marie
          VanNostrand, Ph.D. (Luminosity, Inc.), Lisa M. Spruance, M.S.
          (Independent Consultant), Race and Gender Neutral Pretrial Risk
          Assessment, Release Recommendations, and Supervision: VPRAI and Praxis
          Revised (November 2016)
        </p>
      </div>
      <p className="paragraph-text">
        {`The defendant's VPRAI score was ${score}, which categorized the defendant as
            ${failureRate} risk of Pretrial failure.`}
      </p>

      {!isPrintView || recommendationFormData.mandatory_detention ? (
        <div className="checkbox-container">
          <Checkbox
            name="mandatory_detention"
            onChange={handleCheckboxChanged}
            checked={recommendationFormData.mandatory_detention}
          >
            Mandatory detention until arraignment per 1319 PC.
          </Checkbox>
        </div>
      ) : null}

      <div className="avoid-page-break">
        <p className="section-title">RECOMMENDATION:</p>
        <p className="paragraph-text">
          Based on the Pretrial investigation and the results of the VPRAI, it
          is respectfully recommended:
        </p>

        <div className="checkbox-container">
          <Radio.Group
            onChange={e => handleRadioChanged(e, "release_recommendation")}
            value={recommendationFormData.release_recommendation}
          >
            {(!isPrintView ||
              recommendationFormData.release_recommendation ===
                ReleaseRecommendation.OrGranted) && (
              <>
                <Radio value={ReleaseRecommendation.OrGranted}>
                  <strong>OR GRANTED</strong>
                </Radio>
                <br />
              </>
            )}
            {(!isPrintView ||
              recommendationFormData.release_recommendation ===
                ReleaseRecommendation.ReleaseWithConditions) && (
              <Radio value={ReleaseRecommendation.ReleaseWithConditions}>
                <strong>PRETRIAL RELEASE WITH:</strong>
              </Radio>
            )}
            <div className="checkbox-container-indented">
              {!isPrintView ||
              recommendationFormData.supervision_and_custody ? (
                <>
                  <Checkbox
                    checked={recommendationFormData.supervision_and_custody}
                    name="supervision_and_custody"
                    onChange={handleCheckboxChanged}
                  >
                    The defendant is hereby placed under the supervision and
                    custody of the Pre-Trial Unit of the County of Marin.
                  </Checkbox>
                  <br />
                </>
              ) : null}

              {!isPrintView ||
              recommendationFormData.not_leave_state_or_residence ? (
                <>
                  <Checkbox
                    checked={
                      recommendationFormData.not_leave_state_or_residence
                    }
                    name="not_leave_state_or_residence"
                    onChange={handleCheckboxChanged}
                  >
                    Defendant shall not leave the State of California nor move
                    his/her residence from the County in which he/she resides,
                    without prior written approval of the Pre- Trial Unit or the
                    Court.
                  </Checkbox>
                  <br />
                </>
              ) : null}

              {!isPrintView ||
              recommendationFormData.notify_change_employment_or_residence ===
                true ? (
                <>
                  {" "}
                  <Checkbox
                    checked={
                      recommendationFormData.notify_change_employment_or_residence
                    }
                    name="notify_change_employment_or_residence"
                    onChange={handleCheckboxChanged}
                  >
                    The defendant shall notify the Pre-Trial Unit immediately of
                    any change in employment or place of residence.
                  </Checkbox>
                  <br />
                </>
              ) : null}

              {!isPrintView ||
              recommendationFormData.report_regularly_pretrial ? (
                <>
                  <Checkbox
                    checked={recommendationFormData.report_regularly_pretrial}
                    name="report_regularly_pretrial"
                    onChange={handleCheckboxChanged}
                  >
                    The defendant shall report regularly as directed by the
                    Pre-Trial Unit.
                  </Checkbox>
                  <br />
                </>
              ) : null}

              {!isPrintView || recommendationFormData.report_to_probation ? (
                <>
                  <Checkbox
                    checked={recommendationFormData.report_to_probation}
                    name="report_to_probation"
                    onChange={handleCheckboxChanged}
                  >
                    Report to the Probation Department upon release.
                  </Checkbox>
                  <br />
                </>
              ) : null}

              {!isPrintView || recommendationFormData.tcemp ? (
                <>
                  <Checkbox
                    checked={recommendationFormData.tcemp}
                    name="tcemp"
                    onChange={handleCheckboxChanged}
                  >
                    <strong>TCEMP</strong> Defendant to seek and maintain
                    full-time employment/ education/vocational training, as
                    directed by the Probation Officer.
                  </Checkbox>
                  <br />
                </>
              ) : null}

              {!isPrintView || recommendationFormData.tcv3a ? (
                <>
                  {" "}
                  <Checkbox
                    checked={recommendationFormData.tcv3a}
                    name="tcv3a"
                    onChange={handleCheckboxChanged}
                  >
                    <strong>TCV3A</strong> Defendant shall submit to search and
                    seizure of his/her person, vehicle, residence, or any other
                    property under his/her control, at any time of the day or
                    night, by any Probation Officer or Peace Officer, with or
                    without probable cause, with or without a warrant.
                  </Checkbox>
                  <br />
                </>
              ) : null}

              {!isPrintView || recommendationFormData.curfew ? (
                <>
                  {" "}
                  <Checkbox
                    checked={recommendationFormData.curfew}
                    name="curfew"
                    onChange={handleCheckboxChanged}
                  >
                    Curfew Monitoring (EM)
                  </Checkbox>
                  <br />
                </>
              ) : null}

              {!isPrintView || recommendationFormData.location_tracking ? (
                <>
                  <Checkbox
                    checked={recommendationFormData.location_tracking}
                    name="location_tracking"
                    onChange={handleCheckboxChanged}
                  >
                    Continuous Location Tracking (GPS)
                  </Checkbox>
                  <br />
                </>
              ) : null}

              {!isPrintView || recommendationFormData.alcohol_detection ? (
                <>
                  <Checkbox
                    checked={recommendationFormData.alcohol_detection}
                    name="alcohol_detection"
                    onChange={handleCheckboxChanged}
                  >
                    Transdermal Alcohol Detection (TAD)
                  </Checkbox>
                  <br />
                </>
              ) : null}

              {!isPrintView ||
              recommendationFormData.alcohol_detection_with_curfew == true ? (
                <>
                  <Checkbox
                    checked={
                      recommendationFormData.alcohol_detection_with_curfew
                    }
                    name="alcohol_detection_with_curfew"
                    onChange={handleCheckboxChanged}
                  >
                    Transdermal Alcohol Detection (TAD) with Curfew Monitoring
                    (RF)
                  </Checkbox>
                  <br />
                </>
              ) : null}

              {!isPrintView ||
              recommendationFormData.follow_court_protective_orders ? (
                <>
                  <Checkbox
                    checked={
                      recommendationFormData.follow_court_protective_orders
                    }
                    name="follow_court_protective_orders"
                    onChange={handleCheckboxChanged}
                  >
                    Follow any and all Court ordered protective orders.
                  </Checkbox>
                  <br />
                </>
              ) : null}

              {!isPrintView || recommendationFormData.tcaap ? (
                <>
                  <Checkbox
                    checked={recommendationFormData.tcaap}
                    name="tcaap"
                    onChange={handleCheckboxChanged}
                  >
                    TCAAP Attend AA meetings as directed by Probation Officer.
                  </Checkbox>
                  <br />
                </>
              ) : null}

              {!isPrintView || recommendationFormData.tcbaf ? (
                <>
                  {" "}
                  <Checkbox
                    checked={recommendationFormData.tcbaf}
                    name="tcbaf"
                    onChange={handleCheckboxChanged}
                  >
                    TCBAF Defendant to submit to chemical testing at the request
                    of any Probation Officer or Peace Officer to determine
                    DRUG/ALCOHOL content.
                  </Checkbox>
                  <br />
                </>
              ) : null}

              {!isPrintView || recommendationFormData.tcnax ? (
                <>
                  <Checkbox
                    checked={recommendationFormData.tcnax}
                    name="tcnax"
                    onChange={handleCheckboxChanged}
                  >
                    <strong>TCNAX</strong> Shall not possess, abuse, consume or
                    transport any alcohol, intoxicants, non-prescribed or
                    illegal drugs, or associated paraphernalia, including
                    medicinal marijuana.
                  </Checkbox>
                  <br />
                </>
              ) : null}

              {!isPrintView || recommendationFormData.tcnae ? (
                <>
                  <Checkbox
                    checked={recommendationFormData.tcnae}
                    name="tcnae"
                    onChange={handleCheckboxChanged}
                  >
                    <strong>TCNAE</strong> Defendant shall not use, abuse,
                    consume, or transport alcohol.
                  </Checkbox>
                  <br />
                </>
              ) : null}

              {!isPrintView || recommendationFormData.tcgnx ? (
                <>
                  <Checkbox
                    checked={recommendationFormData.tcgnx}
                    name="tcgnx"
                    onChange={handleCheckboxChanged}
                  >
                    <strong>TCGNX</strong> Shall not be a gang member, shall not
                    associate with gangs or be where gangs congregate, or
                    wear/exhibit clothing. items, or emblems associated with
                    gang membership.
                  </Checkbox>
                  <br />
                </>
              ) : null}

              {!isPrintView || recommendationFormData.tcgwr ? (
                <>
                  <Checkbox
                    checked={recommendationFormData.tcgwr}
                    name="tcgwr"
                    onChange={handleCheckboxChanged}
                  >
                    <strong>TCGWR</strong> Defendant shall not wear clothing,
                    nor wear or display items or emblems, nor acquire and/or
                    display tattoos known or reasonably understood or believed
                    by him/her to be associated with or symbolic of gang
                    membership, including clothing, tattoos and items
                    specifically so identified for him/her by the Probation
                    Officer.
                  </Checkbox>
                  <br />
                </>
              ) : null}

              {!isPrintView ||
              recommendationFormData.follow_court_probation_conditions ? (
                <>
                  <Checkbox
                    checked={
                      recommendationFormData.follow_court_probation_conditions
                    }
                    name="follow_court_probation_conditions"
                    onChange={handleCheckboxChanged}
                  >
                    Follow all Court ordered conditions of your
                    Supervised/Conditional Probation.
                  </Checkbox>
                  <br />
                </>
              ) : null}

              {!isPrintView || recommendationFormData.pretrial_program ? (
                <Checkbox
                  checked={recommendationFormData.pretrial_program}
                  name="pretrial_program"
                  onChange={handleCheckboxChanged}
                >
                  You are being placed on the Marin County Pretrial Program. As
                  part of that program you are to report to the Marin County
                  Superior Court at the direction of the Probation Department.
                  Any violations of the program may result in a notice being
                  filed with the court and/or your arrest for any violations.
                </Checkbox>
              ) : null}
            </div>
            {(!isPrintView ||
              recommendationFormData.release_recommendation ===
                ReleaseRecommendation.DenyRelease) && (
              <Radio value={ReleaseRecommendation.DenyRelease}>
                <strong>
                  THE DEFENDANT REMAIN IN CUSTODY DUE TO THE FOLLOWING:
                </strong>
              </Radio>
            )}
            <div className="checkbox-container-indented">
              {!isPrintView ||
              recommendationFormData.high_risk_to_fail_pretrial_release ? (
                <>
                  {" "}
                  <Checkbox
                    checked={
                      recommendationFormData.high_risk_to_fail_pretrial_release
                    }
                    name="high_risk_to_fail_pretrial_release"
                    onChange={handleCheckboxChanged}
                  >
                    High risk to fail Pretrial release
                  </Checkbox>
                  <br />
                </>
              ) : null}

              {!isPrintView ||
              recommendationFormData.vprai_override_public_safety ? (
                <>
                  <Checkbox
                    checked={
                      recommendationFormData.vprai_override_public_safety
                    }
                    name="vprai_override_public_safety"
                    onChange={handleCheckboxChanged}
                  >
                    VPRAI override for public safety
                  </Checkbox>
                  <br />
                </>
              ) : null}

              {!isPrintView ||
              recommendationFormData.vprai_override_public_safety_comments ? (
                <p>
                  <strong>Comments:</strong>{" "}
                  {isPrintView ? (
                    <p>
                      {
                        recommendationFormData.vprai_override_public_safety_comments
                      }
                    </p>
                  ) : (
                    <>
                      <TextArea
                        name="vprai_override_public_safety_comments"
                        onChange={handleInputChange}
                        style={{ height: "75px" }}
                        value={
                          recommendationFormData.vprai_override_public_safety_comments
                        }
                      />
                      <SaveCommentButton
                        handleSaveCommentClicked={handleSaveCommentClicked}
                      />
                    </>
                  )}
                </p>
              ) : null}

              {!isPrintView ||
              recommendationFormData.vprai_override_victim_residence ? (
                <Checkbox
                  checked={
                    recommendationFormData.vprai_override_victim_residence
                  }
                  name="vprai_override_victim_residence"
                  onChange={handleCheckboxChanged}
                >
                  VPRAI override due to defendant and victim residing at the
                  same address; the defendant should be considered for release
                  to Pretrial Monitoring with the below standard release
                  conditions if other living arrangements are made.
                </Checkbox>
              ) : null}
            </div>

            {!isPrintView ||
            recommendationFormData.other_recommendation_notes ? (
              <>
                {isPrintView ? (
                  <strong>Other Recommendations:</strong>
                ) : (
                  <Checkbox
                    checked={recommendationFormData.other_recommendation_notes}
                    name="other_recommendations"
                    onChange={handleCheckboxChanged}
                  >
                    Other Recommendations:
                  </Checkbox>
                )}
              </>
            ) : null}

            {!isPrintView ||
            recommendationFormData.other_recommendation_notes ? (
              <>
                {isPrintView ? (
                  <p style={{ color: "black" }}>
                    {recommendationFormData.other_recommendation_notes}
                  </p>
                ) : (
                  <>
                    <TextArea
                      style={{ height: "75px" }}
                      name={"other_recommendation_notes"}
                      value={recommendationFormData.other_recommendation_notes}
                      onChange={handleInputChange}
                    />
                    <SaveCommentButton
                      handleSaveCommentClicked={handleSaveCommentClicked}
                    />
                  </>
                )}
              </>
            ) : null}
          </Radio.Group>
        </div>
      </div>

      <SignatureLine
        isPrintView={!!isPrintView}
        onChange={handleInputChange}
        formData={recommendationFormData}
        isCurrentUserAdmin={isCurrentUserAdmin}
      />
      {recommendationFormData.release_recommendation !==
        ReleaseRecommendation.ReleaseWithConditions &&
        isPrintView && <ConditionsOfRelease />}
    </div>
  );
}

function getRecommendationFormInputFromFields(
  formData: RecommendationFormData
): RecommendationFormInput {
  return {
    has_verified_employment_information: booleanToYesNo(
      formData.has_verified_employment_information
    ),
    employment_comments: formData.employment_comments,
    pending_disposition: booleanToYesNo(formData.pending_disposition),
    pending_disposition_comments: formData.pending_disposition_comments,
    fta_comments: formData.fta_comments,
    vprai_override_public_safety_comments:
      formData.vprai_override_public_safety_comments,
    incident_summary_notes: formData.incident_summary_notes,
    mandatory_detention: booleanToYesNo(formData.mandatory_detention),
    additional_information: formData.additional_information,
    release_recommendation: formData.release_recommendation,
    conditions_of_release: {
      supervision_and_custody: booleanToYesNo(formData.supervision_and_custody),
      not_leave_state_or_residence: booleanToYesNo(
        formData.not_leave_state_or_residence
      ),
      notify_change_employment_or_residence: booleanToYesNo(
        formData.notify_change_employment_or_residence
      ),
      report_regularly_pretrial: booleanToYesNo(
        formData.report_regularly_pretrial
      ),
      report_to_probation: booleanToYesNo(formData.report_to_probation),
      tcemp: booleanToYesNo(formData.tcemp),
      tcv3a: booleanToYesNo(formData.tcv3a),
      curfew: booleanToYesNo(formData.curfew),
      location_tracking: booleanToYesNo(formData.location_tracking),
      alcohol_detection: booleanToYesNo(formData.alcohol_detection),
      alcohol_detection_with_curfew: booleanToYesNo(
        formData.alcohol_detection_with_curfew
      ),
      follow_court_protective_orders: booleanToYesNo(
        formData.follow_court_protective_orders
      ),
      tcaap: booleanToYesNo(formData.tcaap),
      tcbaf: booleanToYesNo(formData.tcbaf),
      tcnax: booleanToYesNo(formData.tcnax),
      tcnae: booleanToYesNo(formData.tcnae),
      tcgnx: booleanToYesNo(formData.tcgnx),
      tcgwr: booleanToYesNo(formData.tcgwr),
      follow_court_probation_conditions: booleanToYesNo(
        formData.follow_court_probation_conditions
      ),
      pretrial_program: booleanToYesNo(formData.pretrial_program)
    },
    reasons_against_release: {
      high_risk_to_fail_pretrial_release: booleanToYesNo(
        formData.high_risk_to_fail_pretrial_release
      ),
      vprai_override_public_safety: booleanToYesNo(
        formData.vprai_override_public_safety
      ),
      vprai_override_victim_residence: booleanToYesNo(
        formData.vprai_override_victim_residence
      )
    },
    criminal_history: {
      two_or_more_violent_convictions: booleanToYesNo(
        formData.two_or_more_violent_convictions
      ),
      one_or_more_misdemeanor_or_felony_convictions: booleanToYesNo(
        formData.one_or_more_misdemeanor_or_felony_convictions
      ),
      no_prior_criminal_history: booleanToYesNo(
        formData.no_prior_criminal_history
      )
    },
    afs_info: {
      afs_check_date: formData.afs_check_date
        ? moment(formData.afs_check_date).toISOString()
        : null,
      number_of_weapons_registered: formData.number_of_weapons_registered
        ? parseInt(formData.number_of_weapons_registered, 10)
        : null
    },
    substance_abuse_comments: formData.substance_abuse_comments,
    other_recommendation_notes: formData.other_recommendation_notes,
    victim_comments: formData.victim_comments || "",
    signature_probation_supervisor:
      formData.signature_probation_supervisor || "",
    signature_team_member: formData.signature_team_member || ""
  };
}

function getRecommendationFormDataFromBooking(
  booking: Booking
): Maybe<RecommendationFormData> {
  if (!booking.recommendation_form) {
    return null;
  }
  return {
    has_verified_employment_information: yesNoToBoolean(
      booking.recommendation_form.has_verified_employment_information
    ),
    employment_comments: booking.recommendation_form.employment_comments || "",
    pending_disposition: yesNoToBoolean(
      booking.recommendation_form.pending_disposition
    ),
    pending_disposition_comments:
      booking.recommendation_form.pending_disposition_comments || "",
    fta_comments:
      booking.recommendation_form.fta_comments || "",
    vprai_override_public_safety_comments:
      booking.recommendation_form.vprai_override_public_safety_comments || "",
    incident_summary_notes:
      booking.recommendation_form.incident_summary_notes || "",
    mandatory_detention: yesNoToBoolean(
      booking.recommendation_form.mandatory_detention
    ),
    additional_information:
      booking.recommendation_form.additional_information || "",
    release_recommendation:
      booking.recommendation_form.release_recommendation ||
      ReleaseRecommendation.OrGranted,
    supervision_and_custody: yesNoToBoolean(
      booking.recommendation_form.conditions_of_release.supervision_and_custody
    ),
    not_leave_state_or_residence: yesNoToBoolean(
      booking.recommendation_form.conditions_of_release
        .not_leave_state_or_residence
    ),
    notify_change_employment_or_residence: yesNoToBoolean(
      booking.recommendation_form.conditions_of_release
        .notify_change_employment_or_residence
    ),
    report_regularly_pretrial: yesNoToBoolean(
      booking.recommendation_form.conditions_of_release
        .report_regularly_pretrial
    ),
    report_to_probation: yesNoToBoolean(
      booking.recommendation_form.conditions_of_release.report_to_probation
    ),
    tcemp: yesNoToBoolean(
      booking.recommendation_form.conditions_of_release.tcemp
    ),
    tcv3a: yesNoToBoolean(
      booking.recommendation_form.conditions_of_release.tcv3a
    ),
    curfew: yesNoToBoolean(
      booking.recommendation_form.conditions_of_release.curfew
    ),
    location_tracking: yesNoToBoolean(
      booking.recommendation_form.conditions_of_release.location_tracking
    ),
    alcohol_detection: yesNoToBoolean(
      booking.recommendation_form.conditions_of_release.alcohol_detection
    ),
    alcohol_detection_with_curfew: yesNoToBoolean(
      booking.recommendation_form.conditions_of_release
        .alcohol_detection_with_curfew
    ),
    follow_court_protective_orders: yesNoToBoolean(
      booking.recommendation_form.conditions_of_release
        .follow_court_protective_orders
    ),
    tcaap: yesNoToBoolean(
      booking.recommendation_form.conditions_of_release.tcaap
    ),
    tcbaf: yesNoToBoolean(
      booking.recommendation_form.conditions_of_release.tcbaf
    ),
    tcnax: yesNoToBoolean(
      booking.recommendation_form.conditions_of_release.tcnax
    ),
    tcnae: yesNoToBoolean(
      booking.recommendation_form.conditions_of_release.tcnae
    ),
    tcgnx: yesNoToBoolean(
      booking.recommendation_form.conditions_of_release.tcgnx
    ),
    tcgwr: yesNoToBoolean(
      booking.recommendation_form.conditions_of_release.tcgwr
    ),
    follow_court_probation_conditions: yesNoToBoolean(
      booking.recommendation_form.conditions_of_release
        .follow_court_probation_conditions
    ),
    pretrial_program: yesNoToBoolean(
      booking.recommendation_form.conditions_of_release.pretrial_program
    ),
    high_risk_to_fail_pretrial_release: yesNoToBoolean(
      booking.recommendation_form.reasons_against_release
        .high_risk_to_fail_pretrial_release
    ),
    vprai_override_public_safety: yesNoToBoolean(
      booking.recommendation_form.reasons_against_release
        .vprai_override_public_safety
    ),
    vprai_override_victim_residence: yesNoToBoolean(
      booking.recommendation_form.reasons_against_release
        .vprai_override_victim_residence
    ),
    other_recommendation_notes:
      booking.recommendation_form.other_recommendation_notes || "",
    one_or_more_misdemeanor_or_felony_convictions: yesNoToBoolean(
      booking.recommendation_form.criminal_history
        .one_or_more_misdemeanor_or_felony_convictions
    ),
    two_or_more_violent_convictions: yesNoToBoolean(
      booking.recommendation_form.criminal_history
        .two_or_more_violent_convictions
    ),
    number_of_weapons_registered: booking.recommendation_form.afs_info
      .number_of_weapons_registered
      ? booking.recommendation_form.afs_info.number_of_weapons_registered.toString()
      : "",
    arrest_reason: "",
    afs_check_date: booking.recommendation_form.afs_info.afs_check_date
      ? moment(booking.recommendation_form.afs_info.afs_check_date)
      : undefined,
    arrest_agency: "",
    booking_date: undefined,
    no_victim: false,
    victim_and_contact: false,
    victim_comments: booking.recommendation_form.victim_comments || "",
    other_recommendations: false,
    victim_no_contact: false,
    victim_name: "",
    remain_in_custody: false,
    no_prior_criminal_history: false,
    substance_abuse_comments:
      booking.recommendation_form.substance_abuse_comments || "",
    signature_probation_supervisor:
      booking.recommendation_form.signature_probation_supervisor || "",
    signature_team_member:
      booking.recommendation_form.signature_team_member || ""
  };
}

export function InternalNote(props: InternalNoteProps) {
  const { note } = props;
  return (
    <div className="vertical-margin">
      <Row gutter={24} type="flex" justify="start">
        <Col span={18}>{note.text}</Col>
        <Col span={6}>
          <div>
            {note.created_by &&
              note.created_by.name &&
              note.created_by.name.first}{" "}
            {note.created_by &&
              note.created_by.name &&
              note.created_by.name.last}
          </div>
          <div>
            {note.created_at
              ? moment(note.created_at).format("MM/DD/YYYY h:mm a")
              : ""}
          </div>
        </Col>
      </Row>
    </div>
  );
}

function SaveCommentButton(props: {
  handleSaveCommentClicked: (e: React.MouseEvent) => void;
}) {
  return (
    <div className="save-button-row">
      <Button type="primary" onClick={props.handleSaveCommentClicked}>
        Save
      </Button>
    </div>
  );
}

export function InternalNotesSection(props: InternalNotesSectionProps) {
  const { booking } = props;
  const existingNotes =
    booking &&
    booking.recommendation_form &&
    booking.recommendation_form.internal_notes
      ? booking.recommendation_form.internal_notes
      : [];
  const [noteFormData, setNoteFormData] = useState("");
  return (
    <>
      <Title level={4}>Internal Notes</Title>
      {existingNotes.map(
        (note, idx) =>
          note && <InternalNote note={note} key={`internal_note_${idx}`} />
      )}
      <div className="vertical-margin">
        <TextArea
          style={{ height: "75px" }}
          name="internal_notes"
          value={noteFormData}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setNoteFormData(e.target.value);
          }}
        />
      </div>
      <div className="vertical-margin">
        <Button
          disabled={noteFormData.length === 0}
          onClick={async () => {
            await addNoteToBookingRecommendationForm({
              booking_id: booking.id,
              text: noteFormData
            });
            setNoteFormData("");
          }}
        >
          Add Note
        </Button>
      </div>
    </>
  );
}

function ActionHistoryItem(props: ActionHistoryItemProps) {
  const { actionStr, caseManager, dt } = props;
  return (
    <div className="vertical-margin">
      <Text strong>{actionStr}</Text>
      <Text> by </Text>
      <Text strong>
        {caseManager.name.first} {caseManager.name.last}
      </Text>
      {dt && <Text> at {moment(dt).format("MM/DD/YYYY h:mm a")}</Text>}
    </div>
  );
}

// TODO: actually store admin permissions
// for now, just hardcoding ppl w/ admin permissions
export const ADMIN_EMAILS = [
  "sina@joinpromise.com",
  "fpeterson@marincounty.org",
  "akrupinsky@marincounty.org",
  "mdavis@marincounty.org",
  "hdamato@marincounty.org",
  "omar@joinpromise.com",
  "cathy@joinpromise.com",
  "jvirzi@marincounty.org",
  "mtran@marincounty.org",
  "dcarmona@marincounty.org",
  "tcreighton@marincounty.org",
  "hmoreano@marincounty.org",
  "samador@marincounty.org",
  "ctom@marincounty.org",
  "twright@marincounty.org"
];

type AllActionSectionProps = ActionSectionProps & WithUserProps;

export const ActionSection = withUser<
  ActionSectionProps,
  AllActionSectionProps
>(ActionSectionComponent);

function ActionSectionComponent(props: AllActionSectionProps) {
  const {
    booking: {
      id,
      recommendation_form: recommendationForm,
      recommendation_review_status: recReviewStatus,
      prospect_status: prospectStatus
    },
    getRecommendationFormInput,
    userContext: { user },
    renderPrintButton,
    additionalSave,
    setIsCurrentUserAdmin
  } = props;

  const handleSaveRecommendationClicked = async () => {
    if (additionalSave) {
      await additionalSave();
    }
    await updateBookingRecommendationForm({
      booking_id: id,
      recommendation_form: getRecommendationFormInput()
    });
  };

  const handleSubmitForReviewClicked = async () => {
    if (additionalSave) {
      await additionalSave();
    }
    await submitBookingRecommendationFormForReview({
      booking_id: id,
      recommendation_form: getRecommendationFormInput()
    });
  };

  const handleReviewApproveClicked = async () => {
    if (additionalSave) {
      await additionalSave();
    }
    await reviewBookingRecommendationForm({
      booking_id: id,
      recommendation_form: getRecommendationFormInput(),
      is_approved: true
    });
  };

  const handleReviewRejectClicked = async () => {
    if (additionalSave) {
      await additionalSave();
    }
    await reviewBookingRecommendationForm({
      booking_id: id,
      recommendation_form: getRecommendationFormInput(),
      is_approved: false
    });
  };

  const handleSubmitClicked = async () => {
    await submitBookingRecommendationForm({
      booking_id: id
    });
  };

  const handleRevokeApprovalClicked = async () => {
    await revokeBookingRecommendationApproval({
      booking_id: id
    });
  };

  const isCurrentUserAdmin = user ? ADMIN_EMAILS.includes(user.email) : false;
  // dont want to repeat code so using callback
  if (setIsCurrentUserAdmin) {
    setIsCurrentUserAdmin(isCurrentUserAdmin);
  }

  const printButton = renderPrintButton();

  let statusString = "";
  let buttons: JSX.Element[] = [];
  // different sets of buttons are relevant for different
  // recommendation form states
  if (prospectStatus === ProspectStatus.PendingCourtDecision) {
    statusString = "Submitted";
    buttons = [printButton];
  }
  if (recReviewStatus === RecommendationReviewStatus.NotReadyForReview) {
    statusString = "In Progress";
    buttons = [
      <Button type="primary" onClick={handleSubmitForReviewClicked}>
        Submit for Review
      </Button>,
      <Button onClick={handleSaveRecommendationClicked}>Save Draft</Button>,
      printButton
    ];
  }
  if (recReviewStatus === RecommendationReviewStatus.PendingReview) {
    statusString = "Needs Review";
    buttons = [
      <Button
        type="primary"
        disabled={!isCurrentUserAdmin}
        onClick={handleReviewApproveClicked}
      >
        Approve
      </Button>,
      <Button
        type="primary"
        disabled={!isCurrentUserAdmin}
        onClick={handleReviewRejectClicked}
      >
        Request Changes
      </Button>,
      printButton
    ];
  }
  if (recReviewStatus === RecommendationReviewStatus.Rejected) {
    statusString = "Needs Changes";
    buttons = [
      <Button type="primary" onClick={handleSubmitForReviewClicked}>
        Resubmit for Review
      </Button>,
      <Button type="danger" onClick={handleRevokeApprovalClicked}>
        Revoke Approval
      </Button>,
      printButton
    ];
  }
  if (recReviewStatus === RecommendationReviewStatus.Approved) {
    statusString = "Ready to Submit";
    buttons = [
      <Button type="primary" onClick={handleSubmitClicked}>
        Mark as Submitted
      </Button>,
      <Button type="danger" onClick={handleRevokeApprovalClicked}>
        Revoke Approval
      </Button>,
      printButton
    ];
  }

  return (
    <>
      <Title level={4}>Recommendation Form Status: {statusString}</Title>
      {recommendationForm && (
        <div className="vertical-margin">
          {recommendationForm.submitted_for_review_by && (
            <ActionHistoryItem
              actionStr={"Submitted for review"}
              caseManager={recommendationForm.submitted_for_review_by}
              dt={recommendationForm.submitted_for_review_at}
            />
          )}
          {recommendationForm.reviewed_by && (
            <ActionHistoryItem
              actionStr={
                recReviewStatus === RecommendationReviewStatus.Approved
                  ? "Approved"
                  : "Changes requested"
              }
              caseManager={recommendationForm.reviewed_by}
              dt={recommendationForm.reviewed_at}
            />
          )}
          {recommendationForm.completed_by && (
            <ActionHistoryItem
              actionStr={"Marked as submitted"}
              caseManager={recommendationForm.completed_by}
              dt={recommendationForm.completed_at}
            />
          )}
        </div>
      )}
      <div>{buttons}</div>
    </>
  );
}

export function RecommendationFormPage(props: { booking: Booking }) {
  const { booking } = props;

  const initialData = getRecommendationFormDataFromBooking(booking);

  const [recommendationFormData, setRecommendationFormData] = useState({
    ...initialData
  } as RecommendationFormData);

  const handleCheckboxChanged = (e: CheckboxChangeEvent) => {
    const { name, checked } = e.target;
    setRecommendationFormData({
      ...recommendationFormData,
      [name as string]: checked
    });
  };

  const handleRadioChanged = (e: RadioChangeEvent, field: string) => {
    setRecommendationFormData({
      ...recommendationFormData,
      [field]: e.target.value
    });
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setRecommendationFormData({ ...recommendationFormData, [name]: value });
  };

  const handleDatePickerChange = (date: Maybe<Moment>, name: string) => {
    setRecommendationFormData({ ...recommendationFormData, [name]: date });
  };

  const handleSaveCommentClicked = async () => {
    await updateBookingRecommendationForm({
      booking_id: booking.id,
      recommendation_form: getRecommendationFormInputFromFields(
        recommendationFormData
      )
    });
  };

  return (
    <div style={{ padding: "20px" }}>
      <RecommendationForm
        booking={booking}
        handleDatePickerChange={handleDatePickerChange}
        handleInputChange={handleInputChange}
        handleCheckboxChanged={handleCheckboxChanged}
        recommendationFormData={recommendationFormData}
        handleRadioChanged={handleRadioChanged}
        isPrintView={false}
        handleSaveCommentClicked={handleSaveCommentClicked}
      />
      <div className="form-container recommendation-actions">
        <InternalNotesSection booking={booking} />
        <ActionSection
          booking={booking}
          getRecommendationFormInput={() =>
            getRecommendationFormInputFromFields(recommendationFormData)
          }
          renderPrintButton={() => (
            <Button>
              <a
                href={`/${ROOT_PATH}/print_recommendation/${encodeURIComponent(
                  booking.id
                )}`}
                target="_blank"
              >
                Print
              </a>
            </Button>
          )}
        />
      </div>
    </div>
  );
}

export function RecommendationFormPrintContainer(props: { booking: Booking }) {
  const { booking } = props;

  const recommendationFormData = getRecommendationFormDataFromBooking(
    booking
  ) as RecommendationFormData;

  const handleCheckboxChanged = (_e: CheckboxChangeEvent) => {
    return null;
  };

  const handleRadioChanged = (_e: RadioChangeEvent, _field: string) => {
    return null;
  };

  const handleSaveCommentClicked = (_e: React.MouseEvent) => {
    return null;
  };

  const handleInputChange = (
    _e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    return null;
  };

  const handleDatePickerChange = (_date: Maybe<Moment>, _name: string) => {
    return null;
  };

  return (
    <RecommendationForm
      booking={booking}
      handleDatePickerChange={handleDatePickerChange}
      handleInputChange={handleInputChange}
      handleCheckboxChanged={handleCheckboxChanged}
      recommendationFormData={recommendationFormData}
      handleRadioChanged={handleRadioChanged}
      isPrintView={true}
      handleSaveCommentClicked={handleSaveCommentClicked}
    />
  );
}
