import React from "react";
import moment from "moment";
import { Level } from "react-bulma-components";
import { Tag } from "antd";
import { ParticipantAssessmentResultPlusParticipantName } from "./types";
import "./ParticipantAssessmentContainer.scss";
import { ParticipantAssessmentResultStatus } from "../../graphql-types";

export function AssessmentFormHeader(
  props: ParticipantAssessmentResultPlusParticipantName
) {
  const {
    created_at: createdAt,
    updated_at: updatedAt,
    scored_by: scoredBy,
    status,
    case_number: caseNumber,
  } = props;
  return (
    <Level>
      <Level.Side align="left">
        <div style={{ marginLeft: 10 }}>
          <h1>
            <strong>Case Number: </strong>
            {caseNumber}
          </h1>
          <h1>
            <strong>{`Assessment Type: VPRAI`}</strong>
          </h1>
        </div>
        <div style={{ marginLeft: 15 }}>
          <h1>
            <strong>Scored by: </strong>
            {scoredBy || "N/A"}
          </h1>
          <h1>
            <strong>Created at: </strong>
            {updatedAt
              ? moment(updatedAt).format("MM/DD/YYYY h:mm a")
              : moment(createdAt).format("MM/DD/YYYY h:mm a")}
          </h1>
        </div>
      </Level.Side>
      <Level.Side align="right">
        <Tag
          color={
            status === ParticipantAssessmentResultStatus.Finalized
              ? "red"
              : "green"
          }
        >
          {status.toUpperCase()}
        </Tag>
      </Level.Side>
    </Level>
  );
}
