import React, { Component } from "react";
import { saveAs } from "file-saver";
import fetcher from "../../lib/fetcher/fetcher";
import sniffProject from "../../lib/sniffProject";

interface Props {
  children: any;
}

const stripScripts = function (s: string) {
  var div = document.createElement("div");
  div.innerHTML = s;
  var scripts = div.getElementsByTagName("script");
  var i = scripts.length;
  while (i--) {
    scripts[i].parentNode!.removeChild(scripts[i]);
  }
  return div.innerHTML;
};

export class DownloadPDF extends Component<Props> {
  _downloadPDF = async (id?: string, name?: string): Promise<void> => {
    const htmlContent =
      id && document.getElementById(id)
        ? (document.getElementById(id) as any).innerHTML
        : document.body.innerHTML;

    const cleanedHtmlContent = stripScripts(htmlContent);
    const css = this._getCSS();

    console.log("css");
    console.log(css);
    console.log("css");

    const projectId = sniffProject();

    try {
      const data = await fetcher(
        `https://us-central1-${projectId}.cloudfunctions.net/firestorePdfFormatterServer/export/pdf`,
        { html: cleanedHtmlContent, css: css },
        "POST",
        true,
        {
          Accept: "application/pdf",
        }
      );

      const bufferData = await data.arrayBuffer();

      const blob = new Blob([bufferData], { type: "application/pdf" });

      saveAs(blob, `${name || "Promise Analytics Report"}.pdf`);
    } catch (error) {
      console.error("Failed to download specified PDF.");
      alert("An error occurred while generating your pdf document.");
    }
  };

  _getCSS = (): string => {
    if (!document) {
      return "";
    }
    if (!document.head) {
      return "";
    }
    const stylesheets = Array.from(document.styleSheets) as CSSStyleSheet[];

    if (!stylesheets) {
      return "";
    }

    // horrible stuff, but something about antd messes up our
    // print styles, and haven't the time to figure out what
    const styleSheetsLessAntd = stylesheets.filter((stylesheet) => {
      if (!stylesheet.cssRules) {
        return false;
      }

      const cssRulesArray = Array.from(stylesheet.cssRules);

      return !cssRulesArray.some((cssRule) => {
        if (cssRule.cssText) {
          return cssRule.cssText.includes(".ant") ? true : false;
        }
        return false;
      });
    });

    const rules = styleSheetsLessAntd.reduce(
      (acc: string, stylesheet: CSSStyleSheet) => {
        if (!stylesheet.cssRules) {
          return acc;
        }

        const cssRulesArray = Array.from(stylesheet.cssRules);
        const ruleStringCombined = cssRulesArray.reduce(
          (innerAcc: string, cssRule: CSSRule) => {
            if (!cssRule.cssText) {
              return innerAcc;
            }
            return innerAcc + "\n" + cssRule.cssText;
          },
          ""
        );

        if (!ruleStringCombined) {
          return acc;
        }

        acc = acc + "\n" + ruleStringCombined;
        return acc;
      },
      ""
    );

    return rules;
  };

  render() {
    return (
      <div>
        {this.props.children({
          downloadPDF: (id: string, name: string) =>
            this._downloadPDF(id, name),
        })}
      </div>
    );
  }
}
