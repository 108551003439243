import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "../shared/lib/graphql/commitMutation";
import {
  MutationAddBookingArgs,
  MutationStartBookingInterviewAndAssessmentArgs,
  Booking,
  MutationUpdateBookingInterviewAndAssessmentArgs,
  MutationDeclineBookingInterviewArgs,
  MutationUpdateBookingInterviewDataArgs,
  MutationUpdateBookingRecommendationFormArgs,
  MutationSubmitBookingCourtMemoArgs,
  MutationSubmitBookingRecommendationFormForReviewArgs,
  MutationReviewBookingRecommendationFormArgs,
  MutationSubmitBookingRecommendationFormArgs,
  MutationAddNoteToBookingRecommendationFormArgs,
  MutationUpdateBookingInfoDataArgs,
  MutationUpdateBookingCourtDecisionDataArgs,
  MutationArchiveBookingArgs,
  MutationCreateParticipantFromBookingArgs,
  MutationAddBookingReleaseArgs,
} from "../graphql-types";
import { checkMutationReturn } from "./util";
import { RecordSourceSelectorProxy } from "relay-runtime";

export const addBooking = (
  variables: MutationAddBookingArgs
): Promise<{ addBooking: { booking: Booking } }> => {
  return commitMutation<
    MutationAddBookingArgs,
    { addBooking: { booking: Booking } }
  >(
    {
      mutation: graphql`
        mutation bookingsAddBookingMutation(
          $id: String!
          $booking_defendent: BookingDefendentInput!
          $booking_info: BookingInfoInput!
          $booking_charges: [BookingChargeInput!]
          $booking_releases: [BookingReleaseInput!]
          $classification: BookingClassification
          $booking_status: BookingStatusInput
        ) {
          addBooking(
            id: $id
            booking_defendent: $booking_defendent
            booking_info: $booking_info
            booking_charges: $booking_charges
            booking_releases: $booking_releases
            classification: $classification
            booking_status: $booking_status
          ) {
            result
            description
            booking {
              id
              booking_defendent {
                cii_number
                local_id
                cdl_id
                name {
                  first
                  last
                }
                date_of_birth
                sex
                race
              }
              booking_info {
                booking_sid
                local_id
                booking_number
                booking_datetime
                booking_address
                booking_date
                arrest_agency
                arrested_for
              }
              booking_charges {
                id
                booking_charge_sid
                booking_sid
                booking_number
                booking_datetime
                charge_auth_type
                charge_code_section
                charge_type
                charge_datetime
                charge_description
                arrest_agency
                arrest_datetime
                crime_case_number
                court_warrant_number
              }
              booking_releases {
                charge_code_section
                charge_description
                charge_type
                charge_release_datetime
                charge_release_type
                warrant_number
                court_case_number
                jc_control
                created_datetime
              }
              booking_status {
                booking_number
                court_appearance_datetime
                court_reason
                court_room
              }
              classification
              imported_date
              comments
              is_eligible
              spanish_speaker
              has_wait
              prospect_status
              interview_and_assessment {
                research_factors {
                  has_prior_misdemeanor_conviction
                  has_prior_felony_conviction
                  prior_violent_convictions_count
                  prior_FTAs_past_two_years_count
                  has_prior_FTAs_older_than_two_years
                  has_prior_sentence_to_incarceration
                }
                risk_factors {
                  has_active_community_supervision
                  is_charge_felony_drug_theft_or_fraud
                  has_pending_charges
                  has_criminal_history
                  has_two_or_more_FTAs
                  has_two_or_more_violent_convictions
                  has_history_of_drug_abuse
                  employed_at_time_of_arrest
                }
                completed_by {
                  id
                  name {
                    first
                    last
                  }
                }
                court_date
                charge_bond_type_amount_court
                instrument_completed_at
                last_updated_at
              }
              is_interview_declined
            }
          }
        }
      `,
      variables,
    },
    "Error while adding a booking"
  );
};

export const startBookingInterviewAndAssessment = (
  variables: MutationStartBookingInterviewAndAssessmentArgs
): Promise<{ startBookingInterviewAndAssessment: { booking: Booking } }> => {
  return commitMutation<
    MutationStartBookingInterviewAndAssessmentArgs,
    { startBookingInterviewAndAssessment: { booking: Booking } }
  >(
    {
      mutation: graphql`
        mutation bookingsStartBookingInterviewAndAssessmentMutation(
          $booking_id: ID!
        ) {
          startBookingInterviewAndAssessment(booking_id: $booking_id) {
            result
            description
            booking {
              id
              booking_defendent {
                cii_number
                local_id
                cdl_id
                name {
                  first
                  last
                }
                date_of_birth
                sex
                race
              }
              booking_info {
                booking_sid
                local_id
                booking_number
                booking_datetime
                booking_address
                booking_date
                arrest_agency
                arrested_for
              }
              booking_charges {
                id
                booking_charge_sid
                booking_sid
                booking_number
                booking_datetime
                charge_auth_type
                charge_code_section
                charge_type
                charge_datetime
                charge_description
                arrest_agency
                arrest_datetime
                crime_case_number
                court_warrant_number
              }
              booking_releases {
                charge_code_section
                charge_description
                charge_type
                charge_release_datetime
                charge_release_type
                warrant_number
                court_case_number
                jc_control
                created_datetime
              }
              booking_status {
                booking_number
                court_appearance_datetime
                court_reason
                court_room
              }
              classification
              imported_date
              comments
              is_eligible
              spanish_speaker
              has_wait
              prospect_status
              interview_and_assessment {
                research_factors {
                  has_prior_misdemeanor_conviction
                  has_prior_felony_conviction
                  prior_violent_convictions_count
                  prior_FTAs_past_two_years_count
                  has_prior_FTAs_older_than_two_years
                  has_prior_sentence_to_incarceration
                }
                risk_factors {
                  has_active_community_supervision
                  is_charge_felony_drug_theft_or_fraud
                  has_pending_charges
                  has_criminal_history
                  has_two_or_more_FTAs
                  has_two_or_more_violent_convictions
                  has_history_of_drug_abuse
                  employed_at_time_of_arrest
                }
                completed_by {
                  id
                  name {
                    first
                    last
                  }
                }
                court_date
                charge_bond_type_amount_court
                instrument_completed_at
                last_updated_at
              }
              is_interview_declined
              recommendation_review_status
              risk_assessment_result {
                score
                level
                failure_rate
              }
              pretrial_decision_form {
                decision
                has_gps_monitoring
                has_alcohol_monitoring
                has_basic_monitoring
                sentencing_decision
                jail_sanction
              }
            }
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreUpdateBooking(
          "startBookingInterviewAndAssessment",
          store,
          variables.booking_id
        );
      },
    },
    "Error while attempting to start a booking interview."
  );
};

export const declineBookingInterview = (
  variables: MutationDeclineBookingInterviewArgs
): Promise<{ startBookingInterviewAndAssessment: { booking: Booking } }> => {
  return commitMutation<
    MutationDeclineBookingInterviewArgs,
    { startBookingInterviewAndAssessment: { booking: Booking } }
  >(
    {
      mutation: graphql`
        mutation bookingsDeclineBookingInterviewMutation($booking_id: ID!) {
          declineBookingInterview(booking_id: $booking_id) {
            result
            description
            booking {
              id
              booking_defendent {
                cii_number
                local_id
                cdl_id
                name {
                  first
                  last
                }
                date_of_birth
                sex
                race
              }
              booking_info {
                booking_sid
                local_id
                booking_number
                booking_datetime
                booking_address
                booking_date
                arrest_agency
                arrested_for
              }
              booking_charges {
                id
                booking_charge_sid
                booking_sid
                booking_number
                booking_datetime
                charge_auth_type
                charge_code_section
                charge_type
                charge_datetime
                charge_description
                arrest_agency
                arrest_datetime
                crime_case_number
                court_warrant_number
              }
              booking_releases {
                charge_code_section
                charge_description
                charge_type
                charge_release_datetime
                charge_release_type
                warrant_number
                court_case_number
                jc_control
                created_datetime
              }
              booking_status {
                booking_number
                court_appearance_datetime
                court_reason
                court_room
              }
              classification
              imported_date
              comments
              is_eligible
              spanish_speaker
              has_wait
              prospect_status
              interview_and_assessment {
                interview_data {
                  residence {
                    address
                    moved_into_current_address_date
                    phone_number
                    moved_to_bay_area_date
                  }
                  contacts {
                    name {
                      first
                      last
                    }
                    address
                    phone
                    date_of_birth
                    sex
                    relationship
                    how_often_seen
                  }
                  employment_and_education {
                    is_employed
                    current_employer_name
                    is_student_retired_disabled
                    is_primary_caretaker
                    employment_start_date
                    employment_type
                    hours_worked_per_week
                    type_of_work
                    previous_employer_name
                    previous_employer_start_date
                    previous_employer_end_date
                    unemployed_start_date
                    unemployed_how_supported
                  }
                  military_service {
                    has_served_in_military
                    military_service_comments
                  }
                  prior_record {
                    bench_warrants_count
                    currently_on_probation
                    has_pending_case
                    prior_record_comments
                  }
                  substance_abuse {
                    has_experimented_with_illegal_substances
                    type_of_illegal_substance_experimented
                    last_use_of_illegal_substance_date
                    has_abused_alcohol_or_marijuana
                    last_alcohol_or_marijuna_abuse_date
                    alcohol_or_marijuana_abuse_how_often
                    has_used_illegal_drugs_last_six_months
                    type_of_illegal_drug_used_last_six_months
                    how_often_illegal_drug_use_last_six_months
                    has_received_treatment_alcohol_or_marijuana
                    alcohol_or_marijuana_program_name
                    alcohol_or_marijuana_treatment_date
                  }
                  victim_info {
                    was_victim_in_case
                    victims {
                      name {
                        first
                        last
                      }
                      address
                      phone
                      date_of_birth
                      sex
                      relationship
                      how_often_seen
                    }
                    has_protective_order
                    has_stay_away_order
                    has_peaceful_contact_order
                    protective_order_notes
                  }
                  firearm_info {
                    has_firearm_registered
                    registered_firearm_model
                    registered_firearm_location
                    has_firearm_in_home
                    home_firearm_model
                    home_firearm_location
                  }
                }
                research_factors {
                  has_prior_misdemeanor_conviction
                  has_prior_felony_conviction
                  prior_violent_convictions_count
                  prior_FTAs_past_two_years_count
                  has_prior_FTAs_older_than_two_years
                  has_prior_sentence_to_incarceration
                }
                risk_factors {
                  has_active_community_supervision
                  is_charge_felony_drug_theft_or_fraud
                  has_pending_charges
                  has_criminal_history
                  has_two_or_more_FTAs
                  has_two_or_more_violent_convictions
                  has_history_of_drug_abuse
                  employed_at_time_of_arrest
                }
                completed_by {
                  id
                  name {
                    first
                    last
                  }
                }
                court_date
                charge_bond_type_amount_court
                instrument_completed_at
                last_updated_at
              }
              is_interview_declined
              court_memo {
                was_under_the_influence_of_alcohol_or_drugs
                was_combative
                was_in_medical_isolation
                mental_state_prevented_interview
                has_refused_interview
                is_no_longer_in_custody
                has_declined_interview_or_release
                has_plan_to_bail_out
                is_incomplete_due_to_insufficient_notice
                report_available_by_date
                has_existing_or_report_re_referred
                existing_or_report_date
                is_incomplete_due_to_referral_volume
                is_denied_or
                ordered_tad_date
                has_existing_or_report_previously_interviewed
                last_interviewed_date
                hold_out_state
                other_reason_comments
                signature_probation_supervisor
                signature_team_member
              }
              recommendation_form {
                has_verified_employment_information
                employment_comments
                pending_disposition
                pending_disposition_comments
                vprai_override_public_safety_comments
                signature_probation_supervisor
                signature_team_member
                incident_summary_notes
                mandatory_detention
                additional_information
                release_recommendation
                conditions_of_release {
                  supervision_and_custody
                  not_leave_state_or_residence
                  notify_change_employment_or_residence
                  report_regularly_pretrial
                  report_to_probation
                  tcemp
                  tcv3a
                  curfew
                  location_tracking
                  alcohol_detection
                  alcohol_detection_with_curfew
                  follow_court_protective_orders
                  tcaap
                  tcbaf
                  tcnax
                  tcnae
                  tcgnx
                  tcgwr
                  follow_court_probation_conditions
                  pretrial_program
                }
                reasons_against_release {
                  high_risk_to_fail_pretrial_release
                  vprai_override_public_safety
                  vprai_override_victim_residence
                }
                criminal_history {
                  two_or_more_violent_convictions
                  one_or_more_misdemeanor_or_felony_convictions
                  no_prior_criminal_history
                }
                substance_abuse_comments
                afs_info {
                  afs_check_date
                  number_of_weapons_registered
                }
                internal_notes {
                  text
                  created_at
                  created_by {
                    name {
                      first
                      last
                    }
                  }
                }
              }
              recommendation_review_status
              risk_assessment_result {
                score
                level
                failure_rate
              }
              pretrial_decision_form {
                decision
                has_gps_monitoring
                has_alcohol_monitoring
                has_basic_monitoring
                sentencing_decision
                jail_sanction
              }
            }
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreUpdateBooking(
          "declineBookingInterview",
          store,
          variables.booking_id
        );
      },
    },
    "Error while attempting to decline a booking interview."
  );
};

export const updateBookingInterviewAndAssessment = (
  variables: MutationUpdateBookingInterviewAndAssessmentArgs
): Promise<{ updateBookingInterviewAndAssessment: { booking: Booking } }> => {
  return commitMutation<
    MutationStartBookingInterviewAndAssessmentArgs,
    { updateBookingInterviewAndAssessment: { booking: Booking } }
  >(
    {
      mutation: graphql`
        mutation bookingsUpdateBookingInterviewAndAssessmentMutation(
          $booking_id: ID!
          $research_factors: ResearchFactorInput!
          $risk_factors: RiskFactorInput!
        ) {
          submitBookingInterviewAndAssessment(
            booking_id: $booking_id
            research_factors: $research_factors
            risk_factors: $risk_factors
          ) {
            result
            description
            booking {
              id
              booking_defendent {
                cii_number
                local_id
                cdl_id
                name {
                  first
                  last
                }
                date_of_birth
                sex
                race
              }
              booking_info {
                booking_sid
                local_id
                booking_number
                booking_datetime
                booking_address
                booking_date
                arrest_agency
                arrested_for
              }
              booking_charges {
                id
                booking_charge_sid
                booking_sid
                booking_number
                booking_datetime
                charge_auth_type
                charge_code_section
                charge_type
                charge_datetime
                charge_description
                arrest_agency
                arrest_datetime
                crime_case_number
                court_warrant_number
              }
              booking_releases {
                charge_code_section
                charge_description
                charge_type
                charge_release_datetime
                charge_release_type
                warrant_number
                court_case_number
                jc_control
                created_datetime
              }
              booking_status {
                booking_number
                court_appearance_datetime
                court_reason
                court_room
              }
              classification
              imported_date
              comments
              is_eligible
              spanish_speaker
              has_wait
              prospect_status
              interview_and_assessment {
                interview_data {
                  residence {
                    address
                    moved_into_current_address_date
                    phone_number
                    moved_to_bay_area_date
                  }
                  contacts {
                    name {
                      first
                      last
                    }
                    address
                    phone
                    date_of_birth
                    sex
                    relationship
                    how_often_seen
                  }
                  employment_and_education {
                    is_employed
                    current_employer_name
                    is_student_retired_disabled
                    is_primary_caretaker
                    employment_start_date
                    employment_type
                    hours_worked_per_week
                    type_of_work
                    previous_employer_name
                    previous_employer_start_date
                    previous_employer_end_date
                    unemployed_start_date
                    unemployed_how_supported
                  }
                  military_service {
                    has_served_in_military
                    military_service_comments
                  }
                  prior_record {
                    bench_warrants_count
                    currently_on_probation
                    has_pending_case
                    prior_record_comments
                  }
                  substance_abuse {
                    has_experimented_with_illegal_substances
                    type_of_illegal_substance_experimented
                    last_use_of_illegal_substance_date
                    has_abused_alcohol_or_marijuana
                    last_alcohol_or_marijuna_abuse_date
                    alcohol_or_marijuana_abuse_how_often
                    has_used_illegal_drugs_last_six_months
                    type_of_illegal_drug_used_last_six_months
                    how_often_illegal_drug_use_last_six_months
                    has_received_treatment_alcohol_or_marijuana
                    alcohol_or_marijuana_program_name
                    alcohol_or_marijuana_treatment_date
                  }
                  victim_info {
                    was_victim_in_case
                    victims {
                      name {
                        first
                        last
                      }
                      address
                      phone
                      date_of_birth
                      sex
                      relationship
                      how_often_seen
                    }
                    has_protective_order
                    has_stay_away_order
                    has_peaceful_contact_order
                    protective_order_notes
                  }
                  firearm_info {
                    has_firearm_registered
                    registered_firearm_model
                    registered_firearm_location
                    has_firearm_in_home
                    home_firearm_model
                    home_firearm_location
                  }
                }
                research_factors {
                  has_prior_misdemeanor_conviction
                  has_prior_felony_conviction
                  prior_violent_convictions_count
                  prior_FTAs_past_two_years_count
                  has_prior_FTAs_older_than_two_years
                  has_prior_sentence_to_incarceration
                }
                risk_factors {
                  has_active_community_supervision
                  is_charge_felony_drug_theft_or_fraud
                  has_pending_charges
                  has_criminal_history
                  has_two_or_more_FTAs
                  has_two_or_more_violent_convictions
                  has_history_of_drug_abuse
                  employed_at_time_of_arrest
                }
                completed_by {
                  id
                  name {
                    first
                    last
                  }
                }
                court_date
                charge_bond_type_amount_court
                instrument_completed_at
                last_updated_at
              }
              is_interview_declined
              court_memo {
                was_under_the_influence_of_alcohol_or_drugs
                was_combative
                was_in_medical_isolation
                mental_state_prevented_interview
                has_refused_interview
                is_no_longer_in_custody
                has_declined_interview_or_release
                has_plan_to_bail_out
                is_incomplete_due_to_insufficient_notice
                report_available_by_date
                has_existing_or_report_re_referred
                existing_or_report_date
                is_incomplete_due_to_referral_volume
                is_denied_or
                ordered_tad_date
                has_existing_or_report_previously_interviewed
                last_interviewed_date
                hold_out_state
                other_reason_comments
                signature_probation_supervisor
                signature_team_member
              }
              recommendation_form {
                has_verified_employment_information
                employment_comments
                pending_disposition
                pending_disposition_comments
                vprai_override_public_safety_comments
                signature_probation_supervisor
                signature_team_member
                incident_summary_notes
                mandatory_detention
                additional_information
                release_recommendation
                conditions_of_release {
                  supervision_and_custody
                  not_leave_state_or_residence
                  notify_change_employment_or_residence
                  report_regularly_pretrial
                  report_to_probation
                  tcemp
                  tcv3a
                  curfew
                  location_tracking
                  alcohol_detection
                  alcohol_detection_with_curfew
                  follow_court_protective_orders
                  tcaap
                  tcbaf
                  tcnax
                  tcnae
                  tcgnx
                  tcgwr
                  follow_court_probation_conditions
                  pretrial_program
                }
                reasons_against_release {
                  high_risk_to_fail_pretrial_release
                  vprai_override_public_safety
                  vprai_override_victim_residence
                }
                criminal_history {
                  two_or_more_violent_convictions
                  one_or_more_misdemeanor_or_felony_convictions
                  no_prior_criminal_history
                }
                substance_abuse_comments
                afs_info {
                  afs_check_date
                  number_of_weapons_registered
                }
                internal_notes {
                  text
                  created_at
                  created_by {
                    name {
                      first
                      last
                    }
                  }
                }
              }
              recommendation_review_status
              risk_assessment_result {
                score
                level
                failure_rate
              }
              pretrial_decision_form {
                decision
                has_gps_monitoring
                has_alcohol_monitoring
                has_basic_monitoring
                sentencing_decision
                jail_sanction
              }
            }
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreUpdateBooking(
          "submitBookingInterviewAndAssessment",
          store,
          variables.booking_id
        );
      },
    },
    "Error while attempting to submitBookingInterviewAndAssessment"
  );
};

export const updateBookingInterviewData = (
  variables: MutationUpdateBookingInterviewDataArgs
): Promise<{ updateBookingInterviewAndAssessment: { booking: Booking } }> => {
  return commitMutation<
    MutationUpdateBookingInterviewDataArgs,
    { updateBookingInterviewAndAssessment: { booking: Booking } }
  >(
    {
      mutation: graphql`
        mutation bookingsUpdateBookingInterviewDataMutation(
          $booking_id: ID!
          $interview_data: InterviewDataInput!
        ) {
          updateBookingInterviewData(
            booking_id: $booking_id
            interview_data: $interview_data
          ) {
            result
            description
            booking {
              id
              booking_defendent {
                cii_number
                local_id
                cdl_id
                name {
                  first
                  last
                }
                date_of_birth
                sex
                race
              }
              booking_info {
                booking_sid
                local_id
                booking_number
                booking_datetime
                booking_address
                booking_date
                arrest_agency
                arrested_for
              }
              booking_charges {
                id
                booking_charge_sid
                booking_sid
                booking_number
                booking_datetime
                charge_auth_type
                charge_code_section
                charge_type
                charge_datetime
                charge_description
                arrest_agency
                arrest_datetime
                crime_case_number
                court_warrant_number
              }
              booking_releases {
                charge_code_section
                charge_description
                charge_type
                charge_release_datetime
                charge_release_type
                warrant_number
                court_case_number
                jc_control
                created_datetime
              }
              booking_status {
                booking_number
                court_appearance_datetime
                court_reason
                court_room
              }
              classification
              imported_date
              comments
              is_eligible
              spanish_speaker
              has_wait
              prospect_status
              interview_and_assessment {
                interview_data {
                  residence {
                    address
                    moved_into_current_address_date
                    phone_number
                    moved_to_bay_area_date
                  }
                  contacts {
                    name {
                      first
                      last
                    }
                    address
                    phone
                    date_of_birth
                    sex
                    relationship
                    how_often_seen
                  }
                  employment_and_education {
                    is_employed
                    current_employer_name
                    is_student_retired_disabled
                    is_primary_caretaker
                    employment_start_date
                    employment_type
                    hours_worked_per_week
                    type_of_work
                    previous_employer_name
                    previous_employer_start_date
                    previous_employer_end_date
                    unemployed_start_date
                    unemployed_how_supported
                  }
                  military_service {
                    has_served_in_military
                    military_service_comments
                  }
                  prior_record {
                    bench_warrants_count
                    currently_on_probation
                    has_pending_case
                    prior_record_comments
                  }
                  substance_abuse {
                    has_experimented_with_illegal_substances
                    type_of_illegal_substance_experimented
                    last_use_of_illegal_substance_date
                    has_abused_alcohol_or_marijuana
                    last_alcohol_or_marijuna_abuse_date
                    alcohol_or_marijuana_abuse_how_often
                    has_used_illegal_drugs_last_six_months
                    type_of_illegal_drug_used_last_six_months
                    how_often_illegal_drug_use_last_six_months
                    has_received_treatment_alcohol_or_marijuana
                    alcohol_or_marijuana_program_name
                    alcohol_or_marijuana_treatment_date
                  }
                  victim_info {
                    was_victim_in_case
                    victims {
                      name {
                        first
                        last
                      }
                      address
                      phone
                      date_of_birth
                      sex
                      relationship
                      how_often_seen
                    }
                    has_protective_order
                    has_stay_away_order
                    has_peaceful_contact_order
                    protective_order_notes
                  }
                  firearm_info {
                    has_firearm_registered
                    registered_firearm_model
                    registered_firearm_location
                    has_firearm_in_home
                    home_firearm_model
                    home_firearm_location
                  }
                }
                research_factors {
                  has_prior_misdemeanor_conviction
                  has_prior_felony_conviction
                  prior_violent_convictions_count
                  prior_FTAs_past_two_years_count
                  has_prior_FTAs_older_than_two_years
                  has_prior_sentence_to_incarceration
                }
                risk_factors {
                  has_active_community_supervision
                  is_charge_felony_drug_theft_or_fraud
                  has_pending_charges
                  has_criminal_history
                  has_two_or_more_FTAs
                  has_two_or_more_violent_convictions
                  has_history_of_drug_abuse
                  employed_at_time_of_arrest
                }
                completed_by {
                  id
                  name {
                    first
                    last
                  }
                }
                court_date
                charge_bond_type_amount_court
                instrument_completed_at
                last_updated_at
              }
              is_interview_declined
              court_memo {
                was_under_the_influence_of_alcohol_or_drugs
                was_combative
                was_in_medical_isolation
                mental_state_prevented_interview
                has_refused_interview
                is_no_longer_in_custody
                has_declined_interview_or_release
                has_plan_to_bail_out
                is_incomplete_due_to_insufficient_notice
                report_available_by_date
                has_existing_or_report_re_referred
                existing_or_report_date
                is_incomplete_due_to_referral_volume
                is_denied_or
                ordered_tad_date
                has_existing_or_report_previously_interviewed
                last_interviewed_date
                hold_out_state
                other_reason_comments
                signature_probation_supervisor
                signature_team_member
              }
              recommendation_form {
                has_verified_employment_information
                employment_comments
                pending_disposition
                pending_disposition_comments
                vprai_override_public_safety_comments
                signature_probation_supervisor
                signature_team_member
                incident_summary_notes
                mandatory_detention
                additional_information
                release_recommendation
                conditions_of_release {
                  supervision_and_custody
                  not_leave_state_or_residence
                  notify_change_employment_or_residence
                  report_regularly_pretrial
                  report_to_probation
                  tcemp
                  tcv3a
                  curfew
                  location_tracking
                  alcohol_detection
                  alcohol_detection_with_curfew
                  follow_court_protective_orders
                  tcaap
                  tcbaf
                  tcnax
                  tcnae
                  tcgnx
                  tcgwr
                  follow_court_probation_conditions
                  pretrial_program
                }
                reasons_against_release {
                  high_risk_to_fail_pretrial_release
                  vprai_override_public_safety
                  vprai_override_victim_residence
                }
                criminal_history {
                  two_or_more_violent_convictions
                  one_or_more_misdemeanor_or_felony_convictions
                  no_prior_criminal_history
                }
                substance_abuse_comments
                afs_info {
                  afs_check_date
                  number_of_weapons_registered
                }
                internal_notes {
                  text
                  created_at
                  created_by {
                    name {
                      first
                      last
                    }
                  }
                }
              }
              recommendation_review_status
              risk_assessment_result {
                score
                level
                failure_rate
              }
              pretrial_decision_form {
                decision
                has_gps_monitoring
                has_alcohol_monitoring
                has_basic_monitoring
                sentencing_decision
                jail_sanction
              }
            }
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreUpdateBooking(
          "updateBookingInterviewData",
          store,
          variables.booking_id
        );
      },
    },
    "Error while attempting to updateBookingInterviewData"
  );
};

export const updateBookingRecommendationForm = (
  variables: MutationUpdateBookingRecommendationFormArgs
): Promise<{ updateBookingInterviewAndAssessment: { booking: Booking } }> => {
  return commitMutation<
    MutationUpdateBookingRecommendationFormArgs,
    { updateBookingInterviewAndAssessment: { booking: Booking } }
  >(
    {
      mutation: graphql`
        mutation bookingsUpdateBookingRecommendationFormMutation(
          $booking_id: ID!
          $recommendation_form: RecommendationFormInput!
        ) {
          updateBookingRecommendationForm(
            booking_id: $booking_id
            recommendation_form: $recommendation_form
          ) {
            result
            description
            booking {
              id
              booking_defendent {
                cii_number
                local_id
                cdl_id
                name {
                  first
                  last
                }
                date_of_birth
                sex
                race
              }
              booking_info {
                booking_sid
                local_id
                booking_number
                booking_datetime
                booking_address
                booking_date
                arrest_agency
                arrested_for
              }
              booking_charges {
                id
                booking_charge_sid
                booking_sid
                booking_number
                booking_datetime
                charge_auth_type
                charge_code_section
                charge_type
                charge_datetime
                charge_description
                arrest_agency
                arrest_datetime
                crime_case_number
                court_warrant_number
              }
              booking_releases {
                charge_code_section
                charge_description
                charge_type
                charge_release_datetime
                charge_release_type
                warrant_number
                court_case_number
                jc_control
                created_datetime
              }
              booking_status {
                booking_number
                court_appearance_datetime
                court_reason
                court_room
              }
              classification
              imported_date
              comments
              is_eligible
              spanish_speaker
              has_wait
              prospect_status
              interview_and_assessment {
                interview_data {
                  residence {
                    address
                    moved_into_current_address_date
                    phone_number
                    moved_to_bay_area_date
                  }
                  contacts {
                    name {
                      first
                      last
                    }
                    address
                    phone
                    date_of_birth
                    sex
                    relationship
                    how_often_seen
                  }
                  employment_and_education {
                    is_employed
                    current_employer_name
                    is_student_retired_disabled
                    is_primary_caretaker
                    employment_start_date
                    employment_type
                    hours_worked_per_week
                    type_of_work
                    previous_employer_name
                    previous_employer_start_date
                    previous_employer_end_date
                    unemployed_start_date
                    unemployed_how_supported
                  }
                  military_service {
                    has_served_in_military
                    military_service_comments
                  }
                  prior_record {
                    bench_warrants_count
                    currently_on_probation
                    has_pending_case
                    prior_record_comments
                  }
                  substance_abuse {
                    has_experimented_with_illegal_substances
                    type_of_illegal_substance_experimented
                    last_use_of_illegal_substance_date
                    has_abused_alcohol_or_marijuana
                    last_alcohol_or_marijuna_abuse_date
                    alcohol_or_marijuana_abuse_how_often
                    has_used_illegal_drugs_last_six_months
                    type_of_illegal_drug_used_last_six_months
                    how_often_illegal_drug_use_last_six_months
                    has_received_treatment_alcohol_or_marijuana
                    alcohol_or_marijuana_program_name
                    alcohol_or_marijuana_treatment_date
                  }
                  victim_info {
                    was_victim_in_case
                    victims {
                      name {
                        first
                        last
                      }
                      address
                      phone
                      date_of_birth
                      sex
                      relationship
                      how_often_seen
                    }
                    has_protective_order
                    has_stay_away_order
                    has_peaceful_contact_order
                    protective_order_notes
                  }
                  firearm_info {
                    has_firearm_registered
                    registered_firearm_model
                    registered_firearm_location
                    has_firearm_in_home
                    home_firearm_model
                    home_firearm_location
                  }
                }
                research_factors {
                  has_prior_misdemeanor_conviction
                  has_prior_felony_conviction
                  prior_violent_convictions_count
                  prior_FTAs_past_two_years_count
                  has_prior_FTAs_older_than_two_years
                  has_prior_sentence_to_incarceration
                }
                risk_factors {
                  has_active_community_supervision
                  is_charge_felony_drug_theft_or_fraud
                  has_pending_charges
                  has_criminal_history
                  has_two_or_more_FTAs
                  has_two_or_more_violent_convictions
                  has_history_of_drug_abuse
                  employed_at_time_of_arrest
                }
                completed_by {
                  id
                  name {
                    first
                    last
                  }
                }
                court_date
                charge_bond_type_amount_court
                instrument_completed_at
                last_updated_at
              }
              is_interview_declined
              court_memo {
                was_under_the_influence_of_alcohol_or_drugs
                was_combative
                was_in_medical_isolation
                mental_state_prevented_interview
                has_refused_interview
                is_no_longer_in_custody
                has_declined_interview_or_release
                has_plan_to_bail_out
                is_incomplete_due_to_insufficient_notice
                report_available_by_date
                has_existing_or_report_re_referred
                existing_or_report_date
                is_incomplete_due_to_referral_volume
                is_denied_or
                ordered_tad_date
                has_existing_or_report_previously_interviewed
                last_interviewed_date
                hold_out_state
                other_reason_comments
                signature_probation_supervisor
                signature_team_member
              }
              recommendation_form {
                has_verified_employment_information
                employment_comments
                pending_disposition
                pending_disposition_comments
                vprai_override_public_safety_comments
                signature_probation_supervisor
                signature_team_member
                incident_summary_notes
                mandatory_detention
                additional_information
                release_recommendation
                conditions_of_release {
                  supervision_and_custody
                  not_leave_state_or_residence
                  notify_change_employment_or_residence
                  report_regularly_pretrial
                  report_to_probation
                  tcemp
                  tcv3a
                  curfew
                  location_tracking
                  alcohol_detection
                  alcohol_detection_with_curfew
                  follow_court_protective_orders
                  tcaap
                  tcbaf
                  tcnax
                  tcnae
                  tcgnx
                  tcgwr
                  follow_court_probation_conditions
                  pretrial_program
                }
                reasons_against_release {
                  high_risk_to_fail_pretrial_release
                  vprai_override_public_safety
                  vprai_override_victim_residence
                }
                criminal_history {
                  two_or_more_violent_convictions
                  one_or_more_misdemeanor_or_felony_convictions
                  no_prior_criminal_history
                }
                substance_abuse_comments
                afs_info {
                  afs_check_date
                  number_of_weapons_registered
                }
                internal_notes {
                  text
                  created_at
                  created_by {
                    name {
                      first
                      last
                    }
                  }
                }
              }
              recommendation_review_status
              risk_assessment_result {
                score
                level
                failure_rate
              }
              pretrial_decision_form {
                decision
                has_gps_monitoring
                has_alcohol_monitoring
                has_basic_monitoring
                sentencing_decision
                jail_sanction
              }
            }
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreUpdateBooking(
          "updateBookingRecommendationForm",
          store,
          variables.booking_id
        );
      },
    },
    "Error while attempting to updateBookingRecommendationForm"
  );
};

export const submitBookingRecommendationFormForReview = (
  variables: MutationSubmitBookingRecommendationFormForReviewArgs
): Promise<{
  submitBookingRecommendationFormForReview: { booking: Booking };
}> => {
  return commitMutation<
    MutationSubmitBookingRecommendationFormForReviewArgs,
    { submitBookingRecommendationFormForReview: { booking: Booking } }
  >(
    {
      mutation: graphql`
        mutation bookingsSubmitBookingRecommendationFormForReviewMutation(
          $booking_id: ID!
          $recommendation_form: RecommendationFormInput!
        ) {
          submitBookingRecommendationFormForReview(
            booking_id: $booking_id
            recommendation_form: $recommendation_form
          ) {
            result
            description
            booking {
              id
              booking_defendent {
                cii_number
                local_id
                cdl_id
                name {
                  first
                  last
                }
                date_of_birth
                sex
                race
              }
              booking_info {
                booking_sid
                local_id
                booking_number
                booking_datetime
                booking_address
                booking_date
                arrest_agency
                arrested_for
              }
              booking_charges {
                id
                booking_charge_sid
                booking_sid
                booking_number
                booking_datetime
                charge_auth_type
                charge_code_section
                charge_type
                charge_datetime
                charge_description
                arrest_agency
                arrest_datetime
                crime_case_number
                court_warrant_number
              }
              booking_releases {
                charge_code_section
                charge_description
                charge_type
                charge_release_datetime
                charge_release_type
                warrant_number
                court_case_number
                jc_control
                created_datetime
              }
              booking_status {
                booking_number
                court_appearance_datetime
                court_reason
                court_room
              }
              classification
              imported_date
              comments
              is_eligible
              spanish_speaker
              has_wait
              prospect_status
              interview_and_assessment {
                interview_data {
                  residence {
                    address
                    moved_into_current_address_date
                    phone_number
                    moved_to_bay_area_date
                  }
                  contacts {
                    name {
                      first
                      last
                    }
                    address
                    phone
                    date_of_birth
                    sex
                    relationship
                    how_often_seen
                  }
                  employment_and_education {
                    is_employed
                    current_employer_name
                    is_student_retired_disabled
                    is_primary_caretaker
                    employment_start_date
                    employment_type
                    hours_worked_per_week
                    type_of_work
                    previous_employer_name
                    previous_employer_start_date
                    previous_employer_end_date
                    unemployed_start_date
                    unemployed_how_supported
                  }
                  military_service {
                    has_served_in_military
                    military_service_comments
                  }
                  prior_record {
                    bench_warrants_count
                    currently_on_probation
                    has_pending_case
                    prior_record_comments
                  }
                  substance_abuse {
                    has_experimented_with_illegal_substances
                    type_of_illegal_substance_experimented
                    last_use_of_illegal_substance_date
                    has_abused_alcohol_or_marijuana
                    last_alcohol_or_marijuna_abuse_date
                    alcohol_or_marijuana_abuse_how_often
                    has_used_illegal_drugs_last_six_months
                    type_of_illegal_drug_used_last_six_months
                    how_often_illegal_drug_use_last_six_months
                    has_received_treatment_alcohol_or_marijuana
                    alcohol_or_marijuana_program_name
                    alcohol_or_marijuana_treatment_date
                  }
                  victim_info {
                    was_victim_in_case
                    victims {
                      name {
                        first
                        last
                      }
                      address
                      phone
                      date_of_birth
                      sex
                      relationship
                      how_often_seen
                    }
                    has_protective_order
                    has_stay_away_order
                    has_peaceful_contact_order
                    protective_order_notes
                  }
                  firearm_info {
                    has_firearm_registered
                    registered_firearm_model
                    registered_firearm_location
                    has_firearm_in_home
                    home_firearm_model
                    home_firearm_location
                  }
                }
                research_factors {
                  has_prior_misdemeanor_conviction
                  has_prior_felony_conviction
                  prior_violent_convictions_count
                  prior_FTAs_past_two_years_count
                  has_prior_FTAs_older_than_two_years
                  has_prior_sentence_to_incarceration
                }
                risk_factors {
                  has_active_community_supervision
                  is_charge_felony_drug_theft_or_fraud
                  has_pending_charges
                  has_criminal_history
                  has_two_or_more_FTAs
                  has_two_or_more_violent_convictions
                  has_history_of_drug_abuse
                  employed_at_time_of_arrest
                }
                completed_by {
                  id
                  name {
                    first
                    last
                  }
                }
                court_date
                charge_bond_type_amount_court
                instrument_completed_at
                last_updated_at
              }
              is_interview_declined
              court_memo {
                was_under_the_influence_of_alcohol_or_drugs
                was_combative
                was_in_medical_isolation
                mental_state_prevented_interview
                has_refused_interview
                is_no_longer_in_custody
                has_declined_interview_or_release
                has_plan_to_bail_out
                is_incomplete_due_to_insufficient_notice
                report_available_by_date
                has_existing_or_report_re_referred
                existing_or_report_date
                is_incomplete_due_to_referral_volume
                is_denied_or
                ordered_tad_date
                has_existing_or_report_previously_interviewed
                last_interviewed_date
                hold_out_state
                other_reason_comments
                signature_probation_supervisor
                signature_team_member
              }
              recommendation_form {
                has_verified_employment_information
                employment_comments
                pending_disposition
                pending_disposition_comments
                vprai_override_public_safety_comments
                signature_probation_supervisor
                signature_team_member
                incident_summary_notes
                mandatory_detention
                additional_information
                release_recommendation
                conditions_of_release {
                  supervision_and_custody
                  not_leave_state_or_residence
                  notify_change_employment_or_residence
                  report_regularly_pretrial
                  report_to_probation
                  tcemp
                  tcv3a
                  curfew
                  location_tracking
                  alcohol_detection
                  alcohol_detection_with_curfew
                  follow_court_protective_orders
                  tcaap
                  tcbaf
                  tcnax
                  tcnae
                  tcgnx
                  tcgwr
                  follow_court_probation_conditions
                  pretrial_program
                }
                reasons_against_release {
                  high_risk_to_fail_pretrial_release
                  vprai_override_public_safety
                  vprai_override_victim_residence
                }
                criminal_history {
                  two_or_more_violent_convictions
                  one_or_more_misdemeanor_or_felony_convictions
                  no_prior_criminal_history
                }
                substance_abuse_comments
                afs_info {
                  afs_check_date
                  number_of_weapons_registered
                }
                submitted_for_review_by {
                  name {
                    first
                    last
                  }
                }
                submitted_for_review_at
                reviewed_by {
                  name {
                    first
                    last
                  }
                }
                reviewed_at
                completed_by {
                  name {
                    first
                    last
                  }
                }
                completed_at
                internal_notes {
                  text
                  created_at
                  created_by {
                    name {
                      first
                      last
                    }
                  }
                }
              }
              recommendation_review_status
              risk_assessment_result {
                score
                level
                failure_rate
              }
              pretrial_decision_form {
                decision
                has_gps_monitoring
                has_alcohol_monitoring
                has_basic_monitoring
                sentencing_decision
                jail_sanction
              }
            }
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreUpdateBooking(
          "submitBookingRecommendationFormForReview",
          store,
          variables.booking_id
        );
      },
    },
    "Error while attempting to submitBookingRecommendationFormForReview"
  );
};

export const reviewBookingRecommendationForm = (
  variables: MutationReviewBookingRecommendationFormArgs
): Promise<{
  reviewBookingRecommendationForm: { booking: Booking };
}> => {
  return commitMutation<
    MutationReviewBookingRecommendationFormArgs,
    { reviewBookingRecommendationForm: { booking: Booking } }
  >(
    {
      mutation: graphql`
        mutation bookingsReviewBookingRecommendationFormMutation(
          $booking_id: ID!
          $recommendation_form: RecommendationFormInput!
          $is_approved: Boolean!
        ) {
          reviewBookingRecommendationForm(
            booking_id: $booking_id
            recommendation_form: $recommendation_form
            is_approved: $is_approved
          ) {
            result
            description
            booking {
              id
              booking_defendent {
                cii_number
                local_id
                cdl_id
                name {
                  first
                  last
                }
                date_of_birth
                sex
                race
              }
              booking_info {
                booking_sid
                local_id
                booking_number
                booking_datetime
                booking_address
                booking_date
                arrest_agency
                arrested_for
              }
              booking_charges {
                id
                booking_charge_sid
                booking_sid
                booking_number
                booking_datetime
                charge_auth_type
                charge_code_section
                charge_type
                charge_datetime
                charge_description
                arrest_agency
                arrest_datetime
                crime_case_number
                court_warrant_number
              }
              booking_releases {
                charge_code_section
                charge_description
                charge_type
                charge_release_datetime
                charge_release_type
                warrant_number
                court_case_number
                jc_control
                created_datetime
              }
              booking_status {
                booking_number
                court_appearance_datetime
                court_reason
                court_room
              }
              classification
              imported_date
              comments
              is_eligible
              spanish_speaker
              has_wait
              prospect_status
              interview_and_assessment {
                interview_data {
                  residence {
                    address
                    moved_into_current_address_date
                    phone_number
                    moved_to_bay_area_date
                  }
                  contacts {
                    name {
                      first
                      last
                    }
                    address
                    phone
                    date_of_birth
                    sex
                    relationship
                    how_often_seen
                  }
                  employment_and_education {
                    is_employed
                    current_employer_name
                    is_student_retired_disabled
                    is_primary_caretaker
                    employment_start_date
                    employment_type
                    hours_worked_per_week
                    type_of_work
                    previous_employer_name
                    previous_employer_start_date
                    previous_employer_end_date
                    unemployed_start_date
                    unemployed_how_supported
                  }
                  military_service {
                    has_served_in_military
                    military_service_comments
                  }
                  prior_record {
                    bench_warrants_count
                    currently_on_probation
                    has_pending_case
                    prior_record_comments
                  }
                  substance_abuse {
                    has_experimented_with_illegal_substances
                    type_of_illegal_substance_experimented
                    last_use_of_illegal_substance_date
                    has_abused_alcohol_or_marijuana
                    last_alcohol_or_marijuna_abuse_date
                    alcohol_or_marijuana_abuse_how_often
                    has_used_illegal_drugs_last_six_months
                    type_of_illegal_drug_used_last_six_months
                    how_often_illegal_drug_use_last_six_months
                    has_received_treatment_alcohol_or_marijuana
                    alcohol_or_marijuana_program_name
                    alcohol_or_marijuana_treatment_date
                  }
                  victim_info {
                    was_victim_in_case
                    victims {
                      name {
                        first
                        last
                      }
                      address
                      phone
                      date_of_birth
                      sex
                      relationship
                      how_often_seen
                    }
                    has_protective_order
                    has_stay_away_order
                    has_peaceful_contact_order
                    protective_order_notes
                  }
                  firearm_info {
                    has_firearm_registered
                    registered_firearm_model
                    registered_firearm_location
                    has_firearm_in_home
                    home_firearm_model
                    home_firearm_location
                  }
                }
                research_factors {
                  has_prior_misdemeanor_conviction
                  has_prior_felony_conviction
                  prior_violent_convictions_count
                  prior_FTAs_past_two_years_count
                  has_prior_FTAs_older_than_two_years
                  has_prior_sentence_to_incarceration
                }
                risk_factors {
                  has_active_community_supervision
                  is_charge_felony_drug_theft_or_fraud
                  has_pending_charges
                  has_criminal_history
                  has_two_or_more_FTAs
                  has_two_or_more_violent_convictions
                  has_history_of_drug_abuse
                  employed_at_time_of_arrest
                }
                completed_by {
                  id
                  name {
                    first
                    last
                  }
                }
                court_date
                charge_bond_type_amount_court
                instrument_completed_at
                last_updated_at
              }
              is_interview_declined
              court_memo {
                was_under_the_influence_of_alcohol_or_drugs
                was_combative
                was_in_medical_isolation
                mental_state_prevented_interview
                has_refused_interview
                is_no_longer_in_custody
                has_declined_interview_or_release
                has_plan_to_bail_out
                is_incomplete_due_to_insufficient_notice
                report_available_by_date
                has_existing_or_report_re_referred
                existing_or_report_date
                is_incomplete_due_to_referral_volume
                is_denied_or
                ordered_tad_date
                has_existing_or_report_previously_interviewed
                last_interviewed_date
                hold_out_state
                other_reason_comments
                signature_probation_supervisor
                signature_team_member
              }
              recommendation_form {
                has_verified_employment_information
                employment_comments
                pending_disposition
                pending_disposition_comments
                vprai_override_public_safety_comments
                signature_probation_supervisor
                signature_team_member
                incident_summary_notes
                mandatory_detention
                additional_information
                release_recommendation
                conditions_of_release {
                  supervision_and_custody
                  not_leave_state_or_residence
                  notify_change_employment_or_residence
                  report_regularly_pretrial
                  report_to_probation
                  tcemp
                  tcv3a
                  curfew
                  location_tracking
                  alcohol_detection
                  alcohol_detection_with_curfew
                  follow_court_protective_orders
                  tcaap
                  tcbaf
                  tcnax
                  tcnae
                  tcgnx
                  tcgwr
                  follow_court_probation_conditions
                  pretrial_program
                }
                reasons_against_release {
                  high_risk_to_fail_pretrial_release
                  vprai_override_public_safety
                  vprai_override_victim_residence
                }
                criminal_history {
                  two_or_more_violent_convictions
                  one_or_more_misdemeanor_or_felony_convictions
                  no_prior_criminal_history
                }
                substance_abuse_comments
                afs_info {
                  afs_check_date
                  number_of_weapons_registered
                }
                submitted_for_review_by {
                  name {
                    first
                    last
                  }
                }
                submitted_for_review_at
                reviewed_by {
                  name {
                    first
                    last
                  }
                }
                reviewed_at
                completed_by {
                  name {
                    first
                    last
                  }
                }
                completed_at
                internal_notes {
                  text
                  created_at
                  created_by {
                    name {
                      first
                      last
                    }
                  }
                }
              }
              recommendation_review_status
              risk_assessment_result {
                score
                level
                failure_rate
              }
              pretrial_decision_form {
                decision
                has_gps_monitoring
                has_alcohol_monitoring
                has_basic_monitoring
                sentencing_decision
                jail_sanction
              }
            }
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreUpdateBooking(
          "reviewBookingRecommendationForm",
          store,
          variables.booking_id
        );
      },
    },
    "Error while attempting to reviewBookingRecommendationForm"
  );
};

export const submitBookingRecommendationForm = (
  variables: MutationSubmitBookingRecommendationFormArgs
): Promise<{
  submitBookingRecommendationForm: { booking: Booking };
}> => {
  return commitMutation<
    MutationSubmitBookingRecommendationFormArgs,
    { submitBookingRecommendationForm: { booking: Booking } }
  >(
    {
      mutation: graphql`
        mutation bookingsSubmitBookingRecommendationFormMutation(
          $booking_id: ID!
        ) {
          submitBookingRecommendationForm(booking_id: $booking_id) {
            result
            description
            booking {
              id
              booking_defendent {
                cii_number
                local_id
                cdl_id
                name {
                  first
                  last
                }
                date_of_birth
                sex
                race
              }
              booking_info {
                booking_sid
                local_id
                booking_number
                booking_datetime
                booking_address
                booking_date
                arrest_agency
                arrested_for
              }
              booking_charges {
                id
                booking_charge_sid
                booking_sid
                booking_number
                booking_datetime
                charge_auth_type
                charge_code_section
                charge_type
                charge_datetime
                charge_description
                arrest_agency
                arrest_datetime
                crime_case_number
                court_warrant_number
              }
              booking_releases {
                charge_code_section
                charge_description
                charge_type
                charge_release_datetime
                charge_release_type
                warrant_number
                court_case_number
                jc_control
                created_datetime
              }
              booking_status {
                booking_number
                court_appearance_datetime
                court_reason
                court_room
              }
              classification
              imported_date
              comments
              is_eligible
              spanish_speaker
              has_wait
              prospect_status
              interview_and_assessment {
                interview_data {
                  residence {
                    address
                    moved_into_current_address_date
                    phone_number
                    moved_to_bay_area_date
                  }
                  contacts {
                    name {
                      first
                      last
                    }
                    address
                    phone
                    date_of_birth
                    sex
                    relationship
                    how_often_seen
                  }
                  employment_and_education {
                    is_employed
                    current_employer_name
                    is_student_retired_disabled
                    is_primary_caretaker
                    employment_start_date
                    employment_type
                    hours_worked_per_week
                    type_of_work
                    previous_employer_name
                    previous_employer_start_date
                    previous_employer_end_date
                    unemployed_start_date
                    unemployed_how_supported
                  }
                  military_service {
                    has_served_in_military
                    military_service_comments
                  }
                  prior_record {
                    bench_warrants_count
                    currently_on_probation
                    has_pending_case
                    prior_record_comments
                  }
                  substance_abuse {
                    has_experimented_with_illegal_substances
                    type_of_illegal_substance_experimented
                    last_use_of_illegal_substance_date
                    has_abused_alcohol_or_marijuana
                    last_alcohol_or_marijuna_abuse_date
                    alcohol_or_marijuana_abuse_how_often
                    has_used_illegal_drugs_last_six_months
                    type_of_illegal_drug_used_last_six_months
                    how_often_illegal_drug_use_last_six_months
                    has_received_treatment_alcohol_or_marijuana
                    alcohol_or_marijuana_program_name
                    alcohol_or_marijuana_treatment_date
                  }
                  victim_info {
                    was_victim_in_case
                    victims {
                      name {
                        first
                        last
                      }
                      address
                      phone
                      date_of_birth
                      sex
                      relationship
                      how_often_seen
                    }
                    has_protective_order
                    has_stay_away_order
                    has_peaceful_contact_order
                    protective_order_notes
                  }
                  firearm_info {
                    has_firearm_registered
                    registered_firearm_model
                    registered_firearm_location
                    has_firearm_in_home
                    home_firearm_model
                    home_firearm_location
                  }
                }
                research_factors {
                  has_prior_misdemeanor_conviction
                  has_prior_felony_conviction
                  prior_violent_convictions_count
                  prior_FTAs_past_two_years_count
                  has_prior_FTAs_older_than_two_years
                  has_prior_sentence_to_incarceration
                }
                risk_factors {
                  has_active_community_supervision
                  is_charge_felony_drug_theft_or_fraud
                  has_pending_charges
                  has_criminal_history
                  has_two_or_more_FTAs
                  has_two_or_more_violent_convictions
                  has_history_of_drug_abuse
                  employed_at_time_of_arrest
                }
                completed_by {
                  id
                  name {
                    first
                    last
                  }
                }
                court_date
                charge_bond_type_amount_court
                instrument_completed_at
                last_updated_at
              }
              is_interview_declined
              court_memo {
                was_under_the_influence_of_alcohol_or_drugs
                was_combative
                was_in_medical_isolation
                mental_state_prevented_interview
                has_refused_interview
                is_no_longer_in_custody
                has_declined_interview_or_release
                has_plan_to_bail_out
                is_incomplete_due_to_insufficient_notice
                report_available_by_date
                has_existing_or_report_re_referred
                existing_or_report_date
                is_incomplete_due_to_referral_volume
                is_denied_or
                ordered_tad_date
                has_existing_or_report_previously_interviewed
                last_interviewed_date
                hold_out_state
                other_reason_comments
                signature_probation_supervisor
                signature_team_member
              }
              recommendation_form {
                has_verified_employment_information
                employment_comments
                pending_disposition
                pending_disposition_comments
                vprai_override_public_safety_comments
                signature_probation_supervisor
                signature_team_member
                incident_summary_notes
                mandatory_detention
                additional_information
                release_recommendation
                conditions_of_release {
                  supervision_and_custody
                  not_leave_state_or_residence
                  notify_change_employment_or_residence
                  report_regularly_pretrial
                  report_to_probation
                  tcemp
                  tcv3a
                  curfew
                  location_tracking
                  alcohol_detection
                  alcohol_detection_with_curfew
                  follow_court_protective_orders
                  tcaap
                  tcbaf
                  tcnax
                  tcnae
                  tcgnx
                  tcgwr
                  follow_court_probation_conditions
                  pretrial_program
                }
                reasons_against_release {
                  high_risk_to_fail_pretrial_release
                  vprai_override_public_safety
                  vprai_override_victim_residence
                }
                criminal_history {
                  two_or_more_violent_convictions
                  one_or_more_misdemeanor_or_felony_convictions
                  no_prior_criminal_history
                }
                substance_abuse_comments
                afs_info {
                  afs_check_date
                  number_of_weapons_registered
                }
                submitted_for_review_by {
                  name {
                    first
                    last
                  }
                }
                submitted_for_review_at
                reviewed_by {
                  name {
                    first
                    last
                  }
                }
                reviewed_at
                completed_by {
                  name {
                    first
                    last
                  }
                }
                completed_at
                internal_notes {
                  text
                  created_at
                  created_by {
                    name {
                      first
                      last
                    }
                  }
                }
              }
              recommendation_review_status
              risk_assessment_result {
                score
                level
                failure_rate
              }
              pretrial_decision_form {
                decision
                has_gps_monitoring
                has_alcohol_monitoring
                has_basic_monitoring
                sentencing_decision
                jail_sanction
              }
            }
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreUpdateBooking(
          "submitBookingRecommendationForm",
          store,
          variables.booking_id
        );
      },
    },
    "Error while attempting to submitBookingRecommendationForm"
  );
};

export const revokeBookingRecommendationApproval = (
  variables: MutationSubmitBookingRecommendationFormArgs
): Promise<{
  revokeBookingRecommendationApproval: { booking: Booking };
}> => {
  return commitMutation<
    MutationSubmitBookingRecommendationFormArgs,
    { revokeBookingRecommendationApproval: { booking: Booking } }
  >(
    {
      mutation: graphql`
        mutation bookingsRevokeBookingRecommendationApprovalMutation(
          $booking_id: ID!
        ) {
          revokeBookingRecommendationApproval(booking_id: $booking_id) {
            result
            description
            booking {
              id
              booking_defendent {
                cii_number
                local_id
                cdl_id
                name {
                  first
                  last
                }
                date_of_birth
                sex
                race
              }
              booking_info {
                booking_sid
                local_id
                booking_number
                booking_datetime
                booking_address
                booking_date
                arrest_agency
                arrested_for
              }
              booking_charges {
                id
                booking_charge_sid
                booking_sid
                booking_number
                booking_datetime
                charge_auth_type
                charge_code_section
                charge_type
                charge_datetime
                charge_description
                arrest_agency
                arrest_datetime
                crime_case_number
                court_warrant_number
              }
              booking_releases {
                charge_code_section
                charge_description
                charge_type
                charge_release_datetime
                charge_release_type
                warrant_number
                court_case_number
                jc_control
                created_datetime
              }
              booking_status {
                booking_number
                court_appearance_datetime
                court_reason
                court_room
              }
              classification
              imported_date
              comments
              is_eligible
              spanish_speaker
              has_wait
              prospect_status
              interview_and_assessment {
                interview_data {
                  residence {
                    address
                    moved_into_current_address_date
                    phone_number
                    moved_to_bay_area_date
                  }
                  contacts {
                    name {
                      first
                      last
                    }
                    address
                    phone
                    date_of_birth
                    sex
                    relationship
                    how_often_seen
                  }
                  employment_and_education {
                    is_employed
                    current_employer_name
                    is_student_retired_disabled
                    is_primary_caretaker
                    employment_start_date
                    employment_type
                    hours_worked_per_week
                    type_of_work
                    previous_employer_name
                    previous_employer_start_date
                    previous_employer_end_date
                    unemployed_start_date
                    unemployed_how_supported
                  }
                  military_service {
                    has_served_in_military
                    military_service_comments
                  }
                  prior_record {
                    bench_warrants_count
                    currently_on_probation
                    has_pending_case
                    prior_record_comments
                  }
                  substance_abuse {
                    has_experimented_with_illegal_substances
                    type_of_illegal_substance_experimented
                    last_use_of_illegal_substance_date
                    has_abused_alcohol_or_marijuana
                    last_alcohol_or_marijuna_abuse_date
                    alcohol_or_marijuana_abuse_how_often
                    has_used_illegal_drugs_last_six_months
                    type_of_illegal_drug_used_last_six_months
                    how_often_illegal_drug_use_last_six_months
                    has_received_treatment_alcohol_or_marijuana
                    alcohol_or_marijuana_program_name
                    alcohol_or_marijuana_treatment_date
                  }
                  victim_info {
                    was_victim_in_case
                    victims {
                      name {
                        first
                        last
                      }
                      address
                      phone
                      date_of_birth
                      sex
                      relationship
                      how_often_seen
                    }
                    has_protective_order
                    has_stay_away_order
                    has_peaceful_contact_order
                    protective_order_notes
                  }
                  firearm_info {
                    has_firearm_registered
                    registered_firearm_model
                    registered_firearm_location
                    has_firearm_in_home
                    home_firearm_model
                    home_firearm_location
                  }
                }
                research_factors {
                  has_prior_misdemeanor_conviction
                  has_prior_felony_conviction
                  prior_violent_convictions_count
                  prior_FTAs_past_two_years_count
                  has_prior_FTAs_older_than_two_years
                  has_prior_sentence_to_incarceration
                }
                risk_factors {
                  has_active_community_supervision
                  is_charge_felony_drug_theft_or_fraud
                  has_pending_charges
                  has_criminal_history
                  has_two_or_more_FTAs
                  has_two_or_more_violent_convictions
                  has_history_of_drug_abuse
                  employed_at_time_of_arrest
                }
                completed_by {
                  id
                  name {
                    first
                    last
                  }
                }
                court_date
                charge_bond_type_amount_court
                instrument_completed_at
                last_updated_at
              }
              is_interview_declined
              court_memo {
                was_under_the_influence_of_alcohol_or_drugs
                was_combative
                was_in_medical_isolation
                mental_state_prevented_interview
                has_refused_interview
                is_no_longer_in_custody
                has_declined_interview_or_release
                has_plan_to_bail_out
                is_incomplete_due_to_insufficient_notice
                report_available_by_date
                has_existing_or_report_re_referred
                existing_or_report_date
                is_incomplete_due_to_referral_volume
                is_denied_or
                ordered_tad_date
                has_existing_or_report_previously_interviewed
                last_interviewed_date
                hold_out_state
                other_reason_comments
                signature_probation_supervisor
                signature_team_member
              }
              recommendation_form {
                has_verified_employment_information
                employment_comments
                pending_disposition
                pending_disposition_comments
                vprai_override_public_safety_comments
                signature_probation_supervisor
                signature_team_member
                mandatory_detention
                additional_information
                release_recommendation
                conditions_of_release {
                  supervision_and_custody
                  not_leave_state_or_residence
                  notify_change_employment_or_residence
                  report_regularly_pretrial
                  report_to_probation
                  tcemp
                  tcv3a
                  curfew
                  location_tracking
                  alcohol_detection
                  alcohol_detection_with_curfew
                  follow_court_protective_orders
                  tcaap
                  tcbaf
                  tcnax
                  tcnae
                  tcgnx
                  tcgwr
                  follow_court_probation_conditions
                  pretrial_program
                }
                reasons_against_release {
                  high_risk_to_fail_pretrial_release
                  vprai_override_public_safety
                  vprai_override_victim_residence
                }
                criminal_history {
                  two_or_more_violent_convictions
                  one_or_more_misdemeanor_or_felony_convictions
                  no_prior_criminal_history
                }
                substance_abuse_comments
                afs_info {
                  afs_check_date
                  number_of_weapons_registered
                }
                submitted_for_review_by {
                  name {
                    first
                    last
                  }
                }
                submitted_for_review_at
                reviewed_by {
                  name {
                    first
                    last
                  }
                }
                reviewed_at
                completed_by {
                  name {
                    first
                    last
                  }
                }
                completed_at
                internal_notes {
                  text
                  created_at
                  created_by {
                    name {
                      first
                      last
                    }
                  }
                }
              }
              recommendation_review_status
              risk_assessment_result {
                score
                level
                failure_rate
              }
              pretrial_decision_form {
                decision
                has_gps_monitoring
                has_alcohol_monitoring
                has_basic_monitoring
                sentencing_decision
                jail_sanction
              }
            }
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreUpdateBooking(
          "revokeBookingRecommendationApproval",
          store,
          variables.booking_id
        );
      },
    },
    "Error while attempting to revokeBookingRecommendationApproval"
  );
};

export const addNoteToBookingRecommendationForm = (
  variables: MutationAddNoteToBookingRecommendationFormArgs
): Promise<{
  addNoteToBookingRecommendationForm: { booking: Booking };
}> => {
  return commitMutation<
    MutationAddNoteToBookingRecommendationFormArgs,
    { addNoteToBookingRecommendationForm: { booking: Booking } }
  >(
    {
      mutation: graphql`
        mutation bookingsAddNoteToBookingRecommendationFormMutation(
          $booking_id: ID!
          $text: String!
        ) {
          addNoteToBookingRecommendationForm(
            booking_id: $booking_id
            text: $text
          ) {
            result
            description
            booking {
              id
              booking_defendent {
                cii_number
                local_id
                cdl_id
                name {
                  first
                  last
                }
                date_of_birth
                sex
                race
              }
              booking_info {
                booking_sid
                local_id
                booking_number
                booking_datetime
                booking_address
                booking_date
                arrest_agency
                arrested_for
              }
              booking_charges {
                id
                booking_charge_sid
                booking_sid
                booking_number
                booking_datetime
                charge_auth_type
                charge_code_section
                charge_type
                charge_datetime
                charge_description
                arrest_agency
                arrest_datetime
                crime_case_number
                court_warrant_number
              }
              booking_releases {
                charge_code_section
                charge_description
                charge_type
                charge_release_datetime
                charge_release_type
                warrant_number
                court_case_number
                jc_control
                created_datetime
              }
              booking_status {
                booking_number
                court_appearance_datetime
                court_reason
                court_room
              }
              classification
              imported_date
              comments
              is_eligible
              spanish_speaker
              has_wait
              prospect_status
              interview_and_assessment {
                interview_data {
                  residence {
                    address
                    moved_into_current_address_date
                    phone_number
                    moved_to_bay_area_date
                  }
                  contacts {
                    name {
                      first
                      last
                    }
                    address
                    phone
                    date_of_birth
                    sex
                    relationship
                    how_often_seen
                  }
                  employment_and_education {
                    is_employed
                    current_employer_name
                    is_student_retired_disabled
                    is_primary_caretaker
                    employment_start_date
                    employment_type
                    hours_worked_per_week
                    type_of_work
                    previous_employer_name
                    previous_employer_start_date
                    previous_employer_end_date
                    unemployed_start_date
                    unemployed_how_supported
                  }
                  military_service {
                    has_served_in_military
                    military_service_comments
                  }
                  prior_record {
                    bench_warrants_count
                    currently_on_probation
                    has_pending_case
                    prior_record_comments
                  }
                  substance_abuse {
                    has_experimented_with_illegal_substances
                    type_of_illegal_substance_experimented
                    last_use_of_illegal_substance_date
                    has_abused_alcohol_or_marijuana
                    last_alcohol_or_marijuna_abuse_date
                    alcohol_or_marijuana_abuse_how_often
                    has_used_illegal_drugs_last_six_months
                    type_of_illegal_drug_used_last_six_months
                    how_often_illegal_drug_use_last_six_months
                    has_received_treatment_alcohol_or_marijuana
                    alcohol_or_marijuana_program_name
                    alcohol_or_marijuana_treatment_date
                  }
                  victim_info {
                    was_victim_in_case
                    victims {
                      name {
                        first
                        last
                      }
                      address
                      phone
                      date_of_birth
                      sex
                      relationship
                      how_often_seen
                    }
                    has_protective_order
                    has_stay_away_order
                    has_peaceful_contact_order
                    protective_order_notes
                  }
                  firearm_info {
                    has_firearm_registered
                    registered_firearm_model
                    registered_firearm_location
                    has_firearm_in_home
                    home_firearm_model
                    home_firearm_location
                  }
                }
                research_factors {
                  has_prior_misdemeanor_conviction
                  has_prior_felony_conviction
                  prior_violent_convictions_count
                  prior_FTAs_past_two_years_count
                  has_prior_FTAs_older_than_two_years
                  has_prior_sentence_to_incarceration
                }
                risk_factors {
                  has_active_community_supervision
                  is_charge_felony_drug_theft_or_fraud
                  has_pending_charges
                  has_criminal_history
                  has_two_or_more_FTAs
                  has_two_or_more_violent_convictions
                  has_history_of_drug_abuse
                  employed_at_time_of_arrest
                }
                completed_by {
                  id
                  name {
                    first
                    last
                  }
                }
                court_date
                charge_bond_type_amount_court
                instrument_completed_at
                last_updated_at
              }
              is_interview_declined
              court_memo {
                was_under_the_influence_of_alcohol_or_drugs
                was_combative
                was_in_medical_isolation
                mental_state_prevented_interview
                has_refused_interview
                is_no_longer_in_custody
                has_declined_interview_or_release
                has_plan_to_bail_out
                is_incomplete_due_to_insufficient_notice
                report_available_by_date
                has_existing_or_report_re_referred
                existing_or_report_date
                is_incomplete_due_to_referral_volume
                is_denied_or
                ordered_tad_date
                has_existing_or_report_previously_interviewed
                last_interviewed_date
                hold_out_state
                other_reason_comments
                signature_probation_supervisor
                signature_team_member
              }
              recommendation_form {
                has_verified_employment_information
                employment_comments
                pending_disposition
                pending_disposition_comments
                vprai_override_public_safety_comments
                signature_probation_supervisor
                signature_team_member
                incident_summary_notes
                mandatory_detention
                additional_information
                release_recommendation
                conditions_of_release {
                  supervision_and_custody
                  not_leave_state_or_residence
                  notify_change_employment_or_residence
                  report_regularly_pretrial
                  report_to_probation
                  tcemp
                  tcv3a
                  curfew
                  location_tracking
                  alcohol_detection
                  alcohol_detection_with_curfew
                  follow_court_protective_orders
                  tcaap
                  tcbaf
                  tcnax
                  tcnae
                  tcgnx
                  tcgwr
                  follow_court_probation_conditions
                  pretrial_program
                }
                reasons_against_release {
                  high_risk_to_fail_pretrial_release
                  vprai_override_public_safety
                  vprai_override_victim_residence
                }
                criminal_history {
                  two_or_more_violent_convictions
                  one_or_more_misdemeanor_or_felony_convictions
                  no_prior_criminal_history
                }
                substance_abuse_comments
                afs_info {
                  afs_check_date
                  number_of_weapons_registered
                }
                internal_notes {
                  text
                  created_at
                  created_by {
                    name {
                      first
                      last
                    }
                  }
                }
              }
              recommendation_review_status
              risk_assessment_result {
                score
                level
                failure_rate
              }
              pretrial_decision_form {
                decision
                has_gps_monitoring
                has_alcohol_monitoring
                has_basic_monitoring
                sentencing_decision
                jail_sanction
              }
            }
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreUpdateBooking(
          "addNoteToBookingRecommendationForm",
          store,
          variables.booking_id
        );
      },
    },
    "Error while attempting to addNoteToBookingRecommendationForm"
  );
};

export const submitBookingCourtMemo = (
  variables: MutationSubmitBookingCourtMemoArgs
): Promise<{ updateBookingInterviewAndAssessment: { booking: Booking } }> => {
  return commitMutation<
    MutationSubmitBookingCourtMemoArgs,
    { updateBookingInterviewAndAssessment: { booking: Booking } }
  >(
    {
      mutation: graphql`
        mutation bookingsSubmitBookingCourtMemoMutation(
          $booking_id: ID!
          $court_memo: CourtMemoInput!
        ) {
          submitBookingCourtMemo(
            booking_id: $booking_id
            court_memo: $court_memo
          ) {
            result
            description
            booking {
              id
              booking_defendent {
                cii_number
                local_id
                cdl_id
                name {
                  first
                  last
                }
                date_of_birth
                sex
                race
              }
              booking_info {
                booking_sid
                local_id
                booking_number
                booking_datetime
                booking_address
                booking_date
                arrest_agency
                arrested_for
              }
              booking_charges {
                id
                booking_charge_sid
                booking_sid
                booking_number
                booking_datetime
                charge_auth_type
                charge_code_section
                charge_type
                charge_datetime
                charge_description
                arrest_agency
                arrest_datetime
                crime_case_number
                court_warrant_number
              }
              booking_releases {
                charge_code_section
                charge_description
                charge_type
                charge_release_datetime
                charge_release_type
                warrant_number
                court_case_number
                jc_control
                created_datetime
              }
              booking_status {
                booking_number
                court_appearance_datetime
                court_reason
                court_room
              }
              classification
              imported_date
              comments
              is_eligible
              spanish_speaker
              has_wait
              prospect_status
              interview_and_assessment {
                interview_data {
                  residence {
                    address
                    moved_into_current_address_date
                    phone_number
                    moved_to_bay_area_date
                  }
                  contacts {
                    name {
                      first
                      last
                    }
                    address
                    phone
                    date_of_birth
                    sex
                    relationship
                    how_often_seen
                  }
                  employment_and_education {
                    is_employed
                    current_employer_name
                    is_student_retired_disabled
                    is_primary_caretaker
                    employment_start_date
                    employment_type
                    hours_worked_per_week
                    type_of_work
                    previous_employer_name
                    previous_employer_start_date
                    previous_employer_end_date
                    unemployed_start_date
                    unemployed_how_supported
                  }
                  military_service {
                    has_served_in_military
                    military_service_comments
                  }
                  prior_record {
                    bench_warrants_count
                    currently_on_probation
                    has_pending_case
                    prior_record_comments
                  }
                  substance_abuse {
                    has_experimented_with_illegal_substances
                    type_of_illegal_substance_experimented
                    last_use_of_illegal_substance_date
                    has_abused_alcohol_or_marijuana
                    last_alcohol_or_marijuna_abuse_date
                    alcohol_or_marijuana_abuse_how_often
                    has_used_illegal_drugs_last_six_months
                    type_of_illegal_drug_used_last_six_months
                    how_often_illegal_drug_use_last_six_months
                    has_received_treatment_alcohol_or_marijuana
                    alcohol_or_marijuana_program_name
                    alcohol_or_marijuana_treatment_date
                  }
                  victim_info {
                    was_victim_in_case
                    victims {
                      name {
                        first
                        last
                      }
                      address
                      phone
                      date_of_birth
                      sex
                      relationship
                      how_often_seen
                    }
                    has_protective_order
                    has_stay_away_order
                    has_peaceful_contact_order
                    protective_order_notes
                  }
                  firearm_info {
                    has_firearm_registered
                    registered_firearm_model
                    registered_firearm_location
                    has_firearm_in_home
                    home_firearm_model
                    home_firearm_location
                  }
                }
                research_factors {
                  has_prior_misdemeanor_conviction
                  has_prior_felony_conviction
                  prior_violent_convictions_count
                  prior_FTAs_past_two_years_count
                  has_prior_FTAs_older_than_two_years
                  has_prior_sentence_to_incarceration
                }
                risk_factors {
                  has_active_community_supervision
                  is_charge_felony_drug_theft_or_fraud
                  has_pending_charges
                  has_criminal_history
                  has_two_or_more_FTAs
                  has_two_or_more_violent_convictions
                  has_history_of_drug_abuse
                  employed_at_time_of_arrest
                }
                completed_by {
                  id
                  name {
                    first
                    last
                  }
                }
                court_date
                charge_bond_type_amount_court
                instrument_completed_at
                last_updated_at
              }
              is_interview_declined
              court_memo {
                was_under_the_influence_of_alcohol_or_drugs
                was_combative
                was_in_medical_isolation
                mental_state_prevented_interview
                has_refused_interview
                is_no_longer_in_custody
                has_declined_interview_or_release
                has_plan_to_bail_out
                is_incomplete_due_to_insufficient_notice
                report_available_by_date
                has_existing_or_report_re_referred
                existing_or_report_date
                is_incomplete_due_to_referral_volume
                is_denied_or
                ordered_tad_date
                has_existing_or_report_previously_interviewed
                last_interviewed_date
                hold_out_state
                other_reason_comments
                signature_probation_supervisor
                signature_team_member
              }
              recommendation_form {
                has_verified_employment_information
                employment_comments
                pending_disposition
                pending_disposition_comments
                vprai_override_public_safety_comments
                signature_probation_supervisor
                signature_team_member
                incident_summary_notes
                mandatory_detention
                additional_information
                release_recommendation
                conditions_of_release {
                  supervision_and_custody
                  not_leave_state_or_residence
                  notify_change_employment_or_residence
                  report_regularly_pretrial
                  report_to_probation
                  tcemp
                  tcv3a
                  curfew
                  location_tracking
                  alcohol_detection
                  alcohol_detection_with_curfew
                  follow_court_protective_orders
                  tcaap
                  tcbaf
                  tcnax
                  tcnae
                  tcgnx
                  tcgwr
                  follow_court_probation_conditions
                  pretrial_program
                }
                reasons_against_release {
                  high_risk_to_fail_pretrial_release
                  vprai_override_public_safety
                  vprai_override_victim_residence
                }
                criminal_history {
                  two_or_more_violent_convictions
                  one_or_more_misdemeanor_or_felony_convictions
                  no_prior_criminal_history
                }
                substance_abuse_comments
                afs_info {
                  afs_check_date
                  number_of_weapons_registered
                }
                internal_notes {
                  text
                  created_at
                  created_by {
                    name {
                      first
                      last
                    }
                  }
                }
              }
              recommendation_review_status
              risk_assessment_result {
                score
                level
                failure_rate
              }
              pretrial_decision_form {
                decision
                has_gps_monitoring
                has_alcohol_monitoring
                has_basic_monitoring
                sentencing_decision
                jail_sanction
              }
            }
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreUpdateBooking(
          "submitBookingCourtMemo",
          store,
          variables.booking_id
        );
      },
    },
    "Error while attempting to submitBookingCourtMemo"
  );
};

export const updateBookingInfoData = (
  variables: MutationUpdateBookingInfoDataArgs
): Promise<{ updateBookingInterviewAndAssessment: { booking: Booking } }> => {
  return commitMutation<
    MutationUpdateBookingInfoDataArgs,
    { updateBookingInterviewAndAssessment: { booking: Booking } }
  >(
    {
      mutation: graphql`
        mutation bookingsUpdateBookingInfoDataMutation(
          $booking_id: ID!
          $booking_info_update_data: BookingInfoUpdateDataInput!
        ) {
          updateBookingInfoData(
            booking_id: $booking_id
            booking_info_update_data: $booking_info_update_data
          ) {
            result
            description
            booking {
              id
              booking_defendent {
                cii_number
                local_id
                cdl_id
                name {
                  first
                  last
                }
                date_of_birth
                sex
                race
              }
              booking_info {
                booking_sid
                local_id
                booking_number
                booking_datetime
                booking_address
                booking_date
                arrest_agency
                arrested_for
              }
              booking_charges {
                id
                booking_charge_sid
                booking_sid
                booking_number
                booking_datetime
                charge_auth_type
                charge_code_section
                charge_type
                charge_datetime
                charge_description
                arrest_agency
                arrest_datetime
                crime_case_number
                court_warrant_number
              }
              booking_releases {
                charge_code_section
                charge_description
                charge_type
                charge_release_datetime
                charge_release_type
                warrant_number
                court_case_number
                jc_control
                created_datetime
              }
              booking_status {
                booking_number
                court_appearance_datetime
                court_reason
                court_room
              }
              classification
              imported_date
              comments
              is_eligible
              spanish_speaker
              has_wait
              prospect_status
              interview_and_assessment {
                interview_data {
                  residence {
                    address
                    moved_into_current_address_date
                    phone_number
                    moved_to_bay_area_date
                  }
                  contacts {
                    name {
                      first
                      last
                    }
                    address
                    phone
                    date_of_birth
                    sex
                    relationship
                    how_often_seen
                  }
                  employment_and_education {
                    is_employed
                    current_employer_name
                    is_student_retired_disabled
                    is_primary_caretaker
                    employment_start_date
                    employment_type
                    hours_worked_per_week
                    type_of_work
                    previous_employer_name
                    previous_employer_start_date
                    previous_employer_end_date
                    unemployed_start_date
                    unemployed_how_supported
                  }
                  military_service {
                    has_served_in_military
                    military_service_comments
                  }
                  prior_record {
                    bench_warrants_count
                    currently_on_probation
                    has_pending_case
                    prior_record_comments
                  }
                  substance_abuse {
                    has_experimented_with_illegal_substances
                    type_of_illegal_substance_experimented
                    last_use_of_illegal_substance_date
                    has_abused_alcohol_or_marijuana
                    last_alcohol_or_marijuna_abuse_date
                    alcohol_or_marijuana_abuse_how_often
                    has_used_illegal_drugs_last_six_months
                    type_of_illegal_drug_used_last_six_months
                    how_often_illegal_drug_use_last_six_months
                    has_received_treatment_alcohol_or_marijuana
                    alcohol_or_marijuana_program_name
                    alcohol_or_marijuana_treatment_date
                  }
                  victim_info {
                    was_victim_in_case
                    victims {
                      name {
                        first
                        last
                      }
                      address
                      phone
                      date_of_birth
                      sex
                      relationship
                      how_often_seen
                    }
                    has_protective_order
                    has_stay_away_order
                    has_peaceful_contact_order
                    protective_order_notes
                  }
                  firearm_info {
                    has_firearm_registered
                    registered_firearm_model
                    registered_firearm_location
                    has_firearm_in_home
                    home_firearm_model
                    home_firearm_location
                  }
                }
                research_factors {
                  has_prior_misdemeanor_conviction
                  has_prior_felony_conviction
                  prior_violent_convictions_count
                  prior_FTAs_past_two_years_count
                  has_prior_FTAs_older_than_two_years
                  has_prior_sentence_to_incarceration
                }
                risk_factors {
                  has_active_community_supervision
                  is_charge_felony_drug_theft_or_fraud
                  has_pending_charges
                  has_criminal_history
                  has_two_or_more_FTAs
                  has_two_or_more_violent_convictions
                  has_history_of_drug_abuse
                  employed_at_time_of_arrest
                }
                completed_by {
                  id
                  name {
                    first
                    last
                  }
                }
                court_date
                charge_bond_type_amount_court
                instrument_completed_at
                last_updated_at
              }
              is_interview_declined
              court_memo {
                was_under_the_influence_of_alcohol_or_drugs
                was_combative
                was_in_medical_isolation
                mental_state_prevented_interview
                has_refused_interview
                is_no_longer_in_custody
                has_declined_interview_or_release
                has_plan_to_bail_out
                is_incomplete_due_to_insufficient_notice
                report_available_by_date
                has_existing_or_report_re_referred
                existing_or_report_date
                is_incomplete_due_to_referral_volume
                is_denied_or
                ordered_tad_date
                has_existing_or_report_previously_interviewed
                last_interviewed_date
                hold_out_state
                other_reason_comments
                signature_probation_supervisor
                signature_team_member
              }
              recommendation_form {
                has_verified_employment_information
                employment_comments
                pending_disposition
                pending_disposition_comments
                vprai_override_public_safety_comments
                signature_probation_supervisor
                signature_team_member
                incident_summary_notes
                mandatory_detention
                additional_information
                release_recommendation
                conditions_of_release {
                  supervision_and_custody
                  not_leave_state_or_residence
                  notify_change_employment_or_residence
                  report_regularly_pretrial
                  report_to_probation
                  tcemp
                  tcv3a
                  curfew
                  location_tracking
                  alcohol_detection
                  alcohol_detection_with_curfew
                  follow_court_protective_orders
                  tcaap
                  tcbaf
                  tcnax
                  tcnae
                  tcgnx
                  tcgwr
                  follow_court_probation_conditions
                  pretrial_program
                }
                reasons_against_release {
                  high_risk_to_fail_pretrial_release
                  vprai_override_public_safety
                  vprai_override_victim_residence
                }
                criminal_history {
                  two_or_more_violent_convictions
                  one_or_more_misdemeanor_or_felony_convictions
                  no_prior_criminal_history
                }
                substance_abuse_comments
                afs_info {
                  afs_check_date
                  number_of_weapons_registered
                }
                internal_notes {
                  text
                  created_at
                  created_by {
                    name {
                      first
                      last
                    }
                  }
                }
              }
              recommendation_review_status
              risk_assessment_result {
                score
                level
                failure_rate
              }
              pretrial_decision_form {
                decision
                has_gps_monitoring
                has_alcohol_monitoring
                has_basic_monitoring
                sentencing_decision
                jail_sanction
              }
            }
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreUpdateBooking(
          "updateBookingInfoData",
          store,
          variables.booking_id
        );
      },
    },
    "Error while attempting to updateBookingInfoData"
  );
};

export const updateBookingCourtDecisionData = (
  variables: MutationUpdateBookingCourtDecisionDataArgs
): Promise<{ updateBookingCourtDecisionData: { booking: Booking } }> => {
  return commitMutation<
    MutationUpdateBookingCourtDecisionDataArgs,
    { updateBookingCourtDecisionData: { booking: Booking } }
  >(
    {
      mutation: graphql`
        mutation bookingsUpdateBookingCourtDecisionDataMutation(
          $booking_id: ID!
          $pretrial_decision_form_data: PretrialDecisionFormInput!
        ) {
          updateBookingCourtDecisionData(
            booking_id: $booking_id
            pretrial_decision_form_data: $pretrial_decision_form_data
          ) {
            result
            description
            booking {
              id
              booking_defendent {
                cii_number
                local_id
                cdl_id
                name {
                  first
                  last
                }
                date_of_birth
                sex
                race
              }
              booking_info {
                booking_sid
                local_id
                booking_number
                booking_datetime
                booking_address
                booking_date
                arrest_agency
                arrested_for
              }
              booking_charges {
                id
                booking_charge_sid
                booking_sid
                booking_number
                booking_datetime
                charge_auth_type
                charge_code_section
                charge_type
                charge_datetime
                charge_description
                arrest_agency
                arrest_datetime
                crime_case_number
                court_warrant_number
              }
              booking_releases {
                charge_code_section
                charge_description
                charge_type
                charge_release_datetime
                charge_release_type
                warrant_number
                court_case_number
                jc_control
                created_datetime
              }
              booking_status {
                booking_number
                court_appearance_datetime
                court_reason
                court_room
              }
              classification
              imported_date
              comments
              is_eligible
              spanish_speaker
              has_wait
              prospect_status
              interview_and_assessment {
                interview_data {
                  residence {
                    address
                    moved_into_current_address_date
                    phone_number
                    moved_to_bay_area_date
                  }
                  contacts {
                    name {
                      first
                      last
                    }
                    address
                    phone
                    date_of_birth
                    sex
                    relationship
                    how_often_seen
                  }
                  employment_and_education {
                    is_employed
                    current_employer_name
                    is_student_retired_disabled
                    is_primary_caretaker
                    employment_start_date
                    employment_type
                    hours_worked_per_week
                    type_of_work
                    previous_employer_name
                    previous_employer_start_date
                    previous_employer_end_date
                    unemployed_start_date
                    unemployed_how_supported
                  }
                  military_service {
                    has_served_in_military
                    military_service_comments
                  }
                  prior_record {
                    bench_warrants_count
                    currently_on_probation
                    has_pending_case
                    prior_record_comments
                  }
                  substance_abuse {
                    has_experimented_with_illegal_substances
                    type_of_illegal_substance_experimented
                    last_use_of_illegal_substance_date
                    has_abused_alcohol_or_marijuana
                    last_alcohol_or_marijuna_abuse_date
                    alcohol_or_marijuana_abuse_how_often
                    has_used_illegal_drugs_last_six_months
                    type_of_illegal_drug_used_last_six_months
                    how_often_illegal_drug_use_last_six_months
                    has_received_treatment_alcohol_or_marijuana
                    alcohol_or_marijuana_program_name
                    alcohol_or_marijuana_treatment_date
                  }
                  victim_info {
                    was_victim_in_case
                    victims {
                      name {
                        first
                        last
                      }
                      address
                      phone
                      date_of_birth
                      sex
                      relationship
                      how_often_seen
                    }
                    has_protective_order
                    has_stay_away_order
                    has_peaceful_contact_order
                    protective_order_notes
                  }
                  firearm_info {
                    has_firearm_registered
                    registered_firearm_model
                    registered_firearm_location
                    has_firearm_in_home
                    home_firearm_model
                    home_firearm_location
                  }
                }
                research_factors {
                  has_prior_misdemeanor_conviction
                  has_prior_felony_conviction
                  prior_violent_convictions_count
                  prior_FTAs_past_two_years_count
                  has_prior_FTAs_older_than_two_years
                  has_prior_sentence_to_incarceration
                }
                risk_factors {
                  has_active_community_supervision
                  is_charge_felony_drug_theft_or_fraud
                  has_pending_charges
                  has_criminal_history
                  has_two_or_more_FTAs
                  has_two_or_more_violent_convictions
                  has_history_of_drug_abuse
                  employed_at_time_of_arrest
                }
                completed_by {
                  id
                  name {
                    first
                    last
                  }
                }
                court_date
                charge_bond_type_amount_court
                instrument_completed_at
                last_updated_at
              }
              is_interview_declined
              court_memo {
                was_under_the_influence_of_alcohol_or_drugs
                was_combative
                was_in_medical_isolation
                mental_state_prevented_interview
                has_refused_interview
                is_no_longer_in_custody
                has_declined_interview_or_release
                has_plan_to_bail_out
                is_incomplete_due_to_insufficient_notice
                report_available_by_date
                has_existing_or_report_re_referred
                existing_or_report_date
                is_incomplete_due_to_referral_volume
                is_denied_or
                ordered_tad_date
                has_existing_or_report_previously_interviewed
                last_interviewed_date
                hold_out_state
                other_reason_comments
                signature_probation_supervisor
                signature_team_member
              }
              recommendation_form {
                has_verified_employment_information
                employment_comments
                pending_disposition
                pending_disposition_comments
                vprai_override_public_safety_comments
                signature_probation_supervisor
                signature_team_member
                incident_summary_notes
                mandatory_detention
                additional_information
                release_recommendation
                conditions_of_release {
                  supervision_and_custody
                  not_leave_state_or_residence
                  notify_change_employment_or_residence
                  report_regularly_pretrial
                  report_to_probation
                  tcemp
                  tcv3a
                  curfew
                  location_tracking
                  alcohol_detection
                  alcohol_detection_with_curfew
                  follow_court_protective_orders
                  tcaap
                  tcbaf
                  tcnax
                  tcnae
                  tcgnx
                  tcgwr
                  follow_court_probation_conditions
                  pretrial_program
                }
                reasons_against_release {
                  high_risk_to_fail_pretrial_release
                  vprai_override_public_safety
                  vprai_override_victim_residence
                }
                criminal_history {
                  two_or_more_violent_convictions
                  one_or_more_misdemeanor_or_felony_convictions
                  no_prior_criminal_history
                }
                substance_abuse_comments
                afs_info {
                  afs_check_date
                  number_of_weapons_registered
                }
                internal_notes {
                  text
                  created_at
                  created_by {
                    name {
                      first
                      last
                    }
                  }
                }
              }
              recommendation_review_status
              risk_assessment_result {
                score
                level
                failure_rate
              }
              pretrial_decision_form {
                decision
                has_gps_monitoring
                has_alcohol_monitoring
                has_basic_monitoring
                sentencing_decision
                jail_sanction
              }
            }
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreUpdateBooking(
          "updateBookingCourtDecisionData",
          store,
          variables.booking_id
        );
      },
    },
    "Error while attempting to updateBookingCourtDecisionData"
  );
};

export const archiveBooking = (
  variables: MutationArchiveBookingArgs
): Promise<{ archiveBooking: { booking: Booking } }> => {
  return commitMutation<
    MutationArchiveBookingArgs,
    { archiveBooking: { booking: Booking } }
  >(
    {
      mutation: graphql`
        mutation bookingsArchiveBookingsMutation(
          $booking_id: ID!
          $is_undo: Boolean!
        ) {
          archiveBooking(booking_id: $booking_id, is_undo: $is_undo) {
            result
            description
            booking {
              id
              booking_defendent {
                cii_number
                local_id
                cdl_id
                name {
                  first
                  last
                }
                date_of_birth
                sex
                race
              }
              booking_info {
                booking_sid
                local_id
                booking_number
                booking_datetime
                booking_address
                booking_date
                arrest_agency
                arrested_for
              }
              booking_charges {
                id
                booking_charge_sid
                booking_sid
                booking_number
                booking_datetime
                charge_auth_type
                charge_code_section
                charge_type
                charge_datetime
                charge_description
                arrest_agency
                arrest_datetime
                crime_case_number
                court_warrant_number
              }
              booking_releases {
                charge_code_section
                charge_description
                charge_type
                charge_release_datetime
                charge_release_type
                warrant_number
                court_case_number
                jc_control
                created_datetime
              }
              booking_status {
                booking_number
                court_appearance_datetime
                court_reason
                court_room
              }
              classification
              imported_date
              comments
              is_eligible
              spanish_speaker
              has_wait
              prospect_status
              interview_and_assessment {
                research_factors {
                  has_prior_misdemeanor_conviction
                  has_prior_felony_conviction
                  prior_violent_convictions_count
                  prior_FTAs_past_two_years_count
                  has_prior_FTAs_older_than_two_years
                  has_prior_sentence_to_incarceration
                }
                risk_factors {
                  has_active_community_supervision
                  is_charge_felony_drug_theft_or_fraud
                  has_pending_charges
                  has_criminal_history
                  has_two_or_more_FTAs
                  has_two_or_more_violent_convictions
                  has_history_of_drug_abuse
                  employed_at_time_of_arrest
                }
                completed_by {
                  id
                  name {
                    first
                    last
                  }
                }
                court_date
                charge_bond_type_amount_court
                instrument_completed_at
                last_updated_at
              }
              is_interview_declined
              recommendation_review_status
              risk_assessment_result {
                score
                level
                failure_rate
              }
              pretrial_decision_form {
                decision
                has_gps_monitoring
                has_alcohol_monitoring
                has_basic_monitoring
                sentencing_decision
                jail_sanction
              }
            }
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreUpdateBooking(
          "archiveBooking",
          store,
          variables.booking_id
        );
      },
    },
    "Error while attempting to archive booking"
  );
};

export const addBookingRelease = (
  variables: MutationAddBookingReleaseArgs
): Promise<{ addBookingRelease: { booking: Booking } }> => {
  return commitMutation<
    MutationAddBookingReleaseArgs,
    { addBookingRelease: { booking: Booking } }
  >(
    {
      mutation: graphql`
        mutation bookingsAddBookingReleaseMutation(
          $booking_id: ID!
          $release_type: BookingReleaseType
        ) {
          addBookingRelease(
            booking_id: $booking_id
            release_type: $release_type
          ) {
            result
            description
            booking {
              id
              booking_defendent {
                cii_number
                local_id
                cdl_id
                name {
                  first
                  last
                }
                date_of_birth
                sex
                race
              }
              booking_info {
                booking_sid
                local_id
                booking_number
                booking_datetime
                booking_address
                booking_date
                arrest_agency
                arrested_for
              }
              booking_charges {
                id
                booking_charge_sid
                booking_sid
                booking_number
                booking_datetime
                charge_auth_type
                charge_code_section
                charge_type
                charge_datetime
                charge_description
                arrest_agency
                arrest_datetime
                crime_case_number
                court_warrant_number
              }
              booking_releases {
                charge_code_section
                charge_description
                charge_type
                charge_release_datetime
                charge_release_type
                warrant_number
                court_case_number
                jc_control
                created_datetime
              }
              booking_status {
                booking_number
                court_appearance_datetime
                court_reason
                court_room
              }
              classification
              imported_date
              comments
              is_eligible
              spanish_speaker
              has_wait
              prospect_status
              interview_and_assessment {
                research_factors {
                  has_prior_misdemeanor_conviction
                  has_prior_felony_conviction
                  prior_violent_convictions_count
                  prior_FTAs_past_two_years_count
                  has_prior_FTAs_older_than_two_years
                  has_prior_sentence_to_incarceration
                }
                risk_factors {
                  has_active_community_supervision
                  is_charge_felony_drug_theft_or_fraud
                  has_pending_charges
                  has_criminal_history
                  has_two_or_more_FTAs
                  has_two_or_more_violent_convictions
                  has_history_of_drug_abuse
                  employed_at_time_of_arrest
                }
                completed_by {
                  id
                  name {
                    first
                    last
                  }
                }
                court_date
                charge_bond_type_amount_court
                instrument_completed_at
                last_updated_at
              }
              is_interview_declined
              recommendation_review_status
              risk_assessment_result {
                score
                level
                failure_rate
              }
              pretrial_decision_form {
                decision
                has_gps_monitoring
                has_alcohol_monitoring
                has_basic_monitoring
                sentencing_decision
                jail_sanction
              }
            }
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreUpdateBooking(
          "addBookingRelease",
          store,
          variables.booking_id
        );
      },
    },
    "Error while attempting to add booking release"
  );
};

export const createParticipantFromBooking = (
  variables: MutationCreateParticipantFromBookingArgs
): Promise<{ createParticipantFromBooking: { booking: Booking } }> => {
  return commitMutation<
    MutationCreateParticipantFromBookingArgs,
    { createParticipantFromBooking: { booking: Booking } }
  >(
    {
      mutation: graphql`
        mutation bookingsCreateParticipantFromBookingMutation(
          $booking_id: ID!
        ) {
          createParticipantFromBooking(booking_id: $booking_id) {
            result
            description
            booking {
              id
              booking_defendent {
                cii_number
                local_id
                cdl_id
                name {
                  first
                  last
                }
                date_of_birth
                sex
                race
              }
              booking_info {
                booking_sid
                local_id
                booking_number
                booking_datetime
                booking_address
                booking_date
                arrest_agency
                arrested_for
              }
              booking_charges {
                id
                booking_charge_sid
                booking_sid
                booking_number
                booking_datetime
                charge_auth_type
                charge_code_section
                charge_type
                charge_datetime
                charge_description
                arrest_agency
                arrest_datetime
                crime_case_number
                court_warrant_number
              }
              booking_releases {
                charge_code_section
                charge_description
                charge_type
                charge_release_datetime
                charge_release_type
                warrant_number
                court_case_number
                jc_control
                created_datetime
              }
              booking_status {
                booking_number
                court_appearance_datetime
                court_reason
                court_room
              }
              classification
              imported_date
              comments
              is_eligible
              spanish_speaker
              has_wait
              prospect_status
              participant_id
              interview_and_assessment {
                research_factors {
                  has_prior_misdemeanor_conviction
                  has_prior_felony_conviction
                  prior_violent_convictions_count
                  prior_FTAs_past_two_years_count
                  has_prior_FTAs_older_than_two_years
                  has_prior_sentence_to_incarceration
                }
                risk_factors {
                  has_active_community_supervision
                  is_charge_felony_drug_theft_or_fraud
                  has_pending_charges
                  has_criminal_history
                  has_two_or_more_FTAs
                  has_two_or_more_violent_convictions
                  has_history_of_drug_abuse
                  employed_at_time_of_arrest
                }
                completed_by {
                  id
                  name {
                    first
                    last
                  }
                }
                court_date
                charge_bond_type_amount_court
                instrument_completed_at
                last_updated_at
              }
              is_interview_declined
              recommendation_review_status
              risk_assessment_result {
                score
                level
                failure_rate
              }
              pretrial_decision_form {
                decision
                has_gps_monitoring
                has_alcohol_monitoring
                has_basic_monitoring
                sentencing_decision
                jail_sanction
              }
            }
          }
        }
      `,
      variables,
      updater: (store) => {
        updateRelayStoreUpdateBooking(
          "createParticipantFromBooking",
          store,
          variables.booking_id
        );
      },
    },
    "Error while attempting to create participant from booking"
  );
};

export const updateRelayStoreUpdateBooking = (
  rootFieldName: string,
  store: RecordSourceSelectorProxy,
  id: string
) => {
  const payload = checkMutationReturn(store, rootFieldName);

  if (!payload) return;

  const existing = store.get(id);

  existing!.copyFieldsFrom(payload);
};
